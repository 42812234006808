import {
  Box,
  Badge,
  Container,
  Center,
  Text,
  Heading,
  Button,
  Stack,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Link,
  HStack,
  useToast,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Image,
  Icon,
  Checkbox,
  Tag,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import CRUDFunction from "../../../functions/CRUDFunction";
import { format } from "date-fns";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { Helmet } from "react-helmet";
import guideline from "../../../assets/RegistrationGuideline.pdf";

export default function Details() {
  const { training } = useParams();
  const { state } = useLocation();
  const toast = useToast();
  const nav = useNavigate();
  const drawerDetail = useDisclosure();
  const trainingModes = [
    "Fulltime online inhouse",
    "Fulltime physical inhouse",
    "Parttime online inhouse",
    "Parttime physical inhouse",
    "Fulltime online public",
    "Fulltime physical public",
    "Parttime online public",
    "Parttime physical public",
  ];
  const displayModes = [
    "Full Time Online Inhouse",
    "Full Time Physical Inhouse",
    "Part Time Online Inhouse",
    "Part Time Physical Inhouse",
    "Full Time Online",
    "Full Time Physical",
    "Part Time Online",
    "Part Time Physical",
  ];
  const modeSplitter = (mode) => {
    return mode.split(" ");
  };
  const [course, setCourse] = useState([
    {
      course_about: "",
      course_eligibility: "",
      course_id: "",
      course_image: "",
      course_name: "",
      course_outline: "",
      trainings: [],
    },
  ]);
  const [trainingDetails, setTrainingDetails] = useState([]);
  const [session, setSession] = useState([]);
  const [enrollid, setEnrollId] = useState(0);
  const modal = useDisclosure();

  const checkID = (training) => {
    return new Promise((resolve, reject) => {
      let temp;
      if (state === null) {
        if (training === "pche") {
          temp = 1;
        } else if (training === "pciha") {
          temp = 2;
        } else if (training === "ha" || training === "awareness") {
          temp = 3;
        } else if (training === "mpphm") {
          temp = 4;
        } else if (training === "competency") {
          temp = 5;
        } else if (training === "pematuhan") {
          temp = 6;
        } else if (training === "mhms") {
          temp = 7;
        } else if (training === "halaloem") {
          temp = 8;
        } else if (training === "hc" || training === "publiccompetency") {
          temp = 9;
        }
      } else {
        temp = state.id;
        if (temp === 1) {
          window.location.replace("pche");
        } else if (temp === 2) {
          window.location.replace("pciha");
        } else if (temp === 3) {
          window.location.replace("ha");
        } else if (temp === 4) {
          window.location.replace("mpphm");
        } else if (temp === 5) {
          window.location.replace("competency");
        } else if (temp === 6) {
          window.location.replace("pematuhan");
        } else if (temp === 7) {
          window.location.replace("mhms");
        } else if (temp === 8) {
          window.location.replace("halaloem");
        } else if (temp === 9) {
          window.location.replace("hc");
        }
      }
      resolve(temp);
    });
  };

  useEffect(() => {
    fetchCourse();
    localStorage.getItem("hideModal") !== "true" && modal.onOpen();
  }, []);

  const fetchCourse = async () => {
    const TID = await checkID(training);
    if (TID) {
      const res = await CRUDFunction.get(`${URL_STAGING}coursedetails/${TID}`);
      if (res) {
        setEnrollId(TID);
        let trainings = [];
        if (res.length > 0) {
          if (res[0].training_id !== null) {
            res.map(
              (i) =>
                new Date(JSON.parse(i.training_date)[0].startDate).getTime() >
                  new Date().getTime() &&
                trainings.push({
                  training_id: i.training_id,
                  training_name: i.training_name,
                  training_date: JSON.parse(i.training_date),
                  training_detail: i.training_detail,
                  training_fee: i.training_fee,
                  training_place: i.training_place,
                  training_mode: i.training_mode,
                  training_hrdc: i.training_hrdc,
                  training_status: i.training_status,
                })
            );
          }
          setCourse({
            course_about: res[0].course_about,
            course_id: res[0].course_id,
            course_image: res[0].course_image,
            course_name: res[0].course_name.toUpperCase(),
            course_outline: res[0].course_outline,
            trainings: trainings,
          });
        } else {
          const TID = await checkID(training);
          if (TID) {
            const res = await CRUDFunction.get(`${URL_STAGING}course/${TID}`);
            if (res) {
              setCourse({
                course_name: res.course_name.toUpperCase(),
                course_about: res.course_about,
                course_outline: res.course_outline,
              });
            }
          }
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Training Registration</title>
      </Helmet>
      <Container
        display="grid"
        maxW={{ base: "90%", md: "85%", lg: "80%" }}
        my={{ base: "20%", md: "10%", lg: "0" }}
        px={0}
        bg="white"
        boxShadow="lg"
        borderRadius={10}
      >
        <Stack
          direction="column"
          align="center"
          w="100%"
          bg="#4B85FA"
          color="white"
          borderTopRadius={10}
          pb="30px"
        >
          <Heading
            p={{ base: 4, md: 10 }}
            fontSize={{ base: "18px", md: "36px" }}
            textAlign={{ base: "center" }}
            letterSpacing={2}
            style={{ paddingBottom: "0px" }}
          >
            {course.course_name}
          </Heading>
          <Text size="md">*HRDCorp claimable</Text>
        </Stack>
        <Box
          w="90%"
          h="auto"
          my={"30px"}
          mx={"auto"}
          px={4}
          textAlign="justify"
          // overflowY={{ md: "scroll" }}
        >
          <Box id="courseAbout">
            <Heading size="lg">About Course</Heading>
            <Text pt="3">
              <div dangerouslySetInnerHTML={{ __html: course.course_about }} />
            </Text>
          </Box>
          <Box mt="5" id="courseOutlines">
            <Heading size="lg">Course Outlines</Heading>
            <Text pt="3">
              <div
                dangerouslySetInnerHTML={{ __html: course.course_outline }}
              />
            </Text>
          </Box>

          <Heading mt="5" size="lg">
            Training Details
          </Heading>
          {course.trainings ? (
            course.trainings.length > 0 ? (
              <Box>
                <Box>
                  <Text py="5">
                    Click to view training details based on sessions:
                  </Text>
                  <HStack>
                    <Text>&nbsp;&nbsp;Label:</Text>
                    <Box
                      align="center"
                      borderRadius="5px"
                      px="10px"
                      bg="blue.100"
                    >
                      Online
                    </Box>
                    <Box
                      align="center"
                      borderRadius="5px"
                      px="10px"
                      bg="green.100"
                    >
                      Physical
                    </Box>
                  </HStack>
                  {course.trainings
                    .sort(
                      (a, b) =>
                        new Date(a.training_date[0].startDate).getTime() -
                        new Date(b.training_date[0].startDate).getTime()
                    )
                    .map(
                      (item) =>
                        item.training_status !== 0 &&
                        // THIS CODE IS FOR CLOSE REGISTRATION 4 DAYS BEFORE TRAINING (based on course id)
                        (course.course_id === 1 || course.course_id === 2 ? (
                          new Date(item.training_date[0].startDate).getTime() -
                            86400000 * 4 >
                          new Date().getTime() ? (
                            <>
                              <Button
                                height={
                                  item.training_date[0].startDate !==
                                  item.training_date[0].endDate
                                    ? "180px"
                                    : "170px"
                                }
                                ml="40px"
                                w="300px"
                                p="35px"
                                m="2"
                                bg={
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "online"
                                    ? "blue.100"
                                    : "green.100"
                                }
                                _hover={{
                                  bgColor:
                                    modeSplitter(
                                      trainingModes[item.training_mode]
                                    )[1] === "online"
                                      ? "blue.200"
                                      : "green.200",
                                }}
                                onClick={async () => {
                                  const res = await CRUDFunction.get(
                                    `${URL_STAGING}training/${item.training_id}`
                                  );
                                  setTrainingDetails(res);
                                  setSession(item.training_date);
                                  drawerDetail.onOpen();
                                }}
                              >
                                {item.training_date[0].startDate !==
                                item.training_date[0].endDate ? (
                                  <>
                                    <VStack>
                                      <Badge
                                        fontSize="12px"
                                        variant="subtle"
                                        colorScheme="yellow"
                                      >
                                        {JSON.parse(item.training_hrdc)[0]
                                          ? JSON.parse(item.training_hrdc)[0]
                                              .hrdc === 1 && "HRDCorp Claimable"
                                          : parseInt(item.training_hrdc) === 1
                                          ? "HRDCorp Claimable"
                                          : "Non-Claimable"}
                                      </Badge>
                                      <Text
                                        fontWeight="bold"
                                        letterSpacing="1px"
                                      >
                                        Start Date:{" "}
                                        {format(
                                          new Date(
                                            item.training_date[0].startDate
                                          ),
                                          "dd LLLL y"
                                        )}
                                        <br />
                                        End Date:{" "}
                                        {format(
                                          new Date(
                                            item.training_date[
                                              item.training_date.length - 1
                                            ].endDate
                                          ),
                                          "dd LLLL y"
                                        )}
                                      </Text>
                                      {JSON.parse(item.training_fee)[0] ? (
                                        <>
                                          <Text
                                            fontSize="15px"
                                            fontStyle="italic"
                                          >
                                            Malaysian: RM{" "}
                                            {parseInt(
                                              JSON.parse(item.training_fee)[0]
                                                .ringgit
                                            )}
                                            <br />
                                            Non-Malaysian: USD{" "}
                                            {parseInt(
                                              JSON.parse(item.training_fee)[0]
                                                .dollar
                                            )}
                                          </Text>
                                        </>
                                      ) : (
                                        <>
                                          <Text
                                            fontSize="15px"
                                            fontStyle="italic"
                                          >
                                            Malaysian only : RM
                                            {parseInt(item.training_fee)}
                                          </Text>
                                        </>
                                      )}
                                      {typeof JSON.parse(item.training_hrdc) ===
                                        "object" &&
                                      JSON.parse(item.training_hrdc)[0].pax !==
                                        "" ? (
                                        <Text fontWeight="bold" fontSize="15px">
                                          LIMITED TO{" "}
                                          {
                                            JSON.parse(item.training_hrdc)[0]
                                              .pax
                                          }{" "}
                                          PAX ONLY.
                                        </Text>
                                      ) : (
                                        ""
                                      )}
                                      <Box bgColor="#FED7D7" w="300px">
                                        <Tag colorScheme="red">
                                          Last registration:{" "}
                                          {format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ).getTime() -
                                              86400000 * 5,
                                            "dd LLLL y"
                                          )}
                                        </Tag>
                                      </Box>
                                    </VStack>
                                  </>
                                ) : (
                                  <>
                                    <VStack>
                                      <Badge
                                        fontSize="12px"
                                        variant="subtle"
                                        colorScheme="yellow"
                                      >
                                        {JSON.parse(item.training_hrdc)[0]
                                          ? JSON.parse(item.training_hrdc)[0]
                                              .hrdc === 1 && "HRDCorp Claimable"
                                          : parseInt(item.training_hrdc) === 1
                                          ? "HRDCorp Claimable"
                                          : "Non-Claimable"}
                                      </Badge>
                                      <Text
                                        fontWeight="bold"
                                        letterSpacing="1px"
                                      >
                                        One Day:
                                        <br />
                                        {format(
                                          new Date(
                                            item.training_date[0].startDate
                                          ),
                                          "dd LLLL y"
                                        )}
                                      </Text>
                                      {JSON.parse(item.training_fee)[0] ? (
                                        <>
                                          <Text
                                            fontSize="15px"
                                            fontStyle="italic"
                                          >
                                            Malaysian: RM{" "}
                                            {parseInt(
                                              JSON.parse(item.training_fee)[0]
                                                .ringgit
                                            )}
                                            <br />
                                            Non-Malaysian: USD{" "}
                                            {parseInt(
                                              JSON.parse(item.training_fee)[0]
                                                .dollar
                                            )}
                                          </Text>
                                        </>
                                      ) : (
                                        <>
                                          <Text
                                            fontSize="15px"
                                            fontStyle="italic"
                                          >
                                            Malaysian only : RM
                                            {parseInt(item.training_fee)}
                                          </Text>
                                        </>
                                      )}
                                      {typeof JSON.parse(item.training_hrdc) ===
                                        "object" &&
                                      JSON.parse(item.training_hrdc)[0].pax !==
                                        "" ? (
                                        <Text fontWeight="bold" fontSize="15px">
                                          LIMITED TO{" "}
                                          {
                                            JSON.parse(item.training_hrdc)[0]
                                              .pax
                                          }{" "}
                                          PAX ONLY.
                                        </Text>
                                      ) : (
                                        ""
                                      )}
                                      <Box bgColor="#FED7D7" w="300px">
                                        <Tag colorScheme="red">
                                          Last registration:{" "}
                                          {format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ).getTime() -
                                              86400000 * 5,
                                            "dd LLLL y"
                                          )}
                                        </Tag>
                                      </Box>
                                    </VStack>
                                  </>
                                )}
                              </Button>
                            </>
                          ) : (
                            ""
                          )
                        ) : new Date(
                            item.training_date[0].startDate
                          ).getTime() -
                            86400000 >
                          new Date().getTime() ? (
                          <>
                            <Button
                              height={
                                item.training_date[0].startDate !==
                                item.training_date[0].endDate
                                  ? "180px"
                                  : "170px"
                              }
                              ml="40px"
                              w="300px"
                              p="35px"
                              m="2"
                              bg="blue.100"
                              _hover={{ bgColor: "blue.200" }}
                              onClick={async () => {
                                const res = await CRUDFunction.get(
                                  `${URL_STAGING}training/${item.training_id}`
                                );
                                setTrainingDetails(res);
                                setSession(item.training_date);
                                drawerDetail.onOpen();
                              }}
                            >
                              {item.training_date[0].startDate !==
                              item.training_date[0].endDate ? (
                                <>
                                  <VStack>
                                    <Badge
                                      fontSize="12px"
                                      variant="subtle"
                                      colorScheme="yellow"
                                    >
                                      {JSON.parse(item.training_hrdc)[0]
                                        ? JSON.parse(item.training_hrdc)[0]
                                            .hrdc === 1 && "HRDCorp Claimable"
                                        : parseInt(item.training_hrdc) === 1
                                        ? "HRDCorp Claimable"
                                        : "Non-Claimable"}
                                    </Badge>
                                    <Text fontWeight="bold" letterSpacing="1px">
                                      Start Date:{" "}
                                      {format(
                                        new Date(
                                          item.training_date[0].startDate
                                        ),
                                        "dd LLLL y"
                                      )}
                                      <br />
                                      End Date:{" "}
                                      {format(
                                        new Date(
                                          item.training_date[
                                            item.training_date.length - 1
                                          ].endDate
                                        ),
                                        "dd LLLL y"
                                      )}
                                    </Text>
                                    {JSON.parse(item.training_fee)[0] ? (
                                      <>
                                        <Text
                                          fontSize="15px"
                                          fontStyle="italic"
                                        >
                                          Malaysian: RM{" "}
                                          {parseInt(
                                            JSON.parse(item.training_fee)[0]
                                              .ringgit
                                          )}
                                          <br />
                                          Non-Malaysian: USD{" "}
                                          {parseInt(
                                            JSON.parse(item.training_fee)[0]
                                              .dollar
                                          )}
                                        </Text>
                                      </>
                                    ) : (
                                      <>
                                        <Text
                                          fontSize="15px"
                                          fontStyle="italic"
                                        >
                                          Malaysian only : RM
                                          {parseInt(item.training_fee)}
                                        </Text>
                                      </>
                                    )}
                                    {typeof JSON.parse(item.training_hrdc) ===
                                      "object" &&
                                    JSON.parse(item.training_hrdc)[0].pax !==
                                      "" ? (
                                      <Text fontWeight="bold" fontSize="15px">
                                        LIMITED TO{" "}
                                        {JSON.parse(item.training_hrdc)[0].pax}{" "}
                                        PAX ONLY.
                                      </Text>
                                    ) : (
                                      ""
                                    )}
                                    <Box bgColor="#FED7D7" w="300px">
                                      <Tag colorScheme="red">
                                        Last registration:{" "}
                                        {format(
                                          new Date(
                                            item.training_date[0].startDate
                                          ).getTime() -
                                            86400000 * 2,
                                          "dd LLLL y"
                                        )}
                                      </Tag>
                                    </Box>
                                  </VStack>
                                </>
                              ) : (
                                <>
                                  <VStack>
                                    <Badge
                                      fontSize="12px"
                                      variant="subtle"
                                      colorScheme="yellow"
                                    >
                                      {JSON.parse(item.training_hrdc)[0]
                                        ? JSON.parse(item.training_hrdc)[0]
                                            .hrdc === 1 && "HRDCorp Claimable"
                                        : parseInt(item.training_hrdc) === 1
                                        ? "HRDCorp Claimable"
                                        : "Non-Claimable"}
                                    </Badge>
                                    <Text fontWeight="bold" letterSpacing="1px">
                                      One Day:
                                      <br />
                                      {format(
                                        new Date(
                                          item.training_date[0].startDate
                                        ),
                                        "dd LLLL y"
                                      )}
                                    </Text>
                                    {JSON.parse(item.training_fee)[0] ? (
                                      <>
                                        <Text
                                          fontSize="15px"
                                          fontStyle="italic"
                                        >
                                          Malaysian: RM{" "}
                                          {parseInt(
                                            JSON.parse(item.training_fee)[0]
                                              .ringgit
                                          )}
                                          <br />
                                          Non-Malaysian: USD{" "}
                                          {parseInt(
                                            JSON.parse(item.training_fee)[0]
                                              .dollar
                                          )}
                                        </Text>
                                      </>
                                    ) : (
                                      <>
                                        <Text
                                          fontSize="15px"
                                          fontStyle="italic"
                                        >
                                          Malaysian only : RM
                                          {parseInt(item.training_fee)}
                                        </Text>
                                      </>
                                    )}
                                    {typeof JSON.parse(item.training_hrdc) ===
                                      "object" &&
                                    JSON.parse(item.training_hrdc)[0].pax !==
                                      "" ? (
                                      <Text fontWeight="bold" fontSize="15px">
                                        LIMITED TO{" "}
                                        {JSON.parse(item.training_hrdc)[0].pax}{" "}
                                        PAX ONLY.
                                      </Text>
                                    ) : (
                                      ""
                                    )}
                                    <Box bgColor="#FED7D7" w="300px">
                                      <Tag colorScheme="red">
                                        Last registration:{" "}
                                        {format(
                                          new Date(
                                            item.training_date[0].startDate
                                          ).getTime() -
                                            86400000 * 2,
                                          "dd LLLL y"
                                        )}
                                      </Tag>
                                    </Box>
                                  </VStack>
                                </>
                              )}
                            </Button>
                          </>
                        ) : (
                          ""
                        ))
                    )}
                </Box>
                <Box
                  mx="20%"
                  mt="20px"
                  textAlign="center"
                  fontWeight="bold"
                  bgColor="#4B85FA"
                  borderRadius="10px"
                >
                  <Text fontSize="20px" color="white">
                    Download guideline here <span>&#10230;</span>
                    <Link
                      isExternal
                      fontSize="18px"
                      color="white"
                      href={guideline}
                      download="Registration Guideline.pdf"
                    >
                      {" "}
                      Registration Guideline.pdf
                    </Link>
                  </Text>
                </Box>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  mt={5}
                  float={{ base: "center", md: "right" }}
                >
                  <Text my="auto">Register as:</Text>
                  <Button
                    px={20}
                    bg="#F9C611"
                    boxShadow="md"
                    onClick={() => {
                      nav("../enroll/individu", {
                        replace: false,
                        state: { id: enrollid },
                      });
                    }}
                  >
                    Individual
                  </Button>
                  <Button
                    px={20}
                    bg="#F9C611"
                    boxShadow="md"
                    onClick={() => {
                      nav("../enroll/company", {
                        replace: false,
                        state: { id: enrollid },
                      });
                    }}
                  >
                    Company
                  </Button>
                </Stack>
                <Box
                  color="white"
                  onClick={() => {
                    localStorage.setItem("hideModal", false);
                  }}
                >
                  SHOW
                </Box>
              </Box>
            ) : (
              <Text py="5">No upcoming trainings available.</Text>
            )
          ) : (
            <Text py="5">
              No trainings registered currently for this course.
            </Text>
          )}
        </Box>
      </Container>

      <Drawer
        isOpen={drawerDetail.isOpen}
        placement="right"
        onClose={drawerDetail.onClose}
        size="lg"
        fontSize="14px"
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <DrawerCloseButton />
          <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
            TRAINING DETAILS
          </DrawerHeader>
          <DrawerBody>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING NAME</b>
              <br />
              <br />
              {course.trainings && (
                <Text pl="30px">{trainingDetails.training_name}</Text>
              )}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING DATE</b>
              <br />
              <br />
              {course.trainings &&
                session.map((item, index) => (
                  <>
                    {item.endDate === item.startDate ? (
                      <Text pl="30px">
                        {format(new Date(item.startDate), "dd LLLL y")}
                      </Text>
                    ) : (
                      <Text pl="30px">
                        Week {index + 1}:{" "}
                        {format(new Date(item.startDate), "dd LLLL y")} -{" "}
                        {format(new Date(item.endDate), "dd LLLL y")}
                      </Text>
                    )}
                  </>
                ))}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING TIME</b>
              <br />
              <br />
              <Text pl="30px">
                {trainingDetails.training_time && (
                  <Text>
                    {Number(
                      JSON.parse(trainingDetails.training_time)[0][0] * 10
                    ) +
                      Number(JSON.parse(trainingDetails.training_time)[0][1]) <
                    11 ? (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[0][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[0][1]
                          )}
                        {JSON.parse(trainingDetails.training_time)[0].slice(2)}{" "}
                        AM
                      </>
                    ) : Number(
                        JSON.parse(trainingDetails.training_time)[0][0] * 10
                      ) +
                        Number(
                          JSON.parse(trainingDetails.training_time)[0][1]
                        ) >
                      12 ? (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[0][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[0][1]
                          ) -
                          12}
                        {JSON.parse(trainingDetails.training_time)[0].slice(2)}{" "}
                        PM
                      </>
                    ) : (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[0][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[0][1]
                          )}
                        {JSON.parse(trainingDetails.training_time)[0].slice(2)}{" "}
                        PM
                      </>
                    )}{" "}
                    -{" "}
                    {Number(
                      JSON.parse(trainingDetails.training_time)[1][0] * 10
                    ) +
                      Number(JSON.parse(trainingDetails.training_time)[1][1]) <
                    11 ? (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[1][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[1][1]
                          )}
                        {JSON.parse(trainingDetails.training_time)[1].slice(2)}{" "}
                        AM
                      </>
                    ) : Number(
                        JSON.parse(trainingDetails.training_time)[1][0] * 10
                      ) +
                        Number(
                          JSON.parse(trainingDetails.training_time)[1][1]
                        ) >
                      12 ? (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[1][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[1][1]
                          ) -
                          12}
                        {JSON.parse(trainingDetails.training_time)[1].slice(2)}{" "}
                        PM
                      </>
                    ) : (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[1][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[1][1]
                          )}
                        {JSON.parse(trainingDetails.training_time)[1].slice(2)}{" "}
                        PM
                      </>
                    )}
                  </Text>
                )}
                <br />
              </Text>
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING PLACE</b>
              <br />
              <br />
              {course.trainings && (
                <Text pl="30px">{trainingDetails.training_place}</Text>
              )}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING MODE</b>
              <br />
              <br />
              {course.trainings && (
                <Text pl="30px">
                  {displayModes[trainingDetails.training_mode]}
                </Text>
              )}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING DETAILS</b>
              <br />
              <br />
              {course.trainings && (
                <Text pl="30px">
                  {trainingDetails.training_detail !== "undefined" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: trainingDetails.training_detail,
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </Text>
              )}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>FEE</b>
              <br />
              <br />
              {course.trainings &&
              trainingDetails.training_fee &&
              JSON.parse(trainingDetails.training_fee)[0] ? (
                <Text pl="30px">
                  MYR {JSON.parse(trainingDetails.training_fee)[0].ringgit}
                  <span style={{ fontWeight: "bold" }}> (MALAYSIAN)</span> / USD
                  {"  "}
                  {JSON.parse(trainingDetails.training_fee)[0].dollar}
                  <span style={{ fontWeight: "bold" }}> (NON-MALAYSIAN)</span>
                </Text>
              ) : (
                <Text pl="30px">RM {trainingDetails.training_fee}</Text>
              )}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>HRDC CLAIMABLE</b>&nbsp;
              {course.trainings &&
              trainingDetails.training_hrdc &&
              JSON.parse(trainingDetails.training_hrdc)[0] ? (
                JSON.parse(trainingDetails.training_hrdc)[0].hrdc === 1 ? (
                  <Icon as={BsCheckCircleFill} color="green" />
                ) : (
                  <Icon as={BsXCircleFill} color="red" />
                )
              ) : parseInt(trainingDetails.training_hrdc) === 1 ? (
                <Icon as={BsCheckCircleFill} color="green" />
              ) : (
                <Icon as={BsXCircleFill} color="red" />
              )}
            </Box>
          </DrawerBody>

          <Center>
            <DrawerFooter></DrawerFooter>
          </Center>
        </DrawerContent>
      </Drawer>

      <Modal size="2xl" isOpen={modal.isOpen} onClose={modal.onClose} bg="blue">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(4px)" />
        <ModalContent my="auto" borderRadius="10px">
          <ModalHeader bg="gray.200" borderTopRadius="10px">
            <Center>
              <Image
                w={{ base: "100%", md: "50%" }}
                src={
                  "https://www.holisticslab.my/wp-content/uploads/2022/05/HLSB-2048x502.png"
                }
              />
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <Center>
            <ModalBody align="center" p="20px 20px 0px 20px">
              <Text fontWeight="bold" fontSize="23px" color="black">
                Please refer to our Training Registration Guideline to avoid any
                inconvenience. Thank you.
              </Text>
              <br />
              <Link
                isExternal
                fontSize="18px"
                color="blue.400"
                href={guideline}
                download="Registration Guideline.pdf"
              >
                Download guideline here (Registration Guideline.pdf)
              </Link>
            </ModalBody>
          </Center>
          <Box m="10px" w="fit-content">
            <Checkbox
              onChange={(e) => {
                localStorage.setItem("hideModal", true);
              }}
            >
              Do not show again
            </Checkbox>
          </Box>
          <Button onClick={modal.onClose} _hover={{ bgColor: "red.200" }}>
            Close
          </Button>
        </ModalContent>
      </Modal>
    </>
  );
}

import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Spacer,
  Text,
  Stack,
  Skeleton,
  Select,
  HStack,
} from "@chakra-ui/react";
import {
  usePagination,
  useSortBy,
  useTable,
  disableSortBy,
  useGlobalFilter,
  useFilters,
  useExpanded,
} from "react-table";
import { SearchFilter } from "../header";
import { v4 } from "uuid";
import "./../../assets/css/custom.css";
import Pagination from "./paginate";
import CRUDFunction from "../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../constant";

function Table({
  columns,
  data,
  fetchData,
  modulePage,
  renderRowSubComponent,
  tableLoader,
  totalRows,
  pageChangeHandler,
  rowsPerPage,
  handleData,
  setCurrentRows,
  themeCtx,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    state,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageOptions,
    state: { pageIndex },
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination:
        modulePage === "attendance" ||
        modulePage === "course" ||
        modulePage === "print" ||
        modulePage === "feedback" ||
        modulePage === "settings" ||
        modulePage === "discount" ||
        modulePage === "company" ||
        modulePage === "participant"
          ? false
          : true,
      disableSortBy,
      disableSortRemove: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );
  const [year, setYear] = useState("2023");
  const [pageFilter, setPageFilter] = useState(rowsPerPage);

  const handleYearData = async (event) => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}trainingyear/${event.target.value}/${pageFilter}?page=1`
    );
    if (res) {
      handleData((prevState) => ({
        ...prevState,
        rowData: res.data,
        tableLoader: false,
        totalPages: res.last_page,
        totalData: res.total,
      }));
      setYear(event.target.value);
    }
  };

  const handleMonthData = async (event) => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}trainingmonth/${year}/${event.target.value}/${rowsPerPage}`
    );
    if (res) {
      handleData((prevState) => ({
        ...prevState,
        rowData: res.data,
        tableLoader: false,
        totalPages: res.last_page,
        totalData: res.total,
      }));
    }
  };

  return (
    <>
      {modulePage === "discount" || modulePage === "attendance" ? (
        ""
      ) : (
        <Box w="40%">
          <SearchFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            themeCtx={themeCtx}
          />
        </Box>
      )}

      {modulePage === "training" ? (
        <>
          <Box w="40%">
            <HStack>
              <Select
                width="50%"
                bg={themeCtx.mode && "#1b1b1b"}
                color={themeCtx.mode && "white"}
                _active={{ bgColor: themeCtx.mode && "#1b1b1b" }}
                border="0px"
                onClick={() => {
                  setPageFilter(100);
                }}
                onChange={handleYearData}
              >
                <option
                  value=""
                  selected
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  Select Year
                </option>
                <option
                  value="2018"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  2018
                </option>
                <option
                  value="2019"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  2019
                </option>
                <option
                  value="2020"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  2020
                </option>
                <option
                  value="2021"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  2021
                </option>
                <option
                  value="2022"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  2022
                </option>
                <option
                  value="2023"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  2023
                </option>
              </Select>
              <Select
                width="50%"
                bg={themeCtx.mode && "#1b1b1b"}
                color={themeCtx.mode && "white"}
                border="0px"
                onChange={handleMonthData}
              >
                <option
                  value="default"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                  selected
                >
                  Select Month
                </option>
                <option
                  value="01"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  January
                </option>
                <option
                  value="02"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  February
                </option>
                <option
                  value="03"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  March
                </option>
                <option
                  value="04"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  April
                </option>
                <option
                  value="05"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  May
                </option>
                <option
                  value="06"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  June
                </option>
                <option
                  value="07"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  July
                </option>
                <option
                  value="08"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  August
                </option>
                <option
                  value="09"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  September
                </option>
                <option
                  value="10"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  October
                </option>
                <option
                  value="11"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  November
                </option>
                <option
                  value="12"
                  style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                >
                  Disember
                </option>
              </Select>
            </HStack>
          </Box>
          <br />
        </>
      ) : (
        ""
      )}

      {modulePage !== "print" ? (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={v4()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      textTransform: "uppercase",
                      backgroundColor: themeCtx && themeCtx.mode && "#1b1b1b",
                      color: themeCtx && themeCtx.mode && "white",
                    }}
                    key={v4()}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {
                        column.isSorted
                          ? column.isSortedDesc
                            ? " ▼"
                            : // <ArrowDownIcon />
                              " ▲"
                          : // <ArrowUpIcon />
                            "\r"
                        // <UpDownIcon />
                      }
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} key={v4()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={v4()}
                          style={{
                            backgroundColor:
                              themeCtx && themeCtx.mode ? "#1b1b1b" : "white",
                            color: themeCtx && themeCtx.mode && "white",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td
                        colSpan={visibleColumns.length}
                        style={{
                          color: themeCtx && themeCtx.mode && "white",
                          backgroundColor:
                            themeCtx && themeCtx.mode ? "#1b1b1b" : "white",
                        }}
                      >
                        {/*
                            Inside it, call our renderRowSubComponent function. In reality,
                            you could pass whatever you want as props to
                            a component like this, including the entire
                            table instance. But for this example, we'll just
                            pass the row
                          */}
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </>
              );
            })}
          </tbody>
        </table>
      ) : (
        <table
          {...getTableProps()}
          // className="table-training"
          style={{ width: "100%" }}
          id="printJS-form"
        >
          <thead className="table-head">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={v4()}>
                {headerGroup.headers.map((column) => {
                  if (column.Header === "#") {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontSize: "14px",
                          width: "5%",
                        }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "🔽"
                              : "🔼"
                            : ""}
                        </span>
                      </th>
                    );
                  } else if (column.Header === "Trainee") {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontSize: "14px",
                          width: "20%",
                        }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "🔽"
                              : "🔼"
                            : ""}
                        </span>
                      </th>
                    );
                  } else if (column.Header === "Company") {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontSize: "14px",
                          width: "20%",
                        }}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  } else if (column.Header === "Contact") {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontSize: "14px",
                          width: "15%",
                        }}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  } else if (column.Header === "Training") {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontSize: "12px",
                          width: "30%",
                        }}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  } else if (column.Header === "Payment") {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontSize: "14px",
                          width: "5%",
                        }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "🔽"
                              : "🔼"
                            : ""}
                        </span>
                      </th>
                    );
                  } else if (column.Header === "HRDF") {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontSize: "14px",
                          width: "5%",
                        }}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  }
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} key={v4()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} key={v4()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {/*
                            Inside it, call our renderRowSubComponent function. In reality,
                            you could pass whatever you want as props to
                            a component like this, including the entire
                            table instance. But for this example, we'll just
                            pass the row
                          */}
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </>
              );
            })}
          </tbody>
        </table>
      )}
      <br />
      {tableLoader && (
        <Stack>
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
        </Stack>
      )}
      <br />
      {modulePage === "trainee" ||
      modulePage === "attendance" ||
      modulePage === "course" ||
      modulePage === "print" ||
      modulePage === "feedback" ||
      modulePage === "settings" ||
      modulePage === "discount" ||
      modulePage === "company" ||
      modulePage === "participant" ||
      modulePage === "statistics" ? (
        <Flex className="pagination">
          <Text my="auto" fontSize="sm" fontWeight="bold" color="#c4c4c4">
            Showing the first {page.length} results of {rows.length} rows
          </Text>
          <Spacer />
          <Button
            mr="2"
            onClick={() => gotoPage(0)}
            borderRadius="100px"
            bg={themeCtx && themeCtx.mode ? "#1b1b1b" : "#d9d9d9"}
            color="white"
            disabled={!canPreviousPage}
          >
            <strong>{"<<"}</strong>
          </Button>
          <Button
            mr="2"
            onClick={() => previousPage()}
            borderRadius="100px"
            bg={themeCtx && themeCtx.mode ? "#1b1b1b" : "#d9d9d9"}
            color="white"
            disabled={!canPreviousPage}
          >
            <strong>{"<"}</strong>
          </Button>
          <Text my="auto" mr="2" color="#33945f">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </Text>
          <Button
            mr="2"
            onClick={() => nextPage()}
            borderRadius="100px"
            bg={themeCtx && themeCtx.mode ? "#1b1b1b" : "#d9d9d9"}
            color="white"
            disabled={!canNextPage}
          >
            <strong>{">"}</strong>
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            borderRadius="100px"
            bg={themeCtx && themeCtx.mode ? "#1b1b1b" : "#d9d9d9"}
            color="white"
            disabled={!canNextPage}
          >
            <strong>{">>"}</strong>
          </Button>{" "}
          <Spacer />
          <Text>
            <strong style={{ color: "#c4c4c4" }}>Go to page: </strong>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{
                padding: "5px",
                width: "80px",
                fontWeight: "bold",
                textAlign: "center",
                color: "#33945f",
                backgroundColor: themeCtx && themeCtx.mode && "#3b3b3b",
                border: "3px solid #33945f",
              }}
            />
          </Text>{" "}
          {/* <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select> */}
        </Flex>
      ) : (
        <Pagination
          totalRows={totalRows}
          data={data}
          modulePage={modulePage}
          pageChangeHandler={pageChangeHandler}
          rowsPerPage={pageFilter}
          currentRows={rowsPerPage}
          setData={handleData}
          setCurrentRows={setCurrentRows}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default Table;

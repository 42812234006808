import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import { Grid, GridItem } from "@chakra-ui/react";
import { Header, Footer } from "../../components";

const LayoutClient = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  return (
    <Grid templateAreas={`"header" "main" "footer"`} w="100%" h="auto">
      <GridItem colSpan={9} area={"header"} zIndex="1">
        <Header />
      </GridItem>
      <GridItem
        area={"main"}
        my={windowSize.current[0] > 500 ? "5%" : "0%"}
        colSpan={9}
        bgColor="#ffffff"
        zIndex="0"
      >
        <Outlet />
      </GridItem>
      <GridItem colSpan={9} area={"footer"} zIndex="1">
        <Footer />
      </GridItem>
    </Grid>
  );
};

export { LayoutClient };

import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  Icon,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Select,
  Input,
  Switch,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { FileUploader } from "react-drag-drop-files";
import { FaFileImage } from "react-icons/fa";
import * as moment from "moment";
import CRUDFunction from "../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../constant";
import pdfMake from "pdfmake";
import axios from "axios";

const fontlist = [
  "Arial",
  "Arial Black",
  "Bahnschrift",
  "Brush Script MT",
  "Calibri",
  "Cambria",
  "Cambria Math",
  "Candara",
  "Comic Sans MS",
  "Consolas",
  "Constantia",
  "Corbel",
  "Courier New",
  "Century Gothic",
  "Ebrima",
  "Franklin Gothic Medium",
  "Gabriola",
  "Gadugi",
  "Georgia",
  "HoloLens MDL2 Assets",
  "Impact",
  "Ink Free",
  "Javanese Text",
  "Leelawadee UI",
  "Lucida Console",
  "Lucida Handwriting",
  "Lucida Sans Unicode",
  "Malgun Gothic",
  "MingLiU-ExtB",
  "Mongolian Baiti",
  "MS Gothic",
  "MV Boli",
  "Myanmar Text",
  "Nirmala UI",
  "Palatino Linotype",
  "Segoe MDL2 Assets",
  "Segoe Print",
  "Segoe Script",
  "Segoe UI",
  "Segoe UI Historic",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "SimSun",
  "Sitka",
  "Sylfaen",
  "Symbol",
  "Tahoma",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
  "Yu Gothic",
];

function CanvasComponent({ canvasConfig, type, title }) {
  console.log(canvasConfig);
  const [generateimg, setGenerateImg] = useState("");
  const canvasRef = useRef(null);
  const configItem = useRef({});
  const imgdisplay = useRef(null);
  const subConfig = useRef({});
  const offsetX = useRef(null);
  const offsetY = useRef(null);
  const startX = useRef(null);
  const startY = useRef(null);
  const selectedText = useRef(null);

  const [file, setFile] = useState("");
  const [configState, setConfigState] = useState({});
  const [screenRatio, setScreenRatio] = useState(1);
  const [csswidth, setcsswidth] = useState("0");
  const [cssheight, setcssheight] = useState("0");

  const [docName, setDocName] = useState("");

  useEffect(() => {
    loadCanvas();
  }, []);

  const loadCanvas = async () => {
    if (canvasConfig !== null) {
      configItem.current = canvasConfig.config;
      setDocName(canvasConfig.title);
      let imgsrc = `${URL_STAGING}template/getimg/${btoa(
        canvasConfig.imageDir
      )}`;

      if (type === "edit") {
        renderCanvas(imgsrc)
          .then((x) => {
            draw(true);
          })
          .catch((e) => console.log(e));
      } else {
        renderGenerate(imgsrc);
      }
    }
  };

  const renderGenerate = (imgsrc) => {
    var img = new Image();
    img.crossOrigin = "";
    img.onload = function () {
      let canvas = canvasRef.current;
      let context = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      context.canvas.width = img.width;
      context.canvas.height = img.height;

      let fixheight = window.innerHeight * 0.9;
      let fixwidth = window.innerWidth * 0.6;
      let widthR = fixwidth / img.width;
      let heightR = fixheight / img.height;

      let ratios = Math.min(widthR, heightR);
      ratios = Math.floor(ratios * 100) / 100;

      setScreenRatio(ratios);
      var calwidth = img.width * ratios;
      var calheight = img.height * ratios;
      setcsswidth(calwidth);
      setcssheight(calheight);

      imgdisplay.current = img;
      context.drawImage(img, 0, 0);

      offsetX.current = canvas.offsetLeft;
      offsetY.current = canvas.offsetTop;

      Object.keys(configItem.current).forEach((prop) => {
        const { sample, font, style, size, x, y, color, align, width, height } =
          configItem.current[prop];

        context.font = style + " " + size + "px " + font;

        subConfig.current[prop] = {
          width: context.measureText(sample).width,
        };

        context.fillStyle = color;
        context.textAlign = align;

        if (prop === "Company Address") {
          var lines = sample.split("\n");
          for (let i = 0; i < lines.length; i++) {
            context.fillText(lines[i], x, y + i * 30);
          }
        } else {
          context.fillText(sample, x, y);
        }
      });

      let dataurl = canvas.toDataURL("image/jpeg");
      setGenerateImg(dataurl);
    };
    img.src = imgsrc;
  };

  const renderCanvas = (src) => {
    return new Promise((resolve, reject) => {
      var img = new Image();

      img.onload = function () {
        let canvas = canvasRef.current;
        let context = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context.canvas.width = img.width;
        context.canvas.height = img.height;

        let fixheight = window.innerHeight * 0.9;
        let fixwidth = window.innerWidth * 0.6;
        let widthR = fixwidth / img.width;
        let heightR = fixheight / img.height;

        let ratios = Math.min(widthR, heightR);
        ratios = Math.floor(ratios * 100) / 100;

        setScreenRatio(ratios);
        var calwidth = img.width * ratios;
        var calheight = img.height * ratios;
        setcsswidth(calwidth);
        setcssheight(calheight);

        imgdisplay.current = img;
        context.drawImage(img, 0, 0);

        offsetX.current = canvas.offsetLeft;
        offsetY.current = canvas.offsetTop;
        resolve(true);
      };
      img.src = src;
    });
  };

  const onSubmit = async () => {
    if (docName.length < 1) {
      alert("Please fill the Template Name");
    } else if (docName || canvasConfig) {
      const formdata = new FormData();
      let removeSpace = docName.replaceAll(" ", "_");
      formdata.append("title", removeSpace);
      formdata.append("configDoc", JSON.stringify(configItem.current));
      if (canvasConfig !== null) {
        formdata.append("key", canvasConfig);
        formdata.append("imageDir", canvasConfig.imageDir);
      } else if (file) {
        formdata.append("image", file);
      }

      const res = await CRUDFunction.create(
        `${URL_STAGING}template/upload`,
        formdata
      );
      if (res) {
        if (canvasConfig === null) {
          alert("Template added!");
        } else {
          alert("Template edited!");
        }
        window.close();
      }
    } else {
      alert("Please attach an image");
    }
  };

  const handleDownload = () => {
    var dd = {
      pageSize: "A4",
      // pageOrientation: "landscape",
      pageMargins: [40, 60, 40, 60],
      background: [
        {
          image: generateimg,
          fit: [841.89, 841.89],
        },
      ],
    };
    pdfMake.createPdf(dd).download(title + ".pdf");
  };

  const onUpload = (file) => {
    setFile(file);
    var reader = new FileReader();

    reader.onload = function (e) {
      renderCanvas(e.target.result)
        .then((x) => {
          newConfig();
        })
        .catch((e) => console.log(e));
    };

    reader.readAsDataURL(file);
  };

  const newConfig = async () => {
    let canvas = canvasRef.current;

    if (title === "TAA") {
      configItem.current = {
        "Company Name": {
          enable: true,
          sample: "Company Name",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.18,
          align: "left",
          color: "#000000",
        },
        "Company Name 2": {
          enable: true,
          sample: "Company Name 2",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.53,
          y: canvas.height * 0.25,
          align: "left",
          color: "#000000",
        },
        "Company Address": {
          enable: true,
          sample: "Company Address",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.2,
          align: "left",
          color: "#000000",
        },
        "Course Title": {
          enable: true,
          sample: "Course Title",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.3,
          align: "left",
          color: "#000000",
        },
        "Training Date": {
          enable: true,
          sample: "Training Date",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.32,
          align: "left",
          color: "#000000",
        },
        "No. of pax": {
          enable: true,
          sample: "No. of pax",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.35,
          align: "left",
          color: "#000000",
        },
        "No. of days": {
          enable: true,
          sample: "No. of days",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.37,
          align: "left",
          color: "#000000",
        },
        "Total Fees": {
          enable: true,
          sample: "Total Fees",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.4,
          align: "left",
          color: "#000000",
        },
      };
    } else if (title === "JD 14") {
      configItem.current = {
        "Company Name": {
          enable: true,
          sample: "Company Name",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.1,
          align: "left",
          color: "#000000",
        },
        "Company Address": {
          enable: true,
          sample: "Company Address",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.15,
          align: "left",
          color: "#000000",
        },
        "Employer Code": {
          enable: true,
          sample: "Employer Code",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.2,
          align: "left",
          color: "#000000",
        },
        "Approval No": {
          enable: true,
          sample: "Approval No",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.25,
          align: "left",
          color: "#000000",
        },
        "Course Title": {
          enable: true,
          sample: "Course Title",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.3,
          align: "left",
          color: "#000000",
        },
        "Start Date": {
          enable: true,
          sample: "Start Date",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.35,
          align: "left",
          color: "#000000",
        },
        "End Date": {
          enable: true,
          sample: "End Date",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.4,
          align: "left",
          color: "#000000",
        },
        "Training Venue": {
          enable: true,
          sample: "Training Venue",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.45,
          align: "left",
          color: "#000000",
        },
        Pax: {
          enable: true,
          sample: "Pax",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.5,
          align: "left",
          color: "#000000",
        },
        "Fee Approved": {
          enable: true,
          sample: "Fee Approved",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.55,
          align: "left",
          color: "#000000",
        },
        "Fee Claimed": {
          enable: true,
          sample: "Fee Claimed",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.6,
          align: "left",
          color: "#000000",
        },
      };
    } else if (title === "Proforma") {
      configItem.current = {
        "Company Name": {
          enable: true,
          sample: "Company Name",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.17,
          align: "left",
          color: "#000000",
        },
        "Company Address": {
          enable: true,
          sample: "Company Address",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.19,
          align: "left",
          color: "#000000",
        },
        "Training Name": {
          enable: true,
          sample: "Training Name",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.35,
          align: "left",
          color: "#000000",
        },
        "Start Date": {
          enable: true,
          sample: "Start Date",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.39,
          align: "left",
          color: "#000000",
        },
        "End Date": {
          enable: true,
          sample: "End Date",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.41,
          align: "left",
          color: "#000000",
        },
        "Training Time": {
          enable: true,
          sample: "Training Time",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.45,
          align: "left",
          color: "#000000",
        },
        "Training Place": {
          enable: true,
          sample: "Training Place",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.33,
          y: canvas.height * 0.5,
          align: "left",
          color: "#000000",
        },
        Fee: {
          enable: true,
          sample: "Fee",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.6,
          y: canvas.height * 0.5,
          align: "center",
          color: "#000000",
        },
        Pax: {
          enable: true,
          sample: "Pax",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.7,
          y: canvas.height * 0.5,
          align: "center",
          color: "#000000",
        },
        "Total Fee": {
          enable: true,
          sample: "Total Fee",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.87,
          y: canvas.height * 0.5,
          align: "center",
          color: "#000000",
        },
        Depo: {
          enable: true,
          sample: "Depo",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.18,
          y: canvas.height * 0.68,
          align: "left",
          color: "#000000",
        },
        Dateline: {
          enable: true,
          sample: "Dateline",
          font: "Century Gothic",
          style: "bold",
          size: 30,
          x: canvas.width * 0.37,
          y: canvas.height * 0.68,
          align: "left",
          color: "#000000",
        },
      };
    }
    setConfigState(configItem.current);
    draw(true);
  };

  const RenderConfig = ({ data }) =>
    Object.keys(data).map((prop) => {
      return (
        <AccordionItem>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <b>{prop}</b>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel px="50px">
            <FormControl mt="15px">
              <FormLabel>Font</FormLabel>
              <Select
                defaultValue={configItem.current[prop].font}
                onChange={(e) => {
                  configItem.current[prop].font = e.target.value;
                  draw();
                }}
              >
                {fontlist.map((data) => (
                  <option>{data}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt="15px">
              <FormLabel>Font size</FormLabel>
              <NumberInput
                defaultValue={configItem.current[prop].size}
                onChange={(e) => {
                  configItem.current[prop].size = parseFloat(e);
                  draw();
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl mt="15px">
              <FormLabel>Font color</FormLabel>
              <Input
                type="color"
                onChange={(e) => {
                  configItem.current[prop].color = e.target.value;
                  draw();
                }}
              />
            </FormControl>

            <Switch
              defaultChecked={configItem.current[prop].style === "bold"}
              mt="15px"
              onChange={(e) => {
                if (configItem.current[prop].style == "bold") {
                  configItem.current[prop].style = "normal";
                } else {
                  configItem.current[prop].style = "bold";
                }
                draw();
              }}
            >
              Bold
            </Switch>

            <Select
              mt="15px"
              defaultValue={configItem.current[prop].align}
              onChange={(e) => {
                configItem.current[prop].align = e.target.value;
                draw();
              }}
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </Select>
          </AccordionPanel>
        </AccordionItem>
      );
    });

  const draw = (init) => {
    let canvas = canvasRef.current;
    let ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(imgdisplay.current, 0, 0);
    Object.keys(configItem.current).forEach((prop) => {
      const { sample, font, style, size, x, y, color, align, width, height } =
        configItem.current[prop];

      ctx.font = style + " " + size + "px " + font;

      if (init) {
        subConfig.current[prop] = {
          width: ctx.measureText(sample).width,
        };
      }

      ctx.fillStyle = color;
      ctx.textAlign = align;

      if (prop === "Company Address") {
        var lines = sample.split("\n");
        for (let i = 0; i < lines.length; i++) {
          ctx.fillText(lines[i], x, y + i * 30);
        }
      } else {
        ctx.fillText(sample, x, y);
      }

      if (init) {
        console.log(subConfig.current);
      }
    });
  };

  const textHittest = (dx, dy, key) => {
    let { height, size, align, x, y } = configItem.current[key];
    let { width } = subConfig.current[key];
    width = parseFloat(width);
    height = parseFloat(height);
    size = parseFloat(size);

    if (key == "qr")
      return dx >= x && dx <= x + width && dy >= y - height && dy >= y;
    else if (align == "center")
      return (
        dx >= x - width / 2 &&
        dx <= x - width / 2 + width &&
        dy >= y - size &&
        dy <= y
      );
    else if (align == "right")
      return dx >= x - width && dx <= x && dy >= y - size && dy <= y;
    else return dx >= x && dx <= x + width && dy >= y - size && dy <= y;
  };

  function handleMouseDown(e) {
    e.preventDefault();
    startX.current = parseInt(e.clientX - offsetX.current) / screenRatio;
    startY.current = parseInt(e.clientY - offsetY.current) / screenRatio;
    // Put your mousedown stuff here,

    Object.keys(configItem.current).forEach((prop) => {
      if (configItem.current[prop].enable) {
        if (textHittest(startX.current, startY.current, prop)) {
          selectedText.current = prop;
        }
      }
    });
  }

  // done dragging
  const handleMouseUp = (e) => {
    e.preventDefault();
    setConfigState(configItem.current);
    selectedText.current = null;
  };

  // also done dragging
  const handleMouseOut = (e) => {
    e.preventDefault();
    setConfigState(configItem.current);
    selectedText.current = null;
  };

  // handle mousemove events
  // calc how far the mouse has been dragged since
  // the last mousemove event and move the selected text
  // by that distance
  const handleMouseMove = (e) => {
    if (selectedText.current == null) {
      return;
    }
    e.preventDefault();
    let mouseX = parseInt(e.clientX - offsetX.current) / screenRatio;
    let mouseY = parseInt(e.clientY - offsetY.current) / screenRatio;

    // Put your mousemove stuff here
    var dx = mouseX - startX.current;
    var dy = mouseY - startY.current;
    startX.current = mouseX;
    startY.current = mouseY;

    var text = configItem.current[selectedText.current];
    text.x += dx;
    text.y += dy;

    draw();
  };

  return (
    <>
      {file || canvasConfig !== null ? (
        <Grid templateColumns="repeat(2,1fr)">
          <GridItem>
            {type !== "generate" ? (
              <canvas
                ref={canvasRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseOut}
                onMouseUp={handleMouseUp}
                style={{
                  width: csswidth,
                  maxHeight: cssheight,
                  borderWidth: "1px",
                }}
              />
            ) : (
              <canvas
                ref={canvasRef}
                style={{
                  width: csswidth,
                  maxHeight: cssheight,
                  borderWidth: "1px",
                }}
              />
            )}
          </GridItem>
          <GridItem GridItem px="50px">
            {type === "upload" ? (
              <>
                <FormControl my="20px">
                  <FormLabel fontWeight="bold">Template Name</FormLabel>
                  <Input
                    defaultValue={window.title && window.title}
                    onBlur={(e) => {
                      setDocName(e.target.value);
                    }}
                  />
                </FormControl>

                <Accordion allowToggle>
                  <RenderConfig data={configState} />
                </Accordion>

                <Button
                  w="100%"
                  mt="40px"
                  colorScheme="green"
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </>
            ) : type === "edit" ? (
              <>
                <FormControl my="20px">
                  <FormLabel fontWeight="bold">Template Name</FormLabel>
                  <Input
                    isDisabled={canvasConfig !== null}
                    defaultValue={docName.replaceAll("_", " ")}
                    onBlur={(e) => {
                      setDocName(e.target.value);
                    }}
                  />
                </FormControl>
                <Accordion allowToggle>
                  <RenderConfig data={configState} />
                </Accordion>
                <Button
                  w="100%"
                  mt="40px"
                  colorScheme="green"
                  onClick={onSubmit}
                >
                  Save
                </Button>
              </>
            ) : (
              <Button
                w="100%"
                mt="40px"
                colorScheme="green"
                onClick={() => {
                  handleDownload();
                }}
              >
                Download
              </Button>
            )}
          </GridItem>
        </Grid>
      ) : (
        <Box pt="300px">
          <FileUploader
            handleChange={onUpload}
            name="file"
            types={["JPEG", "JPG"]}
          >
            <VStack>
              <Icon as={FaFileImage} boxSize={20} mb="30px" color="black" />
              <Text fontWeight="bold" fontSize="20px" color="black">
                No template loaded
              </Text>
              <Button colorScheme="teal">Add Template</Button>
            </VStack>
          </FileUploader>
        </Box>
      )}
    </>
  );
}

export { CanvasComponent };

import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  Stack,
  Box,
  Image,
  Heading,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  Select,
  Skeleton,
} from "@chakra-ui/react";
import styled from "styled-components";
import hl_logo from "../../assets/logo.png";

import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { URL_LOCAL, URL_STAGING } from "../../constant";
import CRUDFunction from "../../functions/CRUDFunction";
import ThemeContext from "../../functions/ThemeContext";

const Styles = styled.div`
  padding: 1rem;

  #header {
    text-align: center;
    border: 1px solid;
  }
  #header img {
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
  }

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :hover {
        background-color: #ebebeb;
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :first-child {
        width: 5%;
      }
      :nth-child(2) {
        width: 350px;
      }
    }

    td {
      padding: 0.5rem;
      :last-child {
        margin-top: 20px;
      }
    }
  }
`;

export default function TraineeSummaryTable({
  date,
  data,
  setData,
  print,
  course,
  training,
  tableLoader,
}) {
  const editModal = useDisclosure();
  const [edit, setEdit] = useState({
    id: "",
    trainee_id: "",
    time: "--:--",
    date: "",
    punch: "",
    status: "",
  });

  const [isActive, setActive] = useState(false);

  const { register, reset, handleSubmit } = useForm();
  const themeCtx = useContext(ThemeContext);

  const dataAttendance = async (sumdata) => {
    if (sumdata) {
      await sumdata.trainees.map((item) => {
        //if trainee's attendance is zero
        if (item.attendance.length === 0) {
          for (let i = 0; i < date.length; i++) {
            if (!item.attendance[i]) {
              item.attendance.push({
                attendance_punchin: "--:--",
              });
            }

            item.attendance[i].attendance_punchout = "--:--";
            if (item.pivot.join_status === "3") {
              item.attendance[i].attendance_status = 3;
            }
          }
        } else {
          for (let i = 0; i < date.length; i++) {
            var temp = "--:--";
            var temp2 = "--:--";

            if (item.attendance[i] !== undefined) {
              if (item.attendance[i].attendance_punchin !== null) {
                temp = item.attendance[i].attendance_punchin;
              }
              if (item.attendance[i].attendance_punchout !== null) {
                temp2 = item.attendance[i].attendance_punchout;
              }
              if (item.attendance[i].attendance_status === 1) {
              }

              if (!temp.includes(date[i]) && !temp2.includes(date[i])) {
                if (item.pivot.join_status === "3") {
                  item.attendance.splice(i, 0, {
                    attendance_punchin: "--:--",
                    attendance_punchout: "--:--",
                    attendance_status: 3,
                  });
                } else {
                  item.attendance.splice(i, 0, {
                    attendance_punchin: "--:--",
                    attendance_punchout: "--:--",
                  });
                }
              } else if (!temp.includes(date[i])) {
                item.attendance[i].attendance_punchin = "--:--";
              } else if (!temp2.includes(date[i])) {
                item.attendance[i].attendance_punchout = "--:--";
              }
            } else {
              if (item.pivot.join_status === "3") {
                item.attendance.splice(i, 0, {
                  attendance_punchin: "--:--",
                  attendance_punchout: "--:--",
                  attendance_status: 3,
                });
              } else {
                item.attendance.splice(i, 0, {
                  attendance_punchin: "--:--",
                  attendance_punchout: "--:--",
                });
              }
            }
          }
        }
      });
      setData(sumdata);
    }
  };

  const onSubmit = async (datas) => {
    if (datas) {
      const formData = new FormData();

      if (datas.attd_id) {
        formData.append("attendance_id", datas.attd_id);
      }

      if (datas.time === "") {
        datas.time = "00:00";
        formData.append("attendance_status", 1);
      } else {
        setActive(false);
        formData.append("attendance_status", 2);
      }

      if (datas.punch === "in") {
        formData.append("attendance_punchin", edit.date + " " + datas.time);
      } else if (datas.punch === "out" && datas.time !== "") {
        formData.append("attendance_punchout", edit.date + " " + datas.time);
      }
      formData.append("training_id", data.training_id);
      formData.append("trainee_id", edit.trainee_id);

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      //update
      if (datas.attd_id) {
        const res = await CRUDFunction.update(
          `${URL_STAGING}attdsummary/${datas.attd_id}`,
          formData
        );
        if (res) {
          reset();
          setActive(false);
          dataAttendance(res);

          editModal.onClose();
        }
      } else {
        //create

        const res = await CRUDFunction.create(
          `${URL_STAGING}attdsummary`,
          formData
        );
        if (res) {
          reset();
          setActive(false);
          dataAttendance(res);

          editModal.onClose();
        }
      }
    }
  };

  return (
    <>
      <div id="printSummary">
        <Styles>
          <div>
            {print && (
              <div className="header">
                <div style={{ textAlign: "center", marginLeft: "120px" }}>
                  <img
                    src={hl_logo}
                    style={{ marginLeft: "360px", width: "28%" }}
                  />
                  <span>{data.course.course_name}</span>
                  <p>{training}</p>
                </div>
                <div style={{ float: "left", width: "250px" }}>
                  * <span style={{ color: "red" }}>A</span>
                  <span> = Absent</span>
                  <span style={{ color: "#D4AC0D" }}> W</span>
                  <span> = Withdrew</span>
                </div>
              </div>
            )}

            <table>
              <tr
                style={{
                  color: themeCtx.mode && "white",
                }}
              >
                <th style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}>
                  #
                </th>
                <th style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}>
                  {print ? "NAME" : "TRAINEE"}
                </th>
                {date.map((date) => (
                  <th style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}>
                    {format(new Date(date), "dd/LL/y")}
                  </th>
                ))}
              </tr>
              {tableLoader && (
                <>
                  <tr>
                    <td colspan={2 + date.length}>
                      <Skeleton height="50px" />
                    </td>
                  </tr>
                  <tr>
                    <td colspan={2 + date.length}>
                      <Skeleton height="50px" />
                    </td>
                  </tr>
                  <tr>
                    <td colspan={2 + date.length}>
                      <Skeleton height="50px" />
                    </td>
                  </tr>
                </>
              )}
              {data.trainees &&
                data.trainees.map((item, i) => (
                  <tr
                    style={{
                      color: themeCtx.mode && "white",
                      backgroundColor: themeCtx.mode && "#1b1b1b",
                    }}
                  >
                    <td>{i + 1}</td>
                    <td>
                      <Text
                        textAlign="left"
                        textTransform="capitalize"
                        noOfLines={2}
                        color={item.pivot.join_status === "3" && "#D4AC0D"}
                      >
                        {item.trainee_name.toLowerCase()}
                      </Text>
                    </td>
                    {item.attendance.map((itm, index) => (
                      <>
                        <td>
                          {
                            <>
                              {/* punchin */}
                              <Link
                                color={
                                  item.pivot.join_status === "3" &&
                                  itm.attendance_status === 1
                                    ? "red"
                                    : item.pivot.join_status === "3"
                                    ? "#D4AC0D"
                                    : itm.attendance_status === 1 && "red"
                                }
                                onClick={() => {
                                  editModal.onOpen();
                                  setEdit({
                                    ...edit,
                                    id: itm.attendance_id,
                                    trainee_id: item.trainee_id,
                                    time: itm.attendance_punchin,
                                    date: date[index],
                                    punch: "in",
                                  });
                                  if (itm.attendance_status === 1) {
                                    setActive(true);
                                  }
                                }}
                              >
                                {itm.attendance_status === 1
                                  ? "A"
                                  : itm.attendance_punchin !== "--:--"
                                  ? format(
                                      new Date(itm.attendance_punchin),
                                      "HH:mm"
                                    )
                                  : itm.attendance_status === 3
                                  ? "W"
                                  : "--:--"}
                              </Link>

                              <br />
                              {/* punchout */}
                              <Link
                                color={
                                  item.pivot.join_status === "3" &&
                                  itm.attendance_status === 1
                                    ? "red"
                                    : item.pivot.join_status === "3"
                                    ? "#D4AC0D"
                                    : itm.attendance_status === 1 && "red"
                                }
                                onClick={() => {
                                  editModal.onOpen();
                                  setEdit({
                                    ...edit,
                                    id: itm.attendance_id,
                                    trainee_id: item.trainee_id,
                                    time: itm.attendance_punchout,
                                    date: date[index],
                                    punch: "out",
                                  });
                                  if (itm.attendance_status === 1) {
                                    setActive(true);
                                  }
                                }}
                              >
                                {itm.attendance_status === 1
                                  ? "A"
                                  : itm.attendance_punchout !== "--:--"
                                  ? format(
                                      new Date(itm.attendance_punchout),
                                      "HH:mm"
                                    )
                                  : itm.attendance_status === 3
                                  ? "W"
                                  : "--:--"}
                              </Link>
                            </>
                          }
                        </td>
                      </>
                    ))}
                  </tr>
                ))}
            </table>
          </div>
        </Styles>

        <div
          style={{
            marginTop: "8%",
            display: print ? "block" : "none",
          }}
        >
          <table
            style={{
              width: "100%",
            }}
          >
            <tr>
              <td>
                <table
                  style={{
                    width: "400px",
                    textAlign: "left",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        paddingBottom: "10px",
                      }}
                    >
                      TRAINING PROVIDER
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Signature: </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Name:</td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Position:</td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Date: </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Company Stamp: </td>
                  </tr>
                </table>
              </td>
              <td>
                <table
                  style={{
                    width: "400px",
                    textAlign: "left",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        paddingBottom: "10px",
                      }}
                    >
                      COMPANY
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Signature: </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Name: </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Position: </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Date: </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>Company Stamp: </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        {/* // <div style={{ width: "100%", border: "1px solid black" }}>
          //   <div
          //     style={{
          //       width: "30%",
          //       border: "1px solid black",
          //     }}
          //   >
          //     <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
          //       TRAINING PROVIDER
          //     </p>
          //     <p fontWeight={"bold"}>Signature:</p>
          //     <p fontWeight={"bold"}>Name: </p>
          //     <p fontWeight={"bold"}>Position: </p>
          //     <p fontWeight={"bold"}>Date: </p>
          //     <p fontWeight={"bold"}>Company Stamp: </p>
          //   </div>
          //   <div style={{ width: "30%" }}>
          //     <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
          //       COMPANY
          //     </p>
          //     <p fontWeight={"bold"}>Signature:</p>
          //     <p fontWeight={"bold"}>Name: </p>
          //     <p fontWeight={"bold"}>Position: </p>
          //     <p fontWeight={"bold"}>Date: </p>
          //     <p fontWeight={"bold"}>Company Stamp: </p>
          //   </div>
          // </div> */}
      </div>
      {/* ----------------------------edit modal----------------------------------- */}
      <Modal
        isOpen={editModal.isOpen}
        onClose={() => {
          editModal.onClose();
          setActive(false);
          reset();
        }}
      >
        <ModalOverlay />
        <ModalContent
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Edit time</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                autoFocus
                type="time"
                defaultValue={
                  isActive
                    ? "--:--"
                    : edit.time !== "--:--"
                    ? format(new Date(edit.time), "HH:mm")
                    : "--:--"
                }
                {...register("time")}
                isRequired={isActive ? false : true}
              />
              <Input
                type="hidden"
                defaultValue={edit.punch}
                {...register("punch")}
              />
              <Input
                type="hidden"
                defaultValue={edit.id ? edit.id : ""}
                {...register("attd_id")}
              />
              <Box
                borderWidth={1}
                borderRadius={5}
                borderColor={"red"}
                cursor="pointer"
                mx="auto"
                mt="20px"
                w="150px"
                p={2}
                color={isActive ? "white" : "red"}
                bg={isActive && "red"}
                textAlign={"center"}
                onClick={() => {
                  if (edit.status !== 1) {
                    reset();
                  }
                  setActive((current) => !current);
                }}
              >
                <Text>ABSENT</Text>
              </Box>
            </ModalBody>
            <Box my="20px" textAlign={"center"}>
              <Button type="submit" mr="3" bg="#33945f" color="white">
                Save
              </Button>
              <Button
                bg={themeCtx.mode ? "#3b3b3b" : "#ebebeb"}
                onClick={() => {
                  editModal.onClose();
                  setActive(false);
                  reset();
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

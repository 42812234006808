import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router";
import { Login, Logout } from "../pages/admin/auth/index";
import { LandingPage } from "../pages/admin/landing";
import NotFoundPage from "../pages/NotFoundPage";
import Company from "../pages/client/enroll/company";
import TrainingRegistration from "../pages/client/training/register";
import { Checkout } from "../pages/client/checkout/checkout";
import { Successful } from "../pages/client/checkout/successful";
import { TrainingAdmin } from "../pages/admin/training";
import { TraineeAdmin } from "../pages/admin/trainee";
import { ProgrammeAdmin } from "../pages/admin/programme";
import { CourseAdmin } from "../pages/admin/course/index";
import { TemplateScreen } from "../pages/admin/template/templateScreen";
import { Template } from "../pages/admin/template";
import CalendarAdmin from "../pages/admin/calendar";
import { LayoutAdmin } from "../pages/admin/layout";
import { LayoutClient } from "../pages/client/layout";
import CourseReg from "../pages/client/course";
import CourseDetails from "../pages/client/course/details";
import { Dashboard } from "../pages/admin/dashboard/index";
import { Individu } from "../pages/client/enroll/individu";
import SettingsAdmin from "../pages/admin/settings/settings";
import { Discount } from "../pages/admin/discount/discount";
import CompanyAdmin from "../pages/admin/company/index";
import { FeedbackAdmin } from "../pages/admin/feedback/index";
import {
  AttendanceIndividual,
  AttendanceTraining,
} from "../pages/admin/attendance/attendance";
import { ClientPunchIn, ClientPunchOut } from "../pages/client/attendance";
import { AttendanceSuccess } from "../pages/client/attendance/successful";
import { Failed } from "../pages/client/checkout/failed";
import { Copywriting } from "../pages/admin/copywriting";
import { Statistics } from "../pages/admin/statistics";
import { ParticipantAdmin } from "../pages/admin/participant";
import { PaymentHook } from "../pages/client/checkout/paymenthook";

let expiry = localStorage.getItem("expiry");
let token = localStorage.getItem("token");
const AppRoute = () => {
  const nav = useNavigate();

  const calculateRemainingTime = (expireTime) => {
    const now = new Date().getTime();
    const remaining = expireTime - now;
    return remaining;
  };

  const expiryCheck = () => {
    const now = new Date();
    if (token) {
      if (now.getTime() > expiry) {
        localStorage.removeItem("token");
        localStorage.removeItem("expiry");
        nav("/login", {
          replace: true,
          state: { message: "The session has expired. Please login again." },
        });
      }
    } else {
      token = null;
    }
  };

  useEffect(() => {
    const remainingTime = calculateRemainingTime(expiry);
    const interval = setInterval(() => {
      expiryCheck();
    }, remainingTime);

    return () => clearInterval(interval);
  }, []);

  //production route

  //admin route
  if (window.location.hostname.includes("trainingadmin")) {
    return (
      <Routes>
        <Route path="" element={<LandingPage />} />
        <Route path="login" element={<Login />} />
        <Route
          path=""
          // element={token === null ? <Navigate to="login" /> : <LayoutAdmin />}
          element={token === null ? <Navigate to="" /> : <LayoutAdmin />}
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="course" element={<CourseAdmin />} />
          <Route path="training">
            <Route path="" element={<TrainingAdmin />} />
            <Route path="trainee">
              <Route path="" element={<TraineeAdmin />} />
              <Route path="attendance">
                <Route path="individual" element={<AttendanceIndividual />} />
                <Route path="training" element={<AttendanceTraining />} />
              </Route>
            </Route>
          </Route>
          <Route path="program" element={<ProgrammeAdmin />} />
          <Route path="calendar" element={<CalendarAdmin />} />
          <Route path="discount" element={<Discount />} />
          <Route path="company" element={<CompanyAdmin />} />
          <Route path="participant" element={<ParticipantAdmin />} />
          <Route path="copywriting" element={<Copywriting />} />
          <Route path="template" element={<Template />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="feedback" element={<FeedbackAdmin />} />
          <Route path="settings" element={<SettingsAdmin />} />
          <Route path="logout" element={<Logout />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="templateScreen" element={<TemplateScreen />} />
      </Routes>
    );
  }

  //client route
  if (window.location.hostname.includes("register")) {
    return (
      <Routes>
        <Route path="" element={<LayoutClient />}>
          <Route path="" element={<CourseReg />} />
          <Route path=":training" element={<CourseDetails />} />
          <Route path="details" element={<CourseDetails />} />
          <Route path="training/:courseId" element={<TrainingRegistration />} />
          <Route path="enroll">
            <Route path="company">
              <Route path="" element={<Company />} />
              <Route path="checkout" element={<Checkout />} />
            </Route>
            <Route path="individu">
              <Route path="" element={<Individu />} />
              <Route path="checkout" element={<Checkout />} />
            </Route>
          </Route>
          <Route path="payment" element={<PaymentHook />} />
          <Route path="successful" element={<Successful />} />
          <Route path="failed" element={<Failed />} />
          <Route path="punchin">
            <Route path="id/:trainingId" element={<ClientPunchIn />} />
            <Route path="success" element={<AttendanceSuccess />} />
          </Route>
          <Route path="punchout">
            <Route path="id/:trainingId" element={<ClientPunchOut />} />
            <Route path="success" element={<AttendanceSuccess />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }

  //localhost routes
  return (
    <Routes>
      <Route path="" element={<LandingPage />} />
      <Route path="login" element={<Login />} />
      <Route
        path=""
        // element={token === null ? <Navigate to="login" /> : <LayoutAdmin />}
        element={token === null ? <Navigate to="" /> : <LayoutAdmin />}
      >
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="course" element={<CourseAdmin />} />
        <Route path="training">
          <Route path="" element={<TrainingAdmin />} />
          <Route path="trainee">
            <Route path="" element={<TraineeAdmin />} />
            <Route path="attendance">
              <Route path="individual" element={<AttendanceIndividual />} />
              <Route path="training" element={<AttendanceTraining />} />
            </Route>
          </Route>
        </Route>
        <Route path="attendance">
          <Route path="individual" element={<AttendanceIndividual />} />
          <Route path="training" element={<AttendanceTraining />} />
        </Route>
        <Route path="program" element={<ProgrammeAdmin />} />
        <Route path="calendar" element={<CalendarAdmin />} />
        <Route path="discount" element={<Discount />} />
        <Route path="company" element={<CompanyAdmin />} />
        <Route path="participant" element={<ParticipantAdmin />} />
        <Route path="copywriting" element={<Copywriting />} />
        <Route path="template" element={<Template />} />
        <Route path="statistics" element={<Statistics />} />
        <Route path="feedback" element={<FeedbackAdmin />} />
        <Route path="settings" element={<SettingsAdmin />} />
        <Route path="logout" element={<Logout />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="templateScreen" element={<TemplateScreen />} />

      <Route path="client" element={<LayoutClient />}>
        <Route path="course">
          <Route path="" element={<CourseReg />} />
          <Route path=":training" element={<CourseDetails />} />
        </Route>
        <Route path="details" element={<CourseDetails />} />
        <Route path="training/:courseId" element={<TrainingRegistration />} />
        <Route path="enroll">
          <Route path="company">
            <Route path="" element={<Company />} />
            <Route path="checkout" element={<Checkout />} />
          </Route>
          <Route path="individu">
            <Route path="" element={<Individu />} />
            <Route path="checkout" element={<Checkout />} />
          </Route>
        </Route>
        <Route path="payment" element={<PaymentHook />} />
        <Route path="successful" element={<Successful />} />
        <Route path="failed" element={<Failed />} />
        <Route path="punchin">
          <Route path="id/:trainingId" element={<ClientPunchIn />} />
          <Route path="success" element={<AttendanceSuccess />} />
        </Route>
        <Route path="punchout">
          <Route path="id/:trainingId" element={<ClientPunchOut />} />
          <Route path="success" element={<AttendanceSuccess />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export { AppRoute };

import React, { useRef, useState } from "react";
import {
  Center,
  Grid,
  GridItem,
  Image,
  VStack,
  Box,
  Flex,
  Spacer,
  Icon,
  Button,
} from "@chakra-ui/react";
import { FiUser } from "react-icons/fi";
import { Header, Footer } from "../../../components";
import logoMantis from "../../../assets/htms-logo.png";
import logo from "../../../assets/logo.png";
import { Carousel } from "../../../components/carousel";
import { useNavigate } from "react-router-dom";
import mockDashboard from "../../../assets/screenshots/dashboard.png";
import mockCompany from "../../../assets/screenshots/company.png";
import mockTrainee from "../../../assets/screenshots/trainee.png";
import mockAttendances from "../../../assets/screenshots/attendances.png";
import mockQR from "../../../assets/screenshots/qr_attendances.png";
import mockAutomate from "../../../assets/screenshots/automate.png";
import mockStats from "../../../assets/screenshots/stats.png";

function LandingPage() {
  const nav = useNavigate();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const images = [
    {
      img: mockDashboard,
      description: "Dashboard",
    },
    {
      img: mockCompany,
      description: "Manage registered companies",
    },
    {
      img: mockTrainee,
      description: "Manage trainees based on trainings",
    },
    {
      img: mockAttendances,
      description: "System generated attendances summary",
    },
    {
      img: mockQR,
      description: "System generated attendance QR code",
    },
    {
      img: mockAutomate,
      description: "Automate HRDCorp documents",
    },
    {
      img: mockStats,
      description: "Linked to Power Bi",
    },
  ];

  return (
    <Grid templateAreas={`"header" "main" "footer"`} w="100%" h="auto">
      <GridItem colSpan={9} area={"header"} zIndex="1">
        <Box
          bg="white"
          minWidth="100%"
          style={{ boxShadow: "0px 4px 4px rgba(0,0,0,0.25)" }}
          pos="fixed"
        >
          <Flex p="4">
            <Box>
              <Image
                ml="5%"
                src={logo}
                w={windowSize.current[0] > 500 ? "20%" : "60%"}
                _hover={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href = "https://www.holisticslab.my";
                }}
              />
            </Box>
            <Spacer />
            <Button
              colorScheme="green"
              color="white"
              w="200px"
              mr="5%"
              onClick={() => {
                nav("/login", {
                  replace: false,
                });
              }}
            >
              Login
            </Button>
          </Flex>
        </Box>
      </GridItem>
      <GridItem
        area={"main"}
        my={windowSize.current[0] > 500 ? "5%" : "0%"}
        colSpan={9}
        bgColor="#ffffff"
        zIndex="0"
      >
        <Center>
          <VStack>
            <Image
              mt={windowSize.current[0] > 500 ? "2%" : "40%"}
              boxSize={windowSize.current[0] > 500 ? "250px" : "130px"}
              src={logoMantis}
            />
            <Carousel slides={images} />
          </VStack>
        </Center>
      </GridItem>
      <GridItem colSpan={9} area={"footer"} zIndex="1">
        {/* <Footer /> */}
      </GridItem>
    </Grid>
  );
}

export { LandingPage };

import {
  Box,
  Image,
  Text,
  Center,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Stack,
  Button,
  Square,
  Container,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

function Login() {
  const [error, setError] = useState("");
  const loading = useDisclosure();
  const { register, handleSubmit } = useForm();

  const handleCredential = (data) => {
    loading.onOpen();
    if (data.username === "" || data.password === "") {
      setError("Enter username and password");
      loading.onClose();
    } else {
      setError("");
      return new Promise((resolve, reject) => {
        axios.post(`${URL_STAGING}login`, data).then(
          ({ data }) => {
            localStorage.setItem("token", data.token);
            localStorage.setItem("expiry", new Date().getTime() + 86400000);
            resolve(data);
            loading.onClose();
            window.location.pathname = "dashboard";
          },
          (err) => {
            setError("Wrong username or password");
            loading.onClose();
          }
        );
      });
    }
  };

  return (
    <div>
      <Container px={12} pb={12} my={"auto"} mt={"20vh"} pos="relative">
        <Square
          size={{ base: "0px", sm: "200px" }}
          bg="green.200"
          borderRadius="3xl"
          pos="absolute"
          left="1%"
          top="-7%"
          zIndex={-1}
        ></Square>
        <Square
          size={{ base: "0px", sm: "150px" }}
          borderRadius="3xl"
          borderWidth={{ base: 0, sm: 2 }}
          borderColor="green.400"
          pos="absolute"
          left="17%"
          top="-13%"
          zIndex="-1"
        ></Square>
        <Center>
          <Button
            mb="10px"
            color="white"
            bgColor="green.500"
            _hover={{ bgColor: "green.700" }}
            onClick={() => {
              window.location.pathname = "/";
            }}
          >
            <Image
              w="150px"
              src="https://www.mihas.com.my/static/img/logo-footer.png"
            />
            &nbsp;&nbsp;- Find out more!
          </Button>
        </Center>
        <Center
          maxW="lg"
          boxShadow="2xl"
          bg="white"
          mx="auto"
          mt={0}
          p={10}
          borderRadius="lg"
        >
          <Stack>
            <Heading size="lg" align="center">
              Login
            </Heading>
            <Text textAlign="center" color="gray.400">
              Please enter your username and password.
            </Text>

            {error !== "" && (
              <Text textAlign="center" color="red" fontSize="13px">
                {error}
              </Text>
            )}

            <form onSubmit={handleSubmit(handleCredential)}>
              <FormControl pt={5}>
                <FormLabel>Username</FormLabel>
                <Input
                  autoFocus
                  id="username"
                  type="text"
                  placeholder="Username"
                  {...register("username")}
                />
              </FormControl>
              <FormControl pt={5}>
                <FormLabel>Password</FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="Password"
                  {...register("password")}
                />
                <Text
                  float="right"
                  w="fit-content"
                  _hover={{ cursor: "pointer" }}
                  color="green"
                  //   onClick={handleReset}
                >
                  Reset Password?
                </Text>
              </FormControl>
              <Button
                w="100%"
                type="submit"
                className="btn"
                colorScheme="green"
                color="white"
              >
                Login
              </Button>
            </form>
          </Stack>
        </Center>
        <Square
          size={{ base: "0px", sm: "200px" }}
          bg="green.200"
          borderRadius="3xl"
          pos="absolute"
          right="1%"
          bottom="1%"
          zIndex={-1}
        ></Square>
        <Square
          size={{ base: "0px", sm: "250px" }}
          borderRadius="3xl"
          borderWidth={2}
          borderColor="green.200"
          borderStyle="dashed"
          pos="absolute"
          right="-4%"
          bottom="-4%"
          zIndex={-1}
        ></Square>
      </Container>

      <Modal size="xs" isOpen={loading.isOpen}>
        <ModalOverlay />
        <ModalContent my="auto">
          <ModalBody>
            <Center>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

function Logout() {
  localStorage.clear();
  window.location.pathname = "/adminlogin";
}

export { Login, Logout };

import React, { useState, useRef } from "react";
import {
  Box,
  Image,
  Flex,
  Spacer,
  Icon,
  InputGroup,
  Input,
  Container,
} from "@chakra-ui/react";
import logo from "../../assets/logo.png";
import { Search2Icon } from "@chakra-ui/icons";
import { FiUser } from "react-icons/fi";
import { useAsyncDebounce } from "react-table";
import { useNavigate } from "react-router-dom";

function Header() {
  const nav = useNavigate();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  return (
    <Box
      bg="white"
      minWidth="100%"
      style={{ boxShadow: "0px 4px 4px rgba(0,0,0,0.25)" }}
      pos="fixed"
    >
      <Flex>
        <Box p="4">
          <Image
            src={logo}
            w={windowSize.current[0] > 500 ? "20%" : "30%"}
            _hover={{ cursor: "pointer" }}
            onClick={() => {
              nav("/", {
                replace: false,
              });
            }}
          />
        </Box>
        <Spacer />
        <Box p="4">
          <Icon
            boxsize={9}
            as={FiUser}
            style={{ border: "1px solid #14804a", borderRadius: "100px" }}
          />
        </Box>
      </Flex>
    </Box>
  );
}

function searchBar() {
  return (
    <Container
      maxW="90%"
      borderWidth={1}
      style={{
        backgroundColor: "white",
        borderRadius: "25px",
        margin: "20px auto",
      }}
    >
      <InputGroup size="md">
        <div style={{ width: "5%", display: "flex" }}>
          <Search2Icon
            style={{
              margin: "auto",
            }}
          />
        </div>
        <div style={{ width: "95%" }}>
          <Input
            placeholder="Type anything to search"
            _focusVisible={{
              boxShadow: "0 0 0 0px",
            }}
            style={{
              borderRadius: "25px",
              border: "0px",
            }}
          />
        </div>
      </InputGroup>
    </Container>
  );
}

function SearchFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  themeCtx,
}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Container
      maxW="90%"
      borderWidth={themeCtx && !themeCtx.mode && 1}
      style={{
        backgroundColor: themeCtx && themeCtx.mode ? "#1b1b1b" : "#fcfdfe",
        color: themeCtx && themeCtx.mode && "white",
        borderRadius: "10px",
        margin: "20px 0px",
        minWidth: "100%",
      }}
    >
      <InputGroup size="md">
        <div style={{ width: "5%", display: "flex" }}>
          <Search2Icon
            style={{
              margin: "auto",
            }}
          />
        </div>
        <div style={{ width: "95%" }}>
          <Input
            placeholder="Type anything to search"
            _focusVisible={{
              boxShadow: "0 0 0 0px",
            }}
            style={{
              borderRadius: "25px",
              border: "0px",
            }}
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </div>
      </InputGroup>
    </Container>
  );
}

// function SelectColumnFilter(
//   {
//     // column: { filterValue, setFilter, preFilteredRows, id },
//   }
// ) {
//   // const options = React.useMemo(() => {
//   //   const options = new Set();
//   //   preFilteredRows.forEach((row) => {
//   //     options.add(row.values[id]);
//   //   });
//   //   return [...options.values()];
//   // }, [id, preFilteredRows]);

//   // Render a multi-select box
//   return (
//     // <select
//     //   value={filterValue}
//     //   onChange={(e) => {
//     //     setFilter(e.target.value || undefined);
//     //   }}
//     // >
//     //   <option value="">All</option>
//     //   {options.map((option, i) => (
//     //     <option key={i} value={option}>
//     //       {option}
//     //     </option>
//     //   ))}
//     // </select>
//     // <>
//     //   <Select placeholder="Select year" variant="unstyled">
//     //     <option value="option1">Option 1</option>
//     //     <option value="option2">Option 2</option>
//     //     <option value="option3">Option 3</option>
//     //   </Select>
//     //   <Select placeholder="Select month" variant="unstyled">
//     //     <option value="option1">Option 1</option>
//     //     <option value="option2">Option 2</option>
//     //     <option value="option3">Option 3</option>
//     //   </Select>
//     // </>
//   );
// }

export { Header, searchBar as SearchBar, SearchFilter };

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Input,
  Stack,
  Textarea,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import hl_logo from "../../../assets/logo.png";
import hl_logo_dark from "../../../assets/hlsb_putih.png";
import { ReactTable, AlertPop, ChakraAlertDialog } from "../../../components";
import styled from "styled-components";
import CRUDFunction from "../../../functions/CRUDFunction";
import { useForm, Controller } from "react-hook-form";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { WYSIWYGEditor } from "../../../components/rich-text-editor";
import ThemeContext from "../../../functions/ThemeContext";

const Styles = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :nth-child(1) {
        width: 80px;
      }
      :nth-child(2) {
        width: 350px;
      }
      :nth-child(4) {
        width: 300px;
      }
      :last-child {
        border-right: 0;
      }
    }

    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #bdbdbd;
      text-align: left;
      :nth-child(1),
      :nth-child(4),
      :last-child {
        text-align: center;
      }
      :last-child {
        width: 120px;
        border-right: 0;
      }
    }
  }
`;

export default function Settings() {
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const [load, setLoad] = useState(false);
  const themeCtx = useContext(ThemeContext);

  useEffect(() => {
    fetchData();
  }, []);

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
    }, 5000);
  };

  const fetchData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}biller`);
    if (res) {
      setData(res);
      setLoad(true);
    }
  };

  const { register, reset, handleSubmit, control } = useForm();

  const onSubmit = async (data) => {
    if (data) {
      const formData = new FormData();

      formData.append(
        "biller_name",
        data.billerName === "" ? data.biller_name : data.billerName
      );
      formData.append(
        "biller_address",
        data.billerAddress === "" ? data.biller_address : data.billerAddress
      );
      formData.append(
        "biller_phone",
        data.billerPhone === "" ? data.biller_phone : data.billerPhone
      );
      formData.append(
        "biller_email",
        data.billerEmail === "" ? data.biller_email : data.billerEmail
      );
      formData.append(
        "biller_note",
        data.billerNote === "" ? data.biller_note : data.billerNote
      );

      const res = await CRUDFunction.update(
        `${URL_STAGING}biller/update/1`,
        formData
      );

      if (res) {
        setData(res);
        reset();
        setAlert({
          ...alert,
          class: "show",
          status: "success",
          message: "The data has been updated successfully.",
        });
        timer();
      }
    }
  };

  return (
    <>
      {load && (
        <Container maxW="90%" m="20px auto">
          {alert.message ? (
            <AlertPop
              width="30%"
              className={alert.class}
              status={alert.status}
              title={alert.message}
            />
          ) : (
            ""
          )}
          <Heading mb="15px" color={themeCtx.mode && "white"}>
            SETTINGS
          </Heading>
          <Box
            h="100%"
            borderRadius={10}
            bg={themeCtx.mode ? "#2b2b2b" : "white"}
            color={themeCtx.mode && "white"}
            display="grid"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Image
                src={themeCtx.mode ? hl_logo_dark : hl_logo}
                w="20%"
                px="20px"
                py="10px"
              ></Image>
              <Flex px="30px">
                <Box w="100%">
                  <Stack w="100%" spacing={3}>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold">Name</FormLabel>
                      <Input
                        w={{ md: "70%", xl: "365px" }}
                        defaultValue={data.biller_name}
                        {...register("billerName")}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold">Address</FormLabel>
                      <Textarea
                        w={{ md: "30%", xl: "365px" }}
                        resize="none"
                        defaultValue={data.biller_address}
                        {...register("billerAddress")}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold">Phone</FormLabel>
                      <Input
                        w={{ md: "30%", xl: "365px" }}
                        id="phone"
                        defaultValue={data.biller_phone}
                        {...register("billerPhone")}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold">Email</FormLabel>
                      <Input
                        w={{ md: "30%", xl: "365px" }}
                        id="email"
                        defaultValue={data.biller_email}
                        {...register("billerEmail")}
                      />
                    </FormControl>
                  </Stack>
                </Box>
              </Flex>
              <Box h="8px" w="100%" my="20px" mx={0} bg="#33945f"></Box>
              <FormControl id="billerNote">
                <Box mx="30px" mt="1%" mb="15px">
                  <FormLabel mt="10px" fontWeight="bold">
                    Note <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Controller
                    name="billerNote"
                    control={control}
                    defaultValue={data.biller_note}
                    render={({ field }) => <WYSIWYGEditor {...field} />}
                    rules={{
                      required: { value: true, message: "This is required." },
                    }}
                  />
                  {/* <Textarea
                    resize="none"
                    h="200px"
                    id="biller_notes"
                    defaultValue={data.biller_note}
                    {...register("billerNote")}
                  /> */}
                </Box>
              </FormControl>
              <div style={{ display: "block" }}>
                <Button
                  type="submit"
                  w="10%"
                  float="right"
                  mr="30px"
                  mb="30px"
                  _hover={{ bg: "green.700" }}
                  bg="#33945f"
                  color="white"
                >
                  Save
                </Button>
              </div>
            </form>
          </Box>
        </Container>
      )}
    </>
  );
}

import axios from "axios";

let token = localStorage.getItem("token");
class CRUDFunctions {
  static getHeader = () => {
    return [
      {
        headers: {
          // authorization: `Bearer ${token}`,
          ContentType: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      },
    ];
  };

  static header = {
    headers: {
      authorization: `Bearer ${token && token}`,
      ContentType: "application/json",
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };

  static create = async (URL, data) => {
    return new Promise((resolve) => {
      axios
        .post(URL, data, this.header)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static get = async (URL) => {
    return new Promise((resolve) => {
      axios
        .get(URL, this.header)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          if (err.response.status === 429) {
            console.log("Refresh in 5 minutes");
            console.log(err);
          } else {
            console.log(err);
          }
          resolve(err.response.data);
        });
    });
  };

  static update = async (URL, data) => {
    return new Promise((resolve) => {
      axios
        .post(URL, data, this.header)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static delete = async (URL) => {
    return new Promise((resolve) => {
      axios
        .delete(URL, this.header)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static createBill = async (URL, data) => {
    return new Promise((resolve) => {
      axios
        .post(URL, data, this.header)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static password = async (URL, data) => {
    return new Promise((resolve) => {
      axios
        .post(URL, data, this.header)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static download = (URL, data) => {
    axios
      .post(URL, data, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Download.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };
}

export default CRUDFunctions;

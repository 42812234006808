import React, { useRef } from "react";
import {
  Container,
  Box,
  Text,
  VStack,
  Link,
  Icon,
  Center,
} from "@chakra-ui/react";
import { RiWhatsappFill, RiPhoneFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { CheckIcon } from "@chakra-ui/icons";
import { FiXCircle } from "react-icons/fi";

function Failed() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  return (
    <>
      <Container my="20">
        <Box
          p="40px 30px 20px 30px"
          style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <VStack textAlign="center">
            <FiXCircle color="red" fontSize="80px" py="20px" />
            <Text fontSize="30px">Failed</Text>
            <Text fontSize="20px" color="gray" pb="20px">
              Your registration is failed.
              <br />
              Please try again or contact
              <span>
                <b> HOLISTICS LAB SDN BHD </b>
              </span>
              for assistance.
            </Text>
            <Box
              w="150px"
              p="5px"
              color="white"
              bgColor="#468c32"
              borderRadius="10px"
            >
              <a href="/">Go to Home</a>
            </Box>
          </VStack>
        </Box>
        {windowSize.current[0] < 500 && (
          <Box my="30px">
            <VStack>
              <Text fontStyle="italic" fontSize="15px" textAlign="left">
                <Icon as={MdEmail} /> Email: admin@holisticslab.my
                <br />
                <Icon as={RiPhoneFill} /> 07-5950920 (Office)
                <br />
                <Icon as={RiWhatsappFill} /> 019-7765075 (WhatsApp)
              </Text>
            </VStack>
          </Box>
        )}
      </Container>
      {windowSize.current[0] > 500 && (
        <Box my="30px">
          <Center>
            <Text fontStyle="italic" fontSize="15px" textAlign="left">
              <Icon as={MdEmail} /> Email:
              admin@holisticslab.my&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Icon as={RiPhoneFill} /> 07-5950920
              (Office)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Icon as={RiWhatsappFill} /> 019-7765075 (WhatsApp)
            </Text>
          </Center>
        </Box>
      )}
    </>
  );
}

export { Failed };

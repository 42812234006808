import React, { useContext, useEffect, useState } from "react";
import {
  Heading,
  Container,
  Text,
  Flex,
  Box,
  Spacer,
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem,
  Divider,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Input,
  FormControl,
  FormLabel,
  Center,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { ReactTable, AlertPop, ChakraAlertDialog } from "../../../components";
import styled from "styled-components";
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import CRUDFunction from "../../../functions/CRUDFunction";
import { useForm } from "react-hook-form";
import moment from "moment";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import ThemeContext from "../../../functions/ThemeContext";

const StylesAttendance = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #bdbdbd;
      // border-right: 1px solid black;
    }

    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #bdbdbd;
    }
  }
`;

function AttendanceIndividual() {
  const { state } = useLocation();
  const nav = useNavigate();
  const editAttendance = useDisclosure();
  const dialogProps = useDisclosure();
  const [data, setData] = useState([]);
  const [trainee, setTrainee] = useState();
  const [edit, setEdit] = useState();
  const [attendId, setAttendId] = useState();
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const themeCtx = useContext(ThemeContext);
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        id: "date",
        accessor: (data) => [data.attendance_punchin, data.attendance_punchout],

        Cell: ({ value }) => {
          return value[0]
            ? format(new Date(value[0]), "dd-MM-yyyy")
            : format(new Date(value[1]), "dd-MM-yyyy");
        },
      },
      {
        Header: "Punch In",
        id: "punchin",
        accessor: "attendance_punchin",
        Cell: ({ value }) => {
          return value && format(new Date(value), "HH:mm") !== "00:00"
            ? format(new Date(value), "h:mm aaa")
            : "--:--";
        },
      },
      {
        Header: "Punch Out",
        accessor: "attendance_punchout",
        Cell: ({ value }) => {
          return value && format(new Date(value), "HH:mm") !== "00:00"
            ? format(new Date(value), "h:mm aaa")
            : "--:--";
        },
      },
      {
        Header: "Status",
        accessor: "attendance_status",
        disableSortBy: true,
        Cell: ({ value }) => {
          let status;
          value === 2 ? (status = "Present") : (status = "Absent");
          return (
            <span
              style={{
                color: status === "Present" ? "green" : "red",
              }}
            >
              {status}
            </span>
          );
        },
      },
      {
        Header: "Remarks",
        disableSortBy: true,
        accessor: "attendance_remarks",
        Cell: ({ value }) => <Text noOfLines={1}>{value}</Text>,
      },
      {
        Header: "Actions",
        disableSortBy: true,
        accessor: "attendance_id",
        Cell: ({ cell }) => (
          <>
            <Menu>
              <MenuButton
                as={Button}
                bgColor="none"
                _hover={{ bgColor: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                _expanded={{ bgColor: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                _click={{ bgColor: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                variant="ghost"
                rightIcon={<BiDotsVerticalRounded boxsize={5} />}
              ></MenuButton>
              <MenuList minW="100px" bgColor={themeCtx.mode && "#2b2b2b"}>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bg: themeCtx.mode ? "#1b1b1b" : "#ebebeb" }}
                  icon={<EditIcon boxsize={5} color="#14804a" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}attendance/edit/${cell.value}`
                    );
                    if (res) {
                      setEdit(res);
                      setAttendId(cell.value);
                      editAttendance.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bg: themeCtx.mode ? "#1b1b1b" : "#ebebeb" }}
                  icon={<DeleteIcon boxsize={5} color="#d12953" />}
                  onClick={() => {
                    setAttendId(cell.value);
                    dialogProps.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    fetchTraineeDetails();
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}attendance/${state.trainee_id}`
    );
    if (res) {
      setData(res);
    }
  };

  const fetchTraineeDetails = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}trainee/${state.trainee_id}/${state.training_id}`
    );
    if (res) {
      setTrainee(res);
    }
  };

  const { register, reset, handleSubmit } = useForm();

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
    }, 5000);
  };

  const onSubmit = async (data) => {
    if (data) {
      const formData = new FormData();
      if (data.attendanceStatus === "1") {
        data.attendancePunchin = "00:00";
        data.attendancePunchout = "00:00";
      }
      if (data.attendancePunchin) {
        formData.append(
          "attendance_punchin",
          moment(data.date + " " + data.attendancePunchin).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        );
      }

      if (data.attendancePunchout) {
        formData.append(
          "attendance_punchout",
          moment(data.date + " " + data.attendancePunchout).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        );
      }

      formData.append("attendance_status", data.attendanceStatus);
      formData.append("attendance_remarks", data.attendanceRemarks);

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      const res = await CRUDFunction.update(
        `${URL_STAGING}attendance/update/${attendId}`,
        formData
      );
      if (res) {
        fetchData();
        setAlert({
          ...alert,
          class: "show",
          status: "success",
          message: "Data is successfully updated.",
        });
        timer();
        editAttendance.onClose();
      }
    }
  };
  return (
    <div>
      {alert.message ? (
        <AlertPop
          width="35%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Breadcrumb
        adcrumb
        as={Heading}
        separator={<ChevronRightIcon />}
        m="25px 0px 25px 100px"
        color={themeCtx.mode && "white"}
        textTransform="uppercase"
      >
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => nav("/training", { replace: false })}>
            <Text>TRAINING</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => nav(-1, { replace: false })}
            w="100%"
            maxW="500px"
          >
            <Text noOfLines={1}>{state ? state.training_name : ""}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink w="100%" maxW="500px">
            <Text noOfLines={1}>{trainee ? trainee.trainee_name : ""}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Container
        maxW={"90%"}
        pt="10px"
        bg={themeCtx.mode ? "#2b2b2b" : "white"}
        color={themeCtx.mode && "white"}
        borderRadius="10px"
      >
        <Box>
          <Heading fontSize={"2xl"} p="5" textTransform={"uppercase"}>
            {state.training_name}
          </Heading>
          <Divider />
          <Box m="5" style={{ border: "5px solid #f4f8f1" }}>
            <Flex>
              <Box p="5">
                <Text>
                  <strong>{trainee ? trainee.trainee_name : ""}</strong>
                </Text>
                <Text>{trainee ? trainee.trainee_position : ""}</Text>
              </Box>
              <Spacer />
              <Box p="5">
                <Text>
                  <strong>Trainee Identification Number</strong>
                </Text>
                <Text>{trainee ? trainee.trainee_ic : ""}</Text>
              </Box>
              <Spacer />
              <Box p="5">
                <Text>
                  <strong>Joining Date</strong>
                </Text>
                <Text>
                  {trainee
                    ? format(new Date(trainee.created_at), "dd MMMM yyyy")
                    : ""}
                </Text>
              </Box>
              <Spacer />
              <Box p="5">
                <Text>
                  <strong>Company</strong>
                </Text>
                <Text>{trainee ? trainee.company.company_name : ""}</Text>
              </Box>
              <Spacer />
            </Flex>
          </Box>
          <Divider />
          <StylesAttendance>
            <ReactTable
              columns={columns}
              data={data && data}
              modulePage="attendance"
              themeCtx={themeCtx}
            />
          </StylesAttendance>
        </Box>
      </Container>

      {/* ---------------------------------------edit drawer ---------------------------------------------- */}
      <Drawer
        isOpen={editAttendance.isOpen}
        placement="right"
        onClose={() => {
          reset();
          editAttendance.onClose();
        }}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          color={themeCtx.mode && "white"}
        >
          <DrawerCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              EDIT ATTENDANCE
            </DrawerHeader>

            <DrawerBody>
              <FormControl isRequired pt="20px">
                <FormLabel fontWeight="bold">DATE</FormLabel>
                <Input
                  placeholder="Select Date and Time"
                  type="date"
                  defaultValue={
                    edit
                      ? format(new Date(edit.attendance_punchin), "yyyy-MM-dd")
                      : ""
                  }
                  {...register("date")}
                />
              </FormControl>
              <FormControl pt="20px">
                <FormLabel fontWeight="bold">PUNCH IN</FormLabel>
                <Input
                  placeholder="Punch in"
                  type="time"
                  defaultValue={
                    edit && edit.attendance_punchin !== null
                      ? format(new Date(edit.attendance_punchin), "HH:mm")
                      : ""
                  }
                  {...register("attendancePunchin")}
                />
              </FormControl>
              <FormControl pt="20px">
                <FormLabel fontWeight="bold">PUNCH OUT</FormLabel>
                <Input
                  placeholder="Punch out"
                  type="time"
                  defaultValue={
                    edit && edit.attendance_punchout !== null
                      ? format(new Date(edit.attendance_punchout), "HH:mm")
                      : ""
                  }
                  {...register("attendancePunchout")}
                />
              </FormControl>
              <FormControl isRequired pt="20px">
                <FormLabel fontWeight="bold">STATUS</FormLabel>
                <Select
                  bg={themeCtx.mode && "#2b2b2b"}
                  defaultValue={
                    edit ? (edit.attendance_status === 2 ? 2 : 1) : ""
                  }
                  {...register("attendanceStatus")}
                >
                  <option
                    value=""
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                    disabled
                  >
                    Select status
                  </option>
                  <option
                    value={2}
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Present
                  </option>
                  <option
                    value={1}
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Absent
                  </option>
                </Select>
              </FormControl>
              <FormControl pt="20px">
                <FormLabel fontWeight="bold">REMARKS</FormLabel>
                <Textarea
                  placeholder="Remarks"
                  defaultValue={edit ? edit.attendance_remarks : ""}
                  {...register("attendanceRemarks")}
                />
              </FormControl>
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  type="submit"
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                >
                  Edit
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    editAttendance.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* ---------------------------------------edit drawer end---------------------------------------------- */}

      {/* ---------------------------------------delete dialog------------------------------------------------- */}
      <ChakraAlertDialog
        title="Delete Attendance"
        dialogProps={dialogProps}
        url={[
          `${URL_STAGING}attendance/delete/${attendId}/${state.trainee_id}`,
        ]}
        setAlert={setAlert}
        alert={alert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        themeCtx={themeCtx}
        button={"Delete"}
      />
      {/* ---------------------------------------delete dialog end------------------------------------------------- */}
    </div>
  );
}

function AttendanceTraining() {
  return <div>Attendance Training</div>;
}

export { AttendanceIndividual, AttendanceTraining };

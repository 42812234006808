import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Text,
} from "@chakra-ui/react";
import CRUDFunction from "../../functions/CRUDFunction";

function ChakraAlertDialog({
  dialogProps,
  url,
  setAlert,
  alert,
  setData,
  body,
  button,
  title,
  themeCtx,
}) {
  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
    }, 5000);
  };

  return (
    <AlertDialog
      isOpen={dialogProps.isOpen}
      // leastDestructiveRef={cancelRef}
      onClose={dialogProps.onClose}
      size="xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          my="auto"
          py="40px"
          alignItems="center"
          bgColor={themeCtx && themeCtx.mode && "#2b2b2b"}
          color={themeCtx && themeCtx.mode && "white"}
        >
          <AlertDialogHeader fontSize="25px" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody
            mx="30px"
            textAlign="center"
            fontWeight="bold"
            color="gray"
          >
            {body}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme={title === "Register Trainee" ? "green" : "red"}
              px="80px"
              onClick={async () => {
                let res;
                if (button === "Delete" || button === "Kick") {
                  res = await CRUDFunction.delete(url);
                } else {
                  res = await CRUDFunction.update(url[0], JSON.parse(url[1]));
                }
                if (res) {
                  setAlert({
                    ...alert,
                    class: "show",
                    status: "success",
                    message: "The data has been updated successfully.",
                  });
                  title === "Delete Trainee" ||
                  title === "Register Trainee" ||
                  title === "Withdraw Trainee" ||
                  title === "Kick Trainee" ||
                  title === "Move to Register Tab"
                    ? setData(res.trainees)
                    : title === "Delete Course" ||
                      title === "Delete Discount" ||
                      title === "Delete Copywriting" ||
                      title === "Delete Power BI Link" ||
                      title === "Delete Attendance" ||
                      title === "Delete Company"
                    ? setData(res)
                    : setData({
                        rowData: res.data,
                        tableLoader: false,
                        totalPages: res.last_page,
                        totalData: res.total,
                      });
                  timer();
                  dialogProps.onClose();
                }
              }}
              mr={3}
            >
              {button}
            </Button>
            <Button
              px="80px"
              onClick={dialogProps.onClose}
              bgColor={themeCtx && themeCtx.mode && "#4b4b4b"}
              color={themeCtx && themeCtx.mode && "white"}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

function ErrorsDialog({ dialogProps, body, title }) {
  return (
    <AlertDialog
      isOpen={dialogProps.isOpen}
      onClose={dialogProps.onClose}
      size="sm"
    >
      <AlertDialogContent
        color="white"
        bgColor="red.600"
        alignItems="center"
        p="10px"
        pos="absolute"
        bottom="0"
      >
        <Text fontSize="15px">{title}</Text>
        <Text fontSize="15px">{body}</Text>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function FailAlert({ error }) {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>Unsuccessful</AlertTitle>
      <AlertDescription>Error, please try again later.</AlertDescription>
    </Alert>
  );
}

export { FailAlert, ChakraAlertDialog, ErrorsDialog };

import {
  Box,
  Button,
  Center,
  Container,
  FormLabel,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useContext, useEffect, useState } from "react";
import { AlertPop, ChakraAlertDialog, ReactTable } from "../../../components";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import ThemeContext from "../../../functions/ThemeContext";

const Styles = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :nth-child(1) {
        width: 30px;
      }
      :nth-child(2) {
        width: 150px;
      }
      :nth-child(3) {
        width: 200px;
      }
      :last-child {
        width: 50px;
        border-right: 0;
      }
    }

    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #bdbdbd;
      text-align: center;
      :nth-child(1),
      :nth-child(3),
      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Statistics() {
  const [url, setUrl] = useState();
  const [status, setStatus] = useState();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState([]);
  const [id, setID] = useState();
  const [state, setState] = useState();
  const modal = useDisclosure();
  const deleteDialog = useDisclosure();
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const themeCtx = useContext(ThemeContext);

  const { register, unregister, reset, handleSubmit } = useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}statistics`);
    if (res) {
      setData(res);
    }
  };

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
    }, 5000);
  };

  const column = React.useMemo(
    () => [
      {
        Header: "#",
        id: "index",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "NAME",
        accessor: "statistics_name",
      },
      {
        Header: "LINK",
        accessor: "statistics_link",
        Cell: ({ value }) => (
          <Center>
            <Box maxW="500px">
              <Text>{value}</Text>
            </Box>
          </Center>
        ),
      },
      {
        Header: "ACTIONS",
        accessor: "statistics_id",
        disableSortBy: true,
        Cell: ({ cell }) => (
          <>
            <Menu>
              <MenuButton
                as={Button}
                bgColor="none"
                _hover={{ bg: themeCtx.mode && "#2b2b2b" }}
                _active={{ bg: themeCtx.mode && "#2b2b2b" }}
                align="center"
                variant="ghost"
                rightIcon={<BiDotsVerticalRounded boxsize={5} />}
              ></MenuButton>
              <MenuList minW="100px" bg={themeCtx.mode && "#2b2b2b"}>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bg: themeCtx.mode && "#1b1b1b" }}
                  id="edit"
                  icon={<EditIcon boxsize={5} color="#14804a" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}statistics/${cell.value}`
                    );

                    if (res) {
                      setEdit(res);
                      setState("edit");
                      modal.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bg: themeCtx.mode && "#1b1b1b" }}
                  icon={<DeleteIcon boxsize={5} color="#d12953" />}
                  onClick={() => {
                    setID(cell.value);
                    deleteDialog.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      },
    ],
    []
  );

  const onSubmit = async (data) => {
    if (data) {
      const formData = new FormData();
      formData.append("statistics_name", data.powerBIName);
      formData.append("statistics_link", data.powerBILink);
      let res;
      if (state === "add") {
        res = await CRUDFunction.create(`${URL_STAGING}statistics`, formData);
      } else {
        res = await CRUDFunction.update(
          `${URL_STAGING}statistics/${edit.statistics_id}`,
          formData
        );
      }

      if (res) {
        setData(res);
        reset();
        setAlert({
          ...alert,
          class: "show",
          status: "success",
          message: "The data has been updated successfully.",
        });
        timer();
        modal.onClose();
      }
    }
  };

  const linkSelect = (e) => {
    return new Promise((resolve) => {
      setUrl(e.target.value);
      resolve(true);
    });
  };
  return (
    <>
      <Heading m="25px 0px 25px 100px" color={themeCtx.mode && "white"}>
        STATISTICS
      </Heading>
      <Container minW="90%" m="20px auto">
        {alert.message ? (
          <AlertPop
            width="30%"
            className={alert.class}
            status={alert.status}
            title={alert.message}
          />
        ) : (
          ""
        )}
        <Box
          h="100%"
          borderRadius={10}
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          color={themeCtx.mode && "white"}
          display="grid"
        >
          <Tabs isFitted borderRadius={10} size="lg" variant="enclosed">
            <TabList>
              <Tab
                _selected={{
                  bg: themeCtx.mode ? "#2b2b2b" : "white",
                  color: themeCtx.mode ? "white" : "black",
                }}
                borderTopRightRadius="0"
                color="gray.500"
                bg={themeCtx.mode ? "#3b3b3b" : "#f4f8f1"}
                w="20%"
                px={20}
              >
                Power BI
              </Tab>
              <Tab
                _selected={{
                  bg: themeCtx.mode ? "#2b2b2b" : "white",
                  color: themeCtx.mode ? "white" : "black",
                }}
                borderTopLeftRadius="0"
                color="gray.500"
                bg={themeCtx.mode ? "#3b3b3b" : "#f4f8f1"}
                w="20%"
                px={20}
                onClick={() => {
                  fetchData();
                }}
              >
                Power BI Link
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p="10">
                <Stack direction="row">
                  <Select
                    bg={themeCtx.mode ? "#1b1b1b" : "white"}
                    mb="5"
                    w="30%"
                    onChange={async (e) => {
                      setStatus(false);

                      const state = await linkSelect(e);
                      if (state) {
                        setStatus(true);
                      }
                    }}
                  >
                    <option
                      value=""
                      style={{
                        backgroundColor: themeCtx.mode && "#2b2b2b",
                      }}
                      disabled
                      selected
                    >
                      Select data
                    </option>
                    {data &&
                      data.map((item) => (
                        <option
                          value={item.statistics_link}
                          style={{
                            backgroundColor: themeCtx.mode && "#2b2b2b",
                          }}
                        >
                          {item.statistics_name}
                        </option>
                      ))}
                  </Select>
                </Stack>

                <Box>
                  {status ? (
                    <PowerBIEmbed
                      embedConfig={{
                        type: "report", // Supported types: report, dashboard, tile, visual, and qna.
                        id: "72ae5731-d90a-4bb2-ab4c-09d41534f5ee",
                        embedUrl: status && url,
                        accessToken: "<Access Token>",
                        // tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                        settings: {
                          panes: {
                            filters: {
                              expanded: false,
                              visible: false,
                            },
                          },
                        },
                      }}
                      eventHandlers={
                        new Map([
                          [
                            "loaded",
                            function () {
                              console.log("Report loaded");
                            },
                          ],
                          [
                            "rendered",
                            function () {
                              console.log("Report rendered");
                            },
                          ],
                          [
                            "error",
                            function (event) {
                              console.log(event.detail);
                            },
                          ],
                        ])
                      }
                      cssClassName={"powerBI-embed"}
                      getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;
                      }}
                    />
                  ) : status === false ? (
                    <Spinner />
                  ) : (
                    "No data."
                  )}
                </Box>
              </TabPanel>
              <TabPanel>
                <div style={{ position: "relative" }}>
                  <Button
                    fontSize="14px"
                    id="add"
                    pos="absolute"
                    w="200px"
                    bg="#33945f"
                    _hover={{ bg: "green.700" }}
                    color="white"
                    right="3"
                    top="9"
                    onClick={() => {
                      modal.onOpen();
                      setState("add");
                    }}
                  >
                    Add Link
                  </Button>
                </div>
                <Styles>
                  <ReactTable
                    columns={column}
                    data={data}
                    modulePage="statistics"
                    themeCtx={themeCtx}
                  />
                </Styles>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Container>
      {/* --------------------------------------------MODAL------------------------------------------------ */}
      <Modal
        isOpen={modal.isOpen}
        onClose={() => {
          modal.onClose();
          reset();
        }}
      >
        <ModalOverlay />
        <ModalContent
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              {state === "edit" ? "Edit Data" : "Add Data"}
            </ModalHeader>
            <ModalBody>
              <Text mt="5px" fontWeight="bold">
                NAME:
              </Text>
              <Input
                type="text"
                defaultValue={state === "edit" ? edit.statistics_name : ""}
                {...register("powerBIName")}
              />
              <Text mt="5px" fontWeight="bold">
                LINK:
              </Text>
              <Input
                type="text"
                defaultValue={state === "edit" ? edit.statistics_link : ""}
                {...register("powerBILink")}
              />
            </ModalBody>
            <Box align="center" py="5">
              <Button
                mr="3"
                bg="#33945f"
                _hover={{ bg: "green.700" }}
                color="white"
                type="submit"
              >
                {state === "edit" ? "Save" : "Add"}
              </Button>
              <Button
                bg={themeCtx.mode && "#3b3b3b"}
                onClick={() => {
                  modal.onClose();
                  reset();
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </ModalContent>
      </Modal>
      {/* -----------------------------------------------delete dialog--------------------------------------- */}
      <ChakraAlertDialog
        dialogProps={deleteDialog}
        title={"Delete Power BI Link"}
        url={[`${URL_STAGING}statistics/${id}`]}
        setAlert={setAlert}
        alert={alert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        themeCtx={themeCtx}
        button={"Delete"}
      />
    </>
  );
}

export { Statistics };

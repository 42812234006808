import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Box,
  Text,
  VStack,
  Link,
  Icon,
  Center,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
} from "@chakra-ui/react";
import { RiWhatsappFill, RiPhoneFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { Helmet } from "react-helmet";

function PaymentHook() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const nav = useNavigate();
  const [paid, setPaid] = useState(false);
  const toast = useToast();
  const loading = useDisclosure();

  useEffect(() => {
    checkStatus();
  }, []);

  const checkStatus = () => {
    const url = new URLSearchParams(decodeURIComponent(window.location.search));
    const paymentStatus = url.get("redirect_status");
    if (paymentStatus === "succeeded") {
      setPaid(true);
      console.log("payment berjaya");
    } else if (paymentStatus === "failed") {
      setPaid(false);
      console.log("payment gagal");
    }
  };

  const traineeData = localStorage.getItem("tempData");
  const formDataEntries = JSON.parse(traineeData);
  const formData = new FormData();
  for (let [key, value] of formDataEntries) {
    formData.append(key, value);
  }
  let training_id = formData.get("training_id");

  const handlePayment = async () => {
    if (paid) {
      loading.onOpen();
      //create trainee into database
      if (localStorage.getItem("login") === "individu") {
        const res = await CRUDFunction.create(
          `${URL_STAGING}traineeClient`,
          formData
        );
        console.log(res);
        if (res) {
          loading.onClose();
          if (res.message) {
            toast({
              title: "Order fail to place",
              description: res.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            nav("/successful", {
              replace: false,
            });
          }
        }
      } else {
        const res = await CRUDFunction.create(
          `${URL_STAGING}traineeCompany/${training_id}`,
          formData
        );
        console.log(res);
        if (res) {
          loading.onClose();
          if (res.message) {
            toast({
              title: "Order fail to place",
              description: res.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            nav("/successful", {
              replace: false,
            });
          }
        }
      }
    } else {
      nav("/", {
        replace: false,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Training Registration</title>
      </Helmet>
      <Container my="20">
        <Box
          p="40px 30px 20px 30px"
          style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)" }}
        >
          {paid ? (
            <VStack textAlign="center">
              <Text fontSize="30px">Payment Successful</Text>
              <Button
                w="150px"
                color="white"
                bgColor="#468c32"
                borderRadius="10px"
                _hover={{ bgColor: "green", textDecoration: "underline" }}
                onClick={handlePayment}
              >
                Proceed
              </Button>
            </VStack>
          ) : (
            <VStack textAlign="center">
              <Text fontSize="30px">Payment Failed</Text>
              <Text fontSize="20px" color="gray" pb="10px">
                Please try again or contact
                <span>
                  <b> HOLISTICS LAB SDN BHD </b>
                </span>
                for any inqueries.
              </Text>
              <Button
                w="150px"
                color="white"
                bgColor="#FC4845"
                borderRadius="10px"
                _hover={{ bgColor: "#FF5733", textDecoration: "underline" }}
                onClick={handlePayment}
              >
                Back to Home
              </Button>
              {/* <Box
                w="150px"
                p="5px"
                color="white"
                bgColor="#468c32"
                borderRadius="10px"
              >
                <Link href="/">Home</Link>
              </Box> */}
            </VStack>
          )}
        </Box>
        {windowSize.current[0] < 500 && (
          <Box my="30px">
            <VStack>
              <Text fontStyle="italic" fontSize="15px" textAlign="left">
                <Icon as={MdEmail} /> Email: admin@holisticslab.my
                <br />
                <Icon as={RiPhoneFill} /> 07-5950920 (Office)
                <br />
                <Icon as={RiWhatsappFill} /> 019-7765075 (WhatsApp)
              </Text>
            </VStack>
          </Box>
        )}
      </Container>
      {windowSize.current[0] > 500 && (
        <Box my="30px">
          <Center>
            <Text fontStyle="italic" fontSize="15px" textAlign="left">
              <Icon as={MdEmail} /> Email:
              admin@holisticslab.my&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Icon as={RiPhoneFill} /> 07-5950920
              (Office)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Icon as={RiWhatsappFill} /> 019-7765075 (WhatsApp)
            </Text>
          </Center>
        </Box>
      )}
      <Modal size="xs" isOpen={loading.isOpen} onClose={loading.onClose}>
        <ModalOverlay />
        <ModalContent my="auto">
          <ModalBody>
            <Center>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export { PaymentHook };

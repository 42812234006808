import React, { useContext, useState, useEffect } from "react";
import {
  Heading,
  Box,
  Container,
  Button,
  IconButton,
  Text,
  Image,
  HStack,
  Flex,
  Spacer,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { FiEdit, FiDelete } from "react-icons/fi";
import { RiEditLine, RiDeleteBinLine } from "react-icons/ri";
import ThemeContext from "../../../functions/ThemeContext";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

function Template() {
  const themeCtx = useContext(ThemeContext);
  const [data, setData] = useState();
  const [activeKey, setActiveKey] = useState();
  const deleteDialog = useDisclosure();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}template`);
    if (res.length > 0) {
      console.log(res);
      setData(res);
    }
  };

  const editCanvas = async (key) => {
    let newWindow = window.open("/templateScreen");
    newWindow.type = "edit";
    newWindow.activeKey = key;
  };

  return (
    <div>
      <Heading m="25px 0px 25px 100px" color={themeCtx.mode && "white"}>
        TEMPLATE
      </Heading>
      <Container maxW="90%" m="20px auto">
        <Box
          h="100%"
          borderRadius={10}
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          display="grid"
          p="20px 30px"
        >
          <div style={{ position: "relative" }}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<AddIcon />}
                pos="absolute"
                right="2"
                top="-1"
                _hover={{
                  bg: "green.800 !important",
                }}
                style={{
                  backgroundColor: "#33945F",
                  color: "#ffffff",
                  padding: "14px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                width="200px"
              >
                Add
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    let newWindow = window.open("/templateScreen");
                    newWindow.type = "upload";
                    newWindow.title = "TAA";
                  }}
                >
                  TAA
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    let newWindow = window.open("/templateScreen");
                    newWindow.type = "upload";
                    newWindow.title = "JD 14";
                  }}
                >
                  JD 14
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    let newWindow = window.open("/templateScreen");
                    newWindow.type = "upload";
                    newWindow.title = "Proforma";
                  }}
                >
                  Proforma
                </MenuItem>
              </MenuList>
            </Menu>

            {/* <Button
              pos="absolute"
              right="2"
              top="-1"
              _hover={{
                bg: "green.800 !important",
              }}
              style={{
                backgroundColor: "#33945F",
                color: "#ffffff",
                padding: "14px",
                fontSize: "14px",
              }}
              width="200px"
              onClick={() => {
                let newWindow = window.open("/templateScreen");
                newWindow.type = "upload";
              }}
            >
              <span>
                <AddIcon />
                &nbsp;
              </span>
              Add Template
            </Button> */}
          </div>
          {data ? (
            <div style={{ marginTop: "8%" }}>
              {data.map((data, index) => (
                <Box borderWidth="1px" p="10px" my="5px">
                  <Flex>
                    <HStack>
                      <Image
                        borderRadius="full"
                        boxSize="45px"
                        src={`${URL_STAGING}template/getimg/${btoa(
                          data.imageDir
                        )}`}
                      />
                      <Text fontWeight="bold">
                        {data.title.replaceAll("_", " ")}
                      </Text>
                    </HStack>
                    <Spacer />
                    <HStack>
                      <IconButton
                        variant="outline"
                        color="blue"
                        icon={<RiEditLine />}
                        onClick={() => {
                          editCanvas(data);
                        }}
                      />
                      <IconButton
                        variant="outline"
                        color="red"
                        icon={<RiDeleteBinLine />}
                        onClick={() => {
                          setActiveKey(data);
                          deleteDialog.onOpen();
                        }}
                      />
                    </HStack>
                  </Flex>
                </Box>
              ))}
            </div>
          ) : (
            <Text fontSize="20px">No templates</Text>
          )}
        </Box>
      </Container>

      <AlertDialog isOpen={deleteDialog.isOpen} onClose={deleteDialog.onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Template
            </AlertDialogHeader>
            <AlertDialogCloseButton />

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="red"
                ml={3}
                onClick={async () => {
                  console.log(activeKey);
                  const res = await CRUDFunction.update(
                    `${URL_STAGING}template/delete`,
                    activeKey
                  );
                  if (res) {
                    setData(res);
                    deleteDialog.onClose();
                  }
                }}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export { Template };

import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Container,
  Text,
  Heading,
  Center,
  Image,
  Button,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { Helmet } from "react-helmet";
import { ArrowRightIcon } from "@chakra-ui/icons";

export default function CourseReg() {
  const [data, setData] = useState([]);
  const nav = useNavigate();
  useEffect(() => {
    fetchData();
    localStorage.setItem("tempData", "");
    localStorage.setItem("login", "");
  }, []);

  const fetchData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}courseactive`);
    if (res) {
      setData(res);
    }
  };

  const checkLowestFees = (arr) => {
    let result = [];
    let startingPrice = [];
    let highestPrice = [];
    let tempRM = 0;
    let tempUSD = 0;
    arr.map((e, index) => {
      if (JSON.parse(e.training_fee)[0]) {
        tempRM = parseInt(JSON.parse(e.training_fee)[0].ringgit);
        tempUSD = parseInt(JSON.parse(e.training_fee)[0].dollar);
      } else {
        tempRM = parseInt(e.training_fee);
      }

      if (index == 0) {
        startingPrice[0] = tempRM;
        startingPrice[1] = tempUSD;
      }

      if (startingPrice[1] === 0 && tempUSD !== 0) {
        startingPrice[1] = tempUSD;
      }

      if (startingPrice[0] > tempRM) {
        startingPrice[0] = tempRM;
      } else {
        highestPrice[0] = tempRM;
      }

      if (startingPrice[1] > tempUSD) {
        startingPrice[1] = tempUSD;
      } else {
        highestPrice[1] = tempUSD;
      }
    });

    result = [startingPrice, highestPrice];
    return result;
  };

  return (
    <>
      <Helmet>
        <title>Training Registration</title>
      </Helmet>
      <Container
        maxW={{ base: "100%", md: "90%" }}
        my={{ base: "20%", md: "6%", lg: 0 }}
      >
        <Center>
          <Heading size="2xl">COURSES</Heading>
        </Center>
        <SimpleGrid
          columns={{ sm: 1, lg: 2, xl: 3 }}
          spacing={10}
          mt="5"
          maxH="100%"
        >
          {data.map((item) => (
            <Box
              maxW="600px"
              maxH="100%"
              bg="white"
              boxShadow="lg"
              display="grid"
              pb="10px"
              borderRadius={25}
            >
              <Image
                w="100%"
                h="250px"
                borderTopRadius={25}
                src={
                  item.course_image
                    ? `https://myhalalgig.com/htms-api/public/uploads/${item.course_image}`
                    : "https://dummyimage.com/600x200/000/fff.jpg"
                }
              ></Image>
              <div
                style={{
                  padding: "15px",
                  textAlign: "center",
                }}
              >
                <Heading
                  fontSize={{ base: 20, sm: 30, xl: 22 }}
                  textAlign="center"
                  noOfLines={{ md: 2 }}
                >
                  {item.course_name}
                </Heading>
                <Text
                  fontSize={{ base: 15, md: 18 }}
                  pt="10"
                  px="2"
                  textAlign="justify"
                  noOfLines={4}
                >
                  {item.course_about.replace(/<[^>]+>/g, "")}
                </Text>
                {checkLowestFees(item.trainings)[0][1] !== 0 ? (
                  <>
                    <Text fontSize={18} pt="10" fontWeight="bold">
                      PRICE RANGE
                    </Text>
                    {checkLowestFees(item.trainings)[0][0] !==
                    checkLowestFees(item.trainings)[1][0] ? (
                      <Text fontSize={18}>
                        <b>Malaysian</b>: RM{" "}
                        {checkLowestFees(item.trainings)[0][0]} - RM{" "}
                        {checkLowestFees(item.trainings)[1][0]}
                      </Text>
                    ) : (
                      <Text fontSize={18}>
                        <b>Malaysian</b>: RM{" "}
                        {checkLowestFees(item.trainings)[0][0]}
                      </Text>
                    )}
                    {checkLowestFees(item.trainings)[0][1] !==
                    checkLowestFees(item.trainings)[1][1] ? (
                      <Text fontSize={18}>
                        <b>Non-Malaysian</b>: USD{" "}
                        {checkLowestFees(item.trainings)[0][1]} - USD{" "}
                        {checkLowestFees(item.trainings)[1][1]}
                      </Text>
                    ) : (
                      <Text fontSize={18}>
                        <b>Non-Malaysian</b>: USD{" "}
                        {checkLowestFees(item.trainings)[0][1]}
                      </Text>
                    )}

                    <Text fontSize="15px" fontStyle="italic">
                      *Open to Malaysian and non-Malaysian
                    </Text>
                  </>
                ) : (
                  <>
                    <Text fontSize={18} pt="10" fontWeight="bold">
                      PRICE RANGE
                    </Text>
                    {checkLowestFees(item.trainings)[0][0] !==
                    checkLowestFees(item.trainings)[1][0] ? (
                      <Text fontSize={18}>
                        <b>Malaysian</b>: RM{" "}
                        {checkLowestFees(item.trainings)[0][0]} - RM{" "}
                        {checkLowestFees(item.trainings)[1][0]}
                      </Text>
                    ) : (
                      <Text fontSize={18}>
                        <b>Malaysian</b>: RM{" "}
                        {checkLowestFees(item.trainings)[0][0]}
                      </Text>
                    )}
                    <Text fontSize="15px" fontStyle="italic">
                      *Open to Malaysian only
                    </Text>
                  </>
                )}
                {/* {checkLowestFees(item.trainings).length !== 0 ? (
                  checkLowestFees(item.trainings)[1] !== 0 ? (
                    <>
                      <Text fontSize={18} pt="10" fontWeight="bold">
                        Starting Price:
                      </Text>
                      <Center>
                        <Stack direction={{ base: "column", md: "row" }}>
                          <Text fontSize={18}>
                            RM {checkLowestFees(item.trainings)[0]} (Malaysian)
                          </Text>
                          <Text display={{ base: "none", md: "block" }}>/</Text>
                          <Text fontSize={{ base: 15, md: 18 }}>
                            USD {checkLowestFees(item.trainings)[1]}{" "}
                            (Non-Malaysian)
                          </Text>
                        </Stack>
                      </Center>
                      <Text fontSize="15px" fontStyle="italic">
                        *Open to Malaysian and non-Malaysian
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text fontSize={18} pt="10" fontWeight="bold">
                        Starting Price: RM {checkLowestFees(item.trainings)[0]}
                      </Text>
                      <Text fontSize="15px" fontStyle="italic">
                        *Currently open to Malaysian only
                      </Text>
                    </>
                  )
                ) : (
                  <Text fontSize={18} pt="10" fontWeight="bold">
                    No Available Trainings
                  </Text>
                )} */}
                <Center pt="10">
                  <Button
                    color="green"
                    variant="link"
                    onClick={() => {
                      nav(`../details`, {
                        replace: false,
                        state: { id: item.course_id },
                      });
                    }}
                  >
                    <ArrowRightIcon />
                    &nbsp;Register Here&nbsp;
                    <ArrowRightIcon />
                  </Button>
                </Center>
              </div>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </>
  );
}

import React from "react";
import { Text, Grid, GridItem, Box } from "@chakra-ui/react";
import { format } from "date-fns";
import styled from "styled-components";

const StylesHeader = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: left;

    td {
      padding: 0.5rem;
      font-weight: bold;
    }
  }
`;

const StylesBody = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;
    border: 1px solid black;

    th {
      padding: 0.5rem;
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: #bdbdbd;
      border: 1px solid black;

      :first-child {
        width: 4%;
      }
      :nth-child(2) {
        width: 25%;
      }
      :nth-child(3) {
        width: 20%;
      }
    }

    td {
      padding: 0.5rem;
      border: 1px solid black;

      :nth-child(2) {
        text-align: left;
      }
      :nth-child(3) {
        text-align: left;
      }
    }
  }
`;

const StylesFooter = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: left;

    td {
      padding: 0.5rem;
    }
  }
`;

function T3Form({ data, date, footer, trainingmode }) {
  const traineeCompany = () => {
    let arr = [];
    data.trainees.map((data) => {
      if (data.company_id !== 1 && data.pivot.join_status === "2") {
        arr.push(data);
      }
    });
    return arr;
  };

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const checkGender = (ic) => {
    if (parseInt(ic) % 2 !== 0) {
      return "Male";
    } else {
      return "Female";
    }
  };

  return (
    <div id="printT3">
      <StylesHeader>
        <div>
          <table>
            <tr>
              <td colSpan="100%" style={{ textAlign: "center" }}>
                FOR SBL-KHAS SCHEME ONLY
              </td>
            </tr>
            <tr>
              <td></td>
              <td
                width="400px"
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  verticalAlign: "top",
                }}
              >
                PSMB/SBL-KHAS/T3/01
              </td>
              <td width="10px"></td>
              <td
                width="400px"
                style={{
                  textAlign: "center",
                  verticalAlign: "top",
                }}
              >
                ATTENDANCE LIST
              </td>
              <td
                width="400px"
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  verticalAlign: "top",
                }}
              >
                This attendance list must be enclosed when submitting the claim
                form PSMB/SBL-KHAS /JD/14
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>Course Title</td>
              <td width="10px">:</td>
              <td colSpan="2" style={{ borderBottom: "1px solid black" }}>
                {data.course.course_name}
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>Dates of Training</td>
              <td width="10px">:</td>
              <td colSpan="2" style={{ borderBottom: "1px solid black" }}>
                {format(new Date(date), "dd MMMM yyyy (eeee)")}
              </td>
              <td></td>
            </tr>
          </table>
        </div>
      </StylesHeader>

      <StylesBody>
        <div style={{ margin: "0px 50px 220px 0px" }}>
          <table>
            <tr>
              <th>No.</th>
              <th>Name of Trainee(s)</th>
              <th>Name of Employer(s)</th>
              <th>NRIC</th>
              <th>Citizenship</th>
              <th>Sex</th>
              <th>Signature*</th>
            </tr>

            {traineeCompany().map((data, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{capitalizeWords(data.trainee_name)}</td>
                <td>{data.company.company_name.toUpperCase()}</td>
                <td>{data.trainee_ic}</td>
                <td>Malaysian</td>
                <td>{checkGender(data.trainee_ic)}</td>
                <td>
                  {trainingmode && parseInt(trainingmode) % 2 === 0
                    ? "ROT"
                    : ""}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </StylesBody>

      {footer && (
        <>
          <StylesFooter>
            <div>
              <table>
                <tr>
                  <td colSpan="100%" style={{ fontWeight: "bold" }}>
                    I certify that all trainees listed above had fully attended
                    the training.
                  </td>
                </tr>

                <tr>
                  <td width="30px"></td>
                  <td width="300px">NAME</td>
                  <td width="20px" style={{ fontWeight: "bold" }}>
                    :
                  </td>
                  <td
                    width="500px"
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                    }}
                  >
                    MOHAMAD RAHMAT BIN HARUN
                  </td>
                  <td width="30px"></td>
                  <td width="200px">SIGNATURE</td>
                  <td width="20px" style={{ fontWeight: "bold" }}>
                    :
                  </td>
                  <td
                    width="500px"
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                    }}
                  ></td>
                  <td width="30px"></td>
                </tr>

                <tr>
                  <td width="30px"></td>
                  <td width="300px">DESIGNATION</td>
                  <td width="20px" style={{ fontWeight: "bold" }}>
                    :
                  </td>
                  <td
                    width="500px"
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                    }}
                  >
                    Chief Technology Officer
                  </td>
                  <td width="30px"></td>
                  <td width="200px">DATE</td>
                  <td width="20px" style={{ fontWeight: "bold" }}>
                    :
                  </td>
                  <td
                    width="500px"
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "bold",
                    }}
                  ></td>
                  <td width="30px"></td>
                </tr>
                <tr>
                  <td colspan="X">&nbsp;</td>
                </tr>
                <tr>
                  <td width="30px"></td>
                  <td width="300px">TRAINING PROVIDER'S STAMP</td>
                  <td width="20px" style={{ fontWeight: "bold" }}>
                    :
                  </td>
                  <td colSpan="100%"></td>
                </tr>
              </table>
            </div>
          </StylesFooter>

          <div style={{ marginTop: "30px" }}>
            <table>
              <tr>
                <td width="150px"></td>
                <td width="70px">
                  <i>*Note:</i>
                </td>
                <td width="30px">
                  <i>1.</i>
                </td>
                <td>
                  <i>
                    Please make a separate attachment if more space is required
                  </i>
                </td>
                <td width="100px"></td>
              </tr>
              <tr>
                <td width="150px"></td>
                <td width="70px"></td>
                <td width="30px" style={{ verticalAlign: "top" }}>
                  <i>2.</i>
                </td>
                <td>
                  <i>
                    This attendance list must be prepared on daily basis and
                    signed by the trainee in <b>each column</b> of the relevant
                    date of training if he/she had attended the programme on
                    that day
                  </i>
                </td>
                <td width="100px"></td>
              </tr>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export { T3Form };

import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  Text,
  FormLabel,
  Heading,
  Input,
  Progress,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputRightElement,
  Stack,
  Center,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  UnorderedList,
  ListItem,
  HStack,
  theme,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  EditIcon,
  DeleteIcon,
  AddIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import { ReactTable, AlertPop, ChakraAlertDialog } from "../../../components";
import styled from "styled-components";
import { BiDotsVerticalRounded } from "react-icons/bi";
import CRUDFunction from "../../../functions/CRUDFunction";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import ThemeContext from "../../../functions/ThemeContext";

const Styles = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      width: 180px;
      :first-child {
        width: 50px;
      }
      :nth-last-child(2) {
        width: 50px;
      }
      :last-child {
        width: 50px;
        border-right: 0;
      }
    }

    td {
      margin: 0;
      padding: 1rem;
      text-align: center;
      :last-child {
        text-align: center;
      }
      :last-child {
        width: 120px;
        border-right: 0;
      }
    }
  }
`;

function Discount() {
  const drawer = useDisclosure();
  const modal = useDisclosure();
  const modalSession = useDisclosure();
  const btnRef = React.useRef();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState([]);
  const [discId, setDiscId] = useState();
  const [course, setCourse] = useState([]);
  const [training, setTraining] = useState([]);
  const [disable, setDisable] = useState(true);
  const [trainingId, setTrainingId] = useState([]);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const [error, setError] = useState(false);
  const [err, setErr] = useState({
    course: false,
    training: false,
    promo: false,
    amount: false,
    usage: false,
    valid: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const themeCtx = useContext(ThemeContext);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        id: "index",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <>
                <b>PROMO CODE </b>
              </>
            ) : (
              <>
                <b>PROMO CODE</b>
              </>
            )}
          </span>
        ),
        accessor: "disc_code",
        Cell: ({ row, value }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <>
                {value} <InfoOutlineIcon boxsize={5} color="grey" />
              </>
            ) : (
              <>
                {value} <InfoOutlineIcon boxsize={5} />
              </>
            )}
          </span>
        ),
      },
      {
        Header: "USAGE",
        accessor: (data) => {
          return [data.disc_usage, data.disc_curr_usage];
        },
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            <Progress
              hasStripe
              colorScheme="orange"
              bg="orange.200"
              borderRadius={10}
              value={value[1] !== 0 ? value[0] - value[1] : value[1]}
              max={value[0]}
            />
          </>
        ),
      },
      {
        Header: "EXPIRED ON",
        accessor: "disc_end",
        Cell: ({ value }) => (
          <>
            <Text>{format(new Date(value), "dd MMMM y h:MM a")}</Text>
          </>
        ),
      },
      {
        Header: "STATUS",
        accessor: (data) => {
          return [data.disc_start, data.disc_end];
        },
        Cell: ({ value }) => (
          <>
            <Text
              color={
                new Date(value[0]) > new Date()
                  ? "yellow.400"
                  : new Date(value[1]) > new Date()
                  ? "green.400"
                  : "red.400"
              }
            >
              <b>
                {new Date(value[0]) > new Date()
                  ? "Inactive"
                  : new Date(value[1]) > new Date()
                  ? "Active"
                  : new Date(value[1]) < new Date()
                  ? "Expired"
                  : ""}
              </b>
            </Text>
          </>
        ),
      },
      {
        Header: "ACTIONS",
        accessor: "disc_id",
        disableSortBy: true,
        Cell: ({ cell }) => (
          <>
            <Menu>
              <MenuButton
                as={Button}
                bgColor="none"
                _hover={{ bgColor: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                _expanded={{ bgColor: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
                _active={{ bgColor: themeCtx.mode && "#3b3b3b" }}
                align="center"
                variant="ghost"
                rightIcon={<BiDotsVerticalRounded boxsize={5} />}
              ></MenuButton>
              <MenuList minW="100px" bgColor={themeCtx.mode && "#2b2b2b"}>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bgColor: themeCtx.mode && "#1b1b1b" }}
                  id="edit"
                  icon={<EditIcon boxsize={5} color="#14804a" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}discount/${cell.value}`
                    );

                    if (res) {
                      setEdit(res);
                      setDiscId(cell.value);

                      drawer.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bgColor: themeCtx.mode && "#1b1b1b" }}
                  icon={<DeleteIcon boxsize={5} color="#d12953" />}
                  onClick={() => {
                    setDiscId(cell.value);
                    modal.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      },
    ],
    []
  );

  const renderRowSubComponent = React.useCallback(({ row }) => {
    return (
      <div>
        <Container
          align="left"
          maxW="100%"
          bgColor={themeCtx.mode ? "#2b2b2b" : "white"}
          py="10px"
          borderBottom="1px"
          borderColor="#bdbdbd"
        >
          <Container align="left" maxW="80%" m="0px 0px 0px 100px">
            <>
              <b>Training List</b>
              <UnorderedList>
                {data[row.id].training_name &&
                  data[row.id].training_name
                    .split(",")
                    .map((item) => <ListItem>{item}</ListItem>)}
              </UnorderedList>
              <br />
              <b>Discount Amount: </b>
              {data[row.id].disc_percent}
              {"%"}
              <br />
              <b>Current usage: </b>
              {data[row.id].disc_curr_usage !== 0
                ? data[row.id].disc_usage - data[row.id].disc_curr_usage
                : 0}
              {" / "}
              {data[row.id].disc_usage}
            </>
          </Container>
        </Container>
      </div>
    );
  });

  const handleSelect = () => {
    setDisable(false);
  };

  useEffect(() => {
    fetchData();
    fetchCourseData();
  }, []);

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
    }, 5000);
  };

  const fetchData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}discount`);
    if (res) {
      setData(res);
    }
  };

  const fetchCourseData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}course`);
    if (res) {
      setCourse(res);
    }
  };

  const fetchTrainingData = async (e) => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}discount/training/${e.target.value}`
    );
    if (res) {
      setTraining(res);
    }
  };

  const { register, unregister, reset, handleSubmit } = useForm();

  const clearError = (e) => {
    if (e.target.name === "courseSel" && e.target.value !== "") {
      setErr((existVal) => ({ ...existVal, course: false }));
    }
    if (e.target.name === "training" && e.target.value !== "") {
      setErr((existVal) => ({ ...existVal, training: false }));
    }
    if (e.target.name === "promoCode" && e.target.value !== "") {
      setErr((existVal) => ({ ...existVal, promo: false }));
    }
    if (e.target.name === "discPercent" && e.target.value !== "") {
      setErr((existVal) => ({ ...existVal, promo: false }));
    }
    if (e.target.name === "usage" && e.target.value !== "") {
      setErr((existVal) => ({ ...existVal, usage: false }));
    }
    if (e.target.name === "valid" && e.target.value !== "") {
      setErr((existVal) => ({ ...existVal, valid: false }));
    }
    if (e.target.name === "validfrom" && e.target.value !== "") {
      setErr((existVal) => ({ ...existVal, validfrom: false }));
    }
  };

  const validationCheck = (inputData) => {
    if (inputData.courseSel === "") {
      setErr((existVal) => ({ ...existVal, course: true }));
    } else {
      setErr((existVal) => ({ ...existVal, course: false }));
    }

    if (inputData.checkboxTraining === undefined) {
      setErr((existVal) => ({ ...existVal, training: true }));
    } else {
      setErr((existVal) => ({ ...existVal, training: false }));
    }
    if (inputData.promoCode === "") {
      setErr((existVal) => ({ ...existVal, promo: true }));
    } else {
      setErr((existVal) => ({ ...existVal, promo: false }));
    }
    if (inputData.discPercent === "") {
      setErr((existVal) => ({ ...existVal, amount: true }));
    } else {
      setErr((existVal) => ({ ...existVal, amount: false }));
    }
    if (inputData.usage === "") {
      setErr((existVal) => ({ ...existVal, usage: true }));
    } else {
      setErr((existVal) => ({ ...existVal, usage: false }));
    }
    if (inputData.valid === "") {
      setErr((existVal) => ({ ...existVal, valid: true }));
    } else {
      setErr((existVal) => ({ ...existVal, valid: false }));
    }
    if (inputData.validfrom === "") {
      setErr((existVal) => ({ ...existVal, validfrom: true }));
    } else {
      setErr((existVal) => ({ ...existVal, validfrom: false }));
    }
  };

  const onSubmit = async (data) => {
    if (data) {
      console.log(data);
      const formData = new FormData();
      const idArr = Array.isArray(data.checkboxTraining)
        ? data.checkboxTraining.map((str) => {
            return Number(str);
          })
        : Number(data.checkboxTraining);
      //edit
      if (drawer.isOpen) {
        if (!data.checkboxTraining) {
          setError("*Please select training session.");
          return;
        } else {
          setError("");
        }
        formData.append("trainings_id", JSON.stringify(idArr));
        formData.append("disc_code", data.promo_code.toUpperCase());
        formData.append("disc_usage", data.disc_usage);
        formData.append("disc_percent", data.disc_percent);
        formData.append("disc_start", data.disc_validfrom);
        formData.append("disc_end", data.disc_valid);

        const res = await CRUDFunction.update(
          `${URL_STAGING}discount/update/${discId}`,
          formData
        );
        if (res) {
          setData(res);
          setTraining([]);
          reset();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "The data has been updated successfully.",
          });
          timer();
          training.length = 0;
          drawer.onClose();
        }
      } else {
        validationCheck(data);
      }
      //add
      if (
        data.courseSel !== "" &&
        data.checkboxTraining !== "" &&
        data.promoCode !== "" &&
        data.discPercent !== "" &&
        data.usage !== "" &&
        data.validfrom !== "" &&
        data.valid !== ""
      ) {
        trainingId.length = 0;
        unregister("checkboxTraining");
        formData.append("trainings_id", JSON.stringify(idArr));
        formData.append("disc_code", data.promoCode.toUpperCase());
        formData.append("disc_percent", data.discPercent);
        formData.append("disc_usage", data.usage);
        formData.append("disc_curr_usage", data.usage);
        formData.append("disc_start", data.validfrom);
        formData.append("disc_end", data.valid);

        const res = await CRUDFunction.create(
          `${URL_STAGING}discount/create`,
          formData
        );
        if (res) {
          setData(res);
          reset();
          trainingId.length = 0;
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully created.",
          });
          timer();
        }
      }
    }
    return;
  };

  const modalSubmit = async (data) => {
    modalSession.onClose();
    if (trainingId !== "") {
      const newState = [...trainingId];
      newState[0] = data.checkboxTraining;
      setTrainingId(newState);
      return;
    }
    setTrainingId([...trainingId, data.checkboxTraining]);
  };

  return (
    <>
      {/* ---------------------------delete confirmation dialog -------------------------------*/}
      <ChakraAlertDialog
        dialogProps={modal}
        title="Delete Discount"
        url={[`${URL_STAGING}discount/delete/${discId}`]}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        button={"Delete"}
      />
      {/* ---------------------------delete confirmation dialog end-------------------------------*/}

      {/* ---------------------------session modal -------------------------------*/}
      <Modal
        onClose={() => {
          modalSession.onClose();
          // training.partime.length = 0;
          // training.fulltime.length = 0;
        }}
        size="3xl"
        isOpen={modalSession.isOpen}
      >
        <ModalOverlay />
        <ModalContent
          bgColor={themeCtx.mode && "#2b2b2b"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(modalSubmit)}>
            <ModalHeader textAlign="center" fontSize="30px">
              Select the training
            </ModalHeader>
            <ModalBody>
              <Stack direction="column" px="50px" mt="20px" height="auto">
                <Text>Full time</Text>
                <Stack direction={"column"} mt="2" mb="5">
                  {training.fulltime &&
                    (training.fulltime.length > 0 ? (
                      training.fulltime
                        .sort(
                          (a, b) =>
                            new Date(
                              JSON.parse(a.training_date)[0].startDate
                            ).getTime() -
                            new Date(
                              JSON.parse(b.training_date)[0].startDate
                            ).getTime()
                        )
                        .map((item) => (
                          <>
                            {new Date(
                              JSON.parse(item.training_date)[0].startDate
                            ).getTime() > new Date().getTime() ? (
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "600px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{ borderWidth: "3px solid" }}
                                  {...register("checkboxTraining")}
                                  pb="2"
                                  value={item.training_id}
                                />{" "}
                                {JSON.parse(item.training_date).length > 1
                                  ? format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].startDate
                                      ),
                                      "dd MMMM y"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[1].endDate
                                      ),
                                      "dd MMMM y"
                                    )
                                  : format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].startDate
                                      ),
                                      "dd MMMM y"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].endDate
                                      ),
                                      "dd MMMM y"
                                    )}
                                {", "}
                                {item.training_name}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ))
                    ) : (
                      <Text fontWeight={"bold"}>No data</Text>
                    ))}
                </Stack>

                <Stack direction={"column"}>
                  <Text mt="20px">Part time</Text>
                  {training.partime &&
                    (training.partime.length > 0 ? (
                      training.partime
                        .sort(
                          (a, b) =>
                            new Date(
                              JSON.parse(a.training_date)[0].startDate
                            ).getTime() -
                            new Date(
                              JSON.parse(b.training_date)[0].startDate
                            ).getTime()
                        )
                        .map((item) => (
                          <div>
                            {new Date(
                              JSON.parse(item.training_date)[0].endDate
                            ).getTime() > new Date().getTime() ? (
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "500px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  pb="2"
                                  value={item.training_id}
                                  {...register("checkboxTraining")}
                                />{" "}
                                {JSON.parse(item.training_date).length > 1
                                  ? format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].startDate
                                      ),
                                      "dd MMMM y"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[1].endDate
                                      ),
                                      "dd MMMM y"
                                    )
                                  : format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].startDate
                                      ),
                                      "dd MMMM y"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].endDate
                                      ),
                                      "dd MMMM y"
                                    )}
                                {", "}
                                {item.training_name}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))
                    ) : (
                      <Text fontWeight="bold">No data.</Text>
                    ))}
                </Stack>
              </Stack>
            </ModalBody>
            <Center>
              <Button
                type="submit"
                minW="20%"
                my="20px"
                bg="#33945f"
                _hover={{ bg: "green.700" }}
                color="white"
                mr="3"
              >
                Select
              </Button>
              <Button
                minW="20%"
                my="20px"
                bg={themeCtx.mode && "#3b3b3b"}
                onClick={() => {
                  trainingId.length = 0;
                  // training.partime.length = 0;
                  // training.fulltime.length = 0;
                  modalSession.onClose();
                }}
              >
                Cancel
              </Button>
            </Center>
          </form>
        </ModalContent>
      </Modal>
      {/* ---------------------------session modal end-------------------------------*/}
      <Heading m="25px 0px 25px 100px" color={themeCtx.mode && "white"}>
        DISCOUNT
      </Heading>
      <Container minW="90%" minH="90vh" m="20px auto">
        {alert.message ? (
          <AlertPop
            width="30%"
            className={alert.class}
            status={alert.status}
            title={alert.message}
          />
        ) : (
          ""
        )}

        <Box
          p="20px 50px"
          mb="20px"
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          color={themeCtx.mode && "white"}
          borderRadius={10}
          style={{ display: "grid" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl mb="10px">
              <FormLabel fontWeight="bold">COURSE</FormLabel>
              <InputGroup gap={3}>
                <FormControl>
                  <Select
                    w="100%"
                    defaultValue=""
                    {...register("courseSel", {
                      onChange: (e) => {
                        handleSelect();
                        fetchTrainingData(e);
                      },
                    })}
                    isInvalid={err.course}
                    onBlur={clearError}
                  >
                    <option
                      value=""
                      style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                      disabled
                    >
                      Select your course
                    </option>
                    {course.map((item) => (
                      <option
                        style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                        value={parseInt(item.course_id)}
                      >
                        {item.course_name}
                      </option>
                    ))}
                  </Select>
                  {err.course && (
                    <span style={{ color: "red" }}>
                      This field is required.
                    </span>
                  )}
                </FormControl>
                <FormControl>
                  <Input
                    type="text"
                    name="training"
                    isInvalid={err.training}
                    onBlur={clearError}
                    _hover={{ cursor: "not-allowed" }}
                    style={{ opacity: 1 }}
                    value={
                      trainingId.length === 0
                        ? ""
                        : "Selected training: " + trainingId
                    }
                    placeholder="Select training"
                  />
                  {err.training && (
                    <span style={{ color: "red" }}>
                      This field is required.
                    </span>
                  )}
                  <Tooltip
                    label="Select course first!"
                    isDisabled={disable === true ? false : true}
                    aria-label="A tooltip"
                  >
                    <InputRightElement w="auto">
                      <Button
                        borderLeftRadius={0}
                        borderWidth={2}
                        borderColor={themeCtx.mode ? "white" : "black"}
                        w="250px"
                        bgColor={themeCtx.mode ? "#1b1b1b" : "white"}
                        disabled={disable}
                        onClick={modalSession.onOpen}
                      >
                        Select training
                      </Button>
                    </InputRightElement>
                  </Tooltip>
                </FormControl>
              </InputGroup>
            </FormControl>
            <FormControl mb="10px" isInvalid={err.promo}>
              <FormLabel fontWeight="bold">PROMO CODE</FormLabel>
              <Input
                // maxLength={6}
                minLength={6}
                placeholder="Create minimum 6 characters of code (include alphabets & numbers)"
                {...register("promoCode")}
                onBlur={clearError}
              />
              {err.promo && (
                <span style={{ color: "red" }}>This field is required.</span>
              )}
            </FormControl>
            <FormControl mb="10px" isInvalid={err.promo}>
              <FormLabel fontWeight="bold">DISCOUNT AMOUNT (%)</FormLabel>
              <Input
                placeholder="Input discount amount in percentage, %"
                type="text"
                max="100"
                // step="0.01"
                // precision="2"
                {...register("discPercent", {
                  onChange: (e) => {
                    if (e.target.value > 100) {
                      e.target.value = 100;
                    }
                  },
                  onBlur: () => {
                    clearError();
                  },
                })}
                // onBlur={clearError}
              />
              {err.promo && (
                <span style={{ color: "red" }}>This field is required.</span>
              )}
            </FormControl>
            <FormControl mb="10px" isInvalid={err.usage}>
              <FormLabel fontWeight="bold">USAGE</FormLabel>
              <Input
                type="number"
                min="0"
                max="100"
                placeholder="Limitation of the code redeemed"
                {...register("usage")}
                onBlur={clearError}
              />
              {err.usage && (
                <span style={{ color: "red" }}>This field is required.</span>
              )}
            </FormControl>
            <HStack>
              <FormControl pb="20px" isInvalid={err.validfrom}>
                <FormLabel fontWeight="bold">VALID FROM</FormLabel>
                <Input
                  type="text"
                  placeholder="Start date of the code"
                  {...register("validfrom")}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    e.target.type = "text";
                    clearError(e);
                  }}
                />
                {err.validfrom && (
                  <span style={{ color: "red" }}>This field is required.</span>
                )}
              </FormControl>
              <FormControl pb="20px" isInvalid={err.valid}>
                <FormLabel fontWeight="bold">VALID TILL</FormLabel>
                <Input
                  type="text"
                  placeholder="Expiry date of the code"
                  {...register("valid")}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    e.target.type = "text";
                    clearError(e);
                  }}
                />
                {err.valid && (
                  <span style={{ color: "red" }}>This field is required.</span>
                )}
              </FormControl>
            </HStack>
            <Center>
              <Button
                type="submit"
                leftIcon={<AddIcon />}
                w="200px"
                bg="#33945f"
                _hover={{ bg: "green.700" }}
                color="white"
                fontSize="14px"
              >
                Add Discount
              </Button>
            </Center>
          </form>
        </Box>
        <Heading size="lg" mb="20px" color={themeCtx.mode && "white"}>
          DISCOUNT LIST
        </Heading>
        <Box borderRadius={10} bg={themeCtx.mode ? "#2b2b2b" : "white"} p="-2">
          <Styles>
            <ReactTable
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
              modulePage="discount"
              themeCtx={themeCtx}
            />
          </Styles>
        </Box>

        {/* -----------------------------------edit discount drawer----------------------------------*/}
        <Drawer
          isOpen={drawer.isOpen}
          placement="right"
          size="md"
          onClose={() => {
            reset();
            training.partime && (training.partime.length = 0);
            training.fulltime && (training.fulltime.length = 0);
            drawer.onClose();
          }}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerContent
              bgColor={themeCtx.mode && "#2b2b2b"}
              color={themeCtx.mode && "white"}
            >
              <DrawerCloseButton />
              <DrawerHeader textAlign="center">EDIT DISCOUNT</DrawerHeader>
              <DrawerBody>
                <FormControl mb="10px" isRequired>
                  <FormLabel fontWeight="bold">COURSE</FormLabel>
                  <Select
                    w="100%"
                    defaultValue=""
                    onChange={(e) => {
                      fetchTrainingData(e);
                    }}
                  >
                    <option
                      value=""
                      style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                      disabled
                    >
                      Select your course
                    </option>
                    {course.map((item) => (
                      <option
                        value={item.course_id}
                        style={{ backgroundColor: themeCtx.mode && "#1b1b1b" }}
                      >
                        {item.course_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mb="10px">
                  <FormLabel fontWeight="bold">SESSION</FormLabel>
                  <Text mb="3">Full time</Text>
                  <Stack direction={"column"} mb="2">
                    {training.fulltime && training.fulltime.length > 0 ? (
                      training.fulltime
                        .sort(
                          (a, b) =>
                            new Date(
                              JSON.parse(a.training_date)[0].startDate
                            ).getTime() -
                            new Date(
                              JSON.parse(b.training_date)[0].startDate
                            ).getTime()
                        )
                        .map((item) => (
                          <>
                            {new Date(
                              JSON.parse(item.training_date)[0].startDate
                            ).getTime() > new Date().getTime() ? (
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "400px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  pb="2"
                                  value={item.training_id}
                                  {...register("checkboxTraining")}
                                />{" "}
                                {JSON.parse(item.training_date).length > 1
                                  ? format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].startDate
                                      ),
                                      "dd MMM yy"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[1].endDate
                                      ),
                                      "dd MMM yy"
                                    )
                                  : format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].startDate
                                      ),
                                      "dd MMM yy"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].endDate
                                      ),
                                      "dd MMM yy"
                                    )}
                                {", "}
                                {item.training_name}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ))
                    ) : (
                      <Text fontWeight="bold">No data.</Text>
                    )}
                  </Stack>
                  <Text mb="3">Part time</Text>
                  <Stack direction={"column"}>
                    {training.partime && training.partime.length > 0 ? (
                      training.partime
                        .sort(
                          (a, b) =>
                            new Date(
                              JSON.parse(a.training_date)[0].startDate
                            ).getTime() -
                            new Date(
                              JSON.parse(b.training_date)[0].startDate
                            ).getTime()
                        )
                        .map((item) => (
                          <>
                            {new Date(
                              JSON.parse(item.training_date)[0].endDate
                            ).getTime() > new Date().getTime() ? (
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "400px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  pb="2"
                                  value={item.training_id}
                                  {...register("checkboxTraining")}
                                />{" "}
                                {JSON.parse(item.training_date).length > 1
                                  ? format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].startDate
                                      ),
                                      "dd MMM yy"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[1].endDate
                                      ),
                                      "dd MMM yy"
                                    )
                                  : format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].startDate
                                      ),
                                      "dd MMM yy"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        JSON.parse(
                                          item.training_date
                                        )[0].endDate
                                      ),
                                      "dd MMM yy"
                                    )}
                                {", "}
                                {item.training_name}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ))
                    ) : (
                      <Text mb="3" fontWeight="bold">
                        No data.
                      </Text>
                    )}
                  </Stack>
                  <Text color="red">{error}</Text>
                </FormControl>
                <FormControl mb="10px" isRequired>
                  <FormLabel fontWeight="bold">PROMO CODE</FormLabel>
                  <Input
                    type="text"
                    // maxLength={6}
                    minLength={6}
                    defaultValue={edit.disc_code}
                    placeholder="Create minimum 6 character of code (include alphabets & numbers)"
                    {...register("promo_code")}
                  />
                </FormControl>
                <FormControl mb="10px" isRequired>
                  <FormLabel fontWeight="bold">DISCOUNT AMOUNT (%)</FormLabel>
                  <Input
                    placeholder="Input discount amount in percentage, %"
                    type="text"
                    defaultValue={edit.disc_percent}
                    {...register("disc_percent")}
                  />
                </FormControl>
                <FormControl mb="10px" isRequired>
                  <FormLabel fontWeight="bold">USAGE</FormLabel>
                  <Input
                    type="number"
                    defaultValue={edit.disc_usage}
                    placeholder="Limitation of the code redeemed"
                    {...register("disc_usage")}
                  />
                </FormControl>
                <HStack>
                  <FormControl pb="20px" isRequired>
                    <FormLabel fontWeight="bold">VALID FROM</FormLabel>
                    <Input
                      type="text"
                      placeholder="Start date of the code"
                      defaultValue={
                        edit.disc_start &&
                        format(new Date(edit.disc_start), "y-MM-dd")
                      }
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      {...register("disc_validfrom")}
                    />
                  </FormControl>
                  <FormControl pb="20px" isRequired>
                    <FormLabel fontWeight="bold">VALID TILL</FormLabel>
                    <Input
                      type="text"
                      placeholder="Expiry date of the code"
                      defaultValue={
                        edit.disc_end &&
                        format(new Date(edit.disc_end), "y-MM-dd")
                      }
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      {...register("disc_valid")}
                    />
                  </FormControl>
                </HStack>
              </DrawerBody>
              <DrawerFooter>
                <Center w="100%">
                  <Button
                    type="submit"
                    bg="#33945f"
                    _hover={{ bg: "green.700" }}
                    fontWeight="medium"
                    color="white"
                    mr="3"
                    w="100%"
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      reset();
                      training.partime && (training.partime.length = 0);
                      training.fulltime && (training.fulltime.length = 0);
                      drawer.onClose();
                    }}
                    w="100%"
                    bg={themeCtx.mode && "#3b3b3b"}
                    fontWeight="medium"
                  >
                    Cancel
                  </Button>
                </Center>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </Drawer>
        {/* -----------------------------------edit discount drawer end----------------------------------*/}
      </Container>
    </>
  );
}

export { Discount };

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
  Spinner,
  Skeleton,
  Link,
} from "@chakra-ui/react";
import CalendarAdmin from "../../../components/calendar/calendar";
import CRUDFunction from "../../../functions/CRUDFunction";
import { format } from "date-fns";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import ThemeContext from "../../../functions/ThemeContext";

function Dashboard() {
  const [courseNames, setCourseName] = useState([]);
  const [totalCohort, setTotalCohort] = useState([]);
  const [loader, setLoader] = useState(true);
  const themeCtx = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTotalCohort();
    fetchCourse();
  }, []);

  const fetchCourse = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}coursename`);
    if (res) {
      setCourseName(res);
    }
  };

  const fetchTotalCohort = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}dashboard`);
    let totalHPB = 0,
      totalHLSB = 0;
    if (res) {
      setLoader(false);
      setTotalCohort(res);
      for (let i = 0; i < res.hpb.length; i++) {
        for (let j = 0; j < res.hpb[i].trainees.length; j++) {
          if (res.hpb[i].trainees[j].pivot.join_status !== "3") {
            totalHPB += 1;
          }
        }
      }
      for (let i = 0; i < res.hlsb.length; i++) {
        for (let j = 0; j < res.hlsb[i].trainees.length; j++) {
          if (res.hlsb[i].trainees[j].pivot.join_status !== "3") {
            totalHLSB += 1;
          }
        }
      }
      // res.hpb.map((item) => {
      //   totalHPB += item.trainees.length;
      // });
      // res.hlsb.map((item) => {
      //   totalHLSB += item.trainees.length;
      // });
    }
    setTotalCohort((existVal) => ({
      ...existVal,
      totalHPB: totalHPB,
      totalHLSB: totalHLSB,
    }));
  };

  const navTrainingDetail = (value) => {
    let course_name;
    courseNames.map((item) => {
      if (value.course_id === item.course_id) {
        course_name = item.course_name;
      }
    });
    navigate("/training/trainee", {
      replace: false,
      state: {
        id: value.training_id,
        name: value.training_name,
        date: JSON.parse(value.training_date),
        time: JSON.parse(value.training_time),
        program_id: value.programme.program_id,
        course_id: value.course_id,
        course_name: course_name,
        fullData: value,
      },
    });
  };

  return (
    <Container maxW={"90%"} minH={"100vh"} mt="10px">
      <Heading my="6" color={themeCtx.mode && "white"}>
        DASHBOARD
      </Heading>
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(4, 1fr)"
        w="100%"
        gap={10}
      >
        <GridItem rowSpan={2} colSpan={2}>
          <Box m="10px 0px">
            <Heading size="md" color={themeCtx.mode && "white"}>
              Number of Cohort by Course
            </Heading>
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap="2">
            <GridItem colspan={1}>
              <Box
                bg={themeCtx.mode ? "#2b2b2b" : "white"}
                p="5"
                align="center"
                borderRadius="8px"
                m="5px"
                shadow="md"
              >
                <Text fontSize="50px" fontWeight="bold" color="#33945F">
                  {loader ? <Spinner size="xl" /> : totalCohort.total_pche}
                </Text>
                <Text fontWeight="bold" color={themeCtx.mode && "white"}>
                  PCHE
                </Text>
              </Box>
              <Box
                bg={themeCtx.mode ? "#2b2b2b" : "white"}
                p="5"
                align="center"
                borderRadius="8px"
                m="5px"
                shadow="md"
              >
                <Text fontSize="50px" fontWeight="bold" color="#33945F">
                  {loader ? <Spinner size="xl" /> : totalCohort.total_hc}
                </Text>
                <Text fontWeight="bold" color={themeCtx.mode && "white"}>
                  Halal Competency
                </Text>
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                bg={themeCtx.mode ? "#2b2b2b" : "white"}
                p="5"
                align="center"
                borderRadius="8px"
                m="5px"
                shadow="md"
              >
                <Text fontSize="50px" fontWeight="bold" color="#33945F">
                  {loader ? <Spinner size="xl" /> : totalCohort.total_pciha}
                </Text>
                <Text fontWeight="bold" color={themeCtx.mode && "white"}>
                  PCIHA
                </Text>
              </Box>
              <Box
                bg={themeCtx.mode ? "#2b2b2b" : "white"}
                p="5"
                align="center"
                borderRadius="8px"
                m="5px"
                shadow="md"
              >
                <Text fontSize="50px" fontWeight="bold" color="#33945F">
                  {loader ? <Spinner size="xl" /> : totalCohort.total_ha}
                </Text>
                <Text fontWeight="bold" color={themeCtx.mode && "white"}>
                  Halal Awareness
                </Text>
              </Box>
            </GridItem>
          </Grid>
          <Box m="10px 0px">
            <Heading size="md" color={themeCtx.mode && "white"}>
              Total Participant by Course
            </Heading>
          </Box>
          <Grid templateRows="repeat(2, 1fr)" gap={5}>
            <GridItem rowSpan={1}>
              <Box
                bg={themeCtx.mode ? "#2b2b2b" : "white"}
                p="10"
                style={{
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.25)",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <Grid templateColumns="repeat(5, 1fr)">
                  <GridItem colSpan={1}>
                    <Heading fontSize="5xl" color={themeCtx.mode && "white"}>
                      {loader ? <Spinner /> : totalCohort.totalHPB}
                    </Heading>
                  </GridItem>
                  <GridItem colSpan={4} my="auto">
                    <Text fontSize="2xl" color="#33945F">
                      HPB Participants
                    </Text>
                  </GridItem>
                </Grid>
              </Box>
            </GridItem>
            <GridItem rowSpan={1}>
              <Box
                bg={themeCtx.mode ? "#2b2b2b" : "white"}
                p="10"
                style={{
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.25)",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <Grid templateColumns="repeat(5, 1fr)">
                  <GridItem colSpan={1}>
                    <Heading fontSize="5xl" color={themeCtx.mode && "white"}>
                      {loader ? <Spinner /> : totalCohort.totalHLSB}
                    </Heading>
                  </GridItem>
                  <GridItem colSpan={4} my="auto">
                    <Text fontSize="2xl" color="#33945F">
                      HLSB Participants
                    </Text>
                  </GridItem>
                </Grid>
              </Box>
            </GridItem>
          </Grid>
          <br />
        </GridItem>
        <GridItem rowSpan={2} colSpan={2}>
          <Box m="10px 0px">
            <Heading size="md" color={themeCtx.mode && "white"}>
              Calendar
            </Heading>
            <Box
              style={{
                boxShadow: !themeCtx.mode && "0px 2px 4px #c4c4c4",
                borderRadius: "8px",
              }}
            >
              <CalendarAdmin data={totalCohort.training} label={false} />
            </Box>
          </Box>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem>
          <Box m="10px 0px">
            <Heading size="md" color={themeCtx.mode && "white"}>
              Ongoing Training
            </Heading>
          </Box>
          <Box
            style={{
              maxHeight: "300px",
              boxShadow: !themeCtx.mode && "0px 2px 4px #c4c4c4",
              padding: "20px",
              borderRadius: "8px",
              overflowY: "auto",
            }}
            bg={themeCtx.mode ? "#2b2b2b" : "white"}
            color={themeCtx.mode && "white"}
            mb="30px"
          >
            {totalCohort.upcoming_training ? (
              totalCohort.upcoming_training.map((item) =>
                format(new Date(), "Y-MM-dd") ===
                JSON.parse(item.training_date)[0].startDate ? (
                  <>
                    <Box mb="5" p="2">
                      <Link
                        fontWeight="bold"
                        onClick={() => {
                          navTrainingDetail(item);
                        }}
                      >
                        {item.training_name}
                      </Link>
                      {JSON.parse(item.training_date)[0].startDate !==
                      JSON.parse(item.training_date)[0].endDate ? (
                        <Text mt="1">
                          Date:{" "}
                          {format(
                            new Date(
                              JSON.parse(item.training_date)[0].startDate
                            ),
                            "dd MMM"
                          )}
                          {" - "}
                          {format(
                            new Date(JSON.parse(item.training_date)[0].endDate),
                            "dd MMM y"
                          )}
                        </Text>
                      ) : (
                        <Text mt="1">
                          Date:{" "}
                          {format(
                            new Date(
                              JSON.parse(item.training_date)[0].startDate
                            ),
                            "dd MMM y"
                          )}
                        </Text>
                      )}
                      {JSON.parse(item.training_date).map(
                        (item, index) =>
                          index !== 0 &&
                          (item.startDate !== item.endDate ? (
                            <Text ml="43px">
                              {format(new Date(item.startDate), "dd MMM")}
                              {" - "}
                              {format(new Date(item.endDate), "dd MMM y")}
                            </Text>
                          ) : (
                            <Text ml="43px">
                              {format(new Date(item.startDate), "dd MMM y")}
                            </Text>
                          ))
                      )}
                      {item.training_time !== null && (
                        <Text mt="1">
                          Time:{" "}
                          {Number(JSON.parse(item.training_time)[0][0] * 10) +
                            Number(JSON.parse(item.training_time)[0][1]) <
                          11 ? (
                            <>
                              {Number(
                                JSON.parse(item.training_time)[0][0] * 10
                              ) + Number(JSON.parse(item.training_time)[0][1])}
                              {JSON.parse(item.training_time)[0].slice(2)} AM
                            </>
                          ) : Number(
                              JSON.parse(item.training_time)[0][0] * 10
                            ) +
                              Number(JSON.parse(item.training_time)[0][1]) >
                            12 ? (
                            <>
                              {Number(
                                JSON.parse(item.training_time)[0][0] * 10
                              ) +
                                Number(JSON.parse(item.training_time)[0][1]) -
                                12}
                              {JSON.parse(item.training_time)[0].slice(2)} PM
                            </>
                          ) : (
                            <>
                              {Number(
                                JSON.parse(item.training_time)[0][0] * 10
                              ) + Number(JSON.parse(item.training_time)[0][1])}
                              {JSON.parse(item.training_time)[0].slice(2)} PM
                            </>
                          )}{" "}
                          -{" "}
                          {Number(JSON.parse(item.training_time)[1][0] * 10) +
                            Number(JSON.parse(item.training_time)[1][1]) <
                          11 ? (
                            <>
                              {Number(
                                JSON.parse(item.training_time)[1][0] * 10
                              ) + Number(JSON.parse(item.training_time)[1][1])}
                              {JSON.parse(item.training_time)[1].slice(2)} AM
                            </>
                          ) : Number(
                              JSON.parse(item.training_time)[1][0] * 10
                            ) +
                              Number(JSON.parse(item.training_time)[1][1]) >
                            12 ? (
                            <>
                              {Number(
                                JSON.parse(item.training_time)[1][0] * 10
                              ) +
                                Number(JSON.parse(item.training_time)[1][1]) -
                                12}
                              {JSON.parse(item.training_time)[1].slice(2)} PM
                            </>
                          ) : (
                            <>
                              {Number(
                                JSON.parse(item.training_time)[1][0] * 10
                              ) + Number(JSON.parse(item.training_time)[1][1])}
                              {JSON.parse(item.training_time)[1].slice(2)} PM
                            </>
                          )}
                        </Text>
                      )}
                    </Box>
                  </>
                ) : (
                  new Date().getTime() >
                    new Date(
                      JSON.parse(item.training_date)[0].startDate
                    ).getTime() &&
                  new Date().getTime() <
                    new Date(
                      JSON.parse(item.training_date)[
                        JSON.parse(item.training_date).length - 1
                      ].endDate
                    ).getTime() && (
                    <>
                      <Box mb="5" p="2">
                        <Link
                          fontWeight="bold"
                          onClick={() => {
                            navTrainingDetail(item);
                          }}
                        >
                          {item.training_name}
                        </Link>
                        {JSON.parse(item.training_date)[0].startDate !==
                        JSON.parse(item.training_date)[0].endDate ? (
                          <Text mt="1">
                            Date:{" "}
                            {format(
                              new Date(
                                JSON.parse(item.training_date)[0].startDate
                              ),
                              "dd MMM"
                            )}
                            {" - "}
                            {format(
                              new Date(
                                JSON.parse(item.training_date)[0].endDate
                              ),
                              "dd MMM y"
                            )}
                          </Text>
                        ) : (
                          <Text mt="1">
                            Date:{" "}
                            {format(
                              new Date(
                                JSON.parse(item.training_date)[0].startDate
                              ),
                              "dd MMM y"
                            )}
                          </Text>
                        )}
                        {JSON.parse(item.training_date).map(
                          (item, index) =>
                            index !== 0 &&
                            (item.startDate !== item.endDate ? (
                              <Text ml="43px">
                                {format(new Date(item.startDate), "dd MMM")}
                                {" - "}
                                {format(new Date(item.endDate), "dd MMM y")}
                              </Text>
                            ) : (
                              <Text ml="43px">
                                {format(new Date(item.startDate), "dd MMM y")}
                              </Text>
                            ))
                        )}
                        {item.training_time !== null && (
                          <Text mt="1">
                            Time:{" "}
                            {Number(JSON.parse(item.training_time)[0][0] * 10) +
                              Number(JSON.parse(item.training_time)[0][1]) <
                            11 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[0][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[0][1])}
                                {JSON.parse(item.training_time)[0].slice(2)} AM
                              </>
                            ) : Number(
                                JSON.parse(item.training_time)[0][0] * 10
                              ) +
                                Number(JSON.parse(item.training_time)[0][1]) >
                              12 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[0][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[0][1]) -
                                  12}
                                {JSON.parse(item.training_time)[0].slice(2)} PM
                              </>
                            ) : (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[0][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[0][1])}
                                {JSON.parse(item.training_time)[0].slice(2)} PM
                              </>
                            )}{" "}
                            -{" "}
                            {Number(JSON.parse(item.training_time)[1][0] * 10) +
                              Number(JSON.parse(item.training_time)[1][1]) <
                            11 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[1][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[1][1])}
                                {JSON.parse(item.training_time)[1].slice(2)} AM
                              </>
                            ) : Number(
                                JSON.parse(item.training_time)[1][0] * 10
                              ) +
                                Number(JSON.parse(item.training_time)[1][1]) >
                              12 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[1][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[1][1]) -
                                  12}
                                {JSON.parse(item.training_time)[1].slice(2)} PM
                              </>
                            ) : (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[1][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[1][1])}
                                {JSON.parse(item.training_time)[1].slice(2)} PM
                              </>
                            )}
                          </Text>
                        )}
                      </Box>
                    </>
                  )
                )
              )
            ) : (
              <>
                <Skeleton h="20px" w="70%" mb="2" />
                <Skeleton h="20px" w="50%" />
              </>
            )}
          </Box>
          <Box m="10px 0px">
            <Heading size="md" color={themeCtx.mode && "white"}>
              Upcoming Training
            </Heading>
          </Box>
          <Box
            style={{
              maxHeight: "550px",
              boxShadow: !themeCtx.mode && "0px 2px 4px #c4c4c4",
              padding: "20px",
              borderRadius: "8px",
              overflowY: "auto",
            }}
            bg={themeCtx.mode ? "#2b2b2b" : "white"}
            color={themeCtx.mode && "white"}
          >
            {totalCohort.upcoming_training ? (
              totalCohort.upcoming_training.map(
                (item) =>
                  new Date(
                    JSON.parse(item.training_date)[0].startDate
                  ).getTime() > new Date().getTime() && (
                    <>
                      <Box mb="5" p="2">
                        <Link
                          fontWeight="bold"
                          onClick={() => {
                            navTrainingDetail(item);
                          }}
                        >
                          {item.training_name}
                        </Link>
                        {JSON.parse(item.training_date)[0].startDate !==
                        JSON.parse(item.training_date)[0].endDate ? (
                          <Text mt="1">
                            Date:{" "}
                            {format(
                              new Date(
                                JSON.parse(item.training_date)[0].startDate
                              ),
                              "dd MMM"
                            )}
                            {" - "}
                            {format(
                              new Date(
                                JSON.parse(item.training_date)[0].endDate
                              ),
                              "dd MMM y"
                            )}
                          </Text>
                        ) : (
                          <Text mt="1">
                            Date:{" "}
                            {format(
                              new Date(
                                JSON.parse(item.training_date)[0].startDate
                              ),
                              "dd MMM y"
                            )}
                          </Text>
                        )}
                        {JSON.parse(item.training_date).map(
                          (item, index) =>
                            index !== 0 &&
                            (item.startDate !== item.endDate ? (
                              <Text ml="43px">
                                {format(new Date(item.startDate), "dd MMM")}
                                {" - "}
                                {format(new Date(item.endDate), "dd MMM y")}
                              </Text>
                            ) : (
                              <Text ml="43px">
                                {format(new Date(item.startDate), "dd MMM y")}
                              </Text>
                            ))
                        )}
                        {item.training_time !== null && (
                          <Text mt="1">
                            Time:{" "}
                            {Number(JSON.parse(item.training_time)[0][0] * 10) +
                              Number(JSON.parse(item.training_time)[0][1]) <
                            11 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[0][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[0][1])}
                                {JSON.parse(item.training_time)[0].slice(2)} AM
                              </>
                            ) : Number(
                                JSON.parse(item.training_time)[0][0] * 10
                              ) +
                                Number(JSON.parse(item.training_time)[0][1]) >
                              12 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[0][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[0][1]) -
                                  12}
                                {JSON.parse(item.training_time)[0].slice(2)} PM
                              </>
                            ) : (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[0][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[0][1])}
                                {JSON.parse(item.training_time)[0].slice(2)} PM
                              </>
                            )}{" "}
                            -{" "}
                            {Number(JSON.parse(item.training_time)[1][0] * 10) +
                              Number(JSON.parse(item.training_time)[1][1]) <
                            11 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[1][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[1][1])}
                                {JSON.parse(item.training_time)[1].slice(2)} AM
                              </>
                            ) : Number(
                                JSON.parse(item.training_time)[1][0] * 10
                              ) +
                                Number(JSON.parse(item.training_time)[1][1]) >
                              12 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[1][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[1][1]) -
                                  12}
                                {JSON.parse(item.training_time)[1].slice(2)} PM
                              </>
                            ) : (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[1][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[1][1])}
                                {JSON.parse(item.training_time)[1].slice(2)} PM
                              </>
                            )}
                          </Text>
                        )}
                      </Box>
                    </>
                  )
              )
            ) : (
              <>
                <Skeleton h="20px" w="70%" mb="2" />
                <Skeleton h="20px" w="50%" />
              </>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Container>
  );
}

export { Dashboard };

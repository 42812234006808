import {
  Box,
  Center,
  Container,
  Heading,
  Grid,
  GridItem,
  Text,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Radio,
  RadioGroup,
  Image,
  Button,
  Divider,
  Input,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  VStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Icon,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import paymentImage from "../../../assets/payment.png";
import { useLocation, useNavigate } from "react-router-dom";
import CRUDFunction from "../../../functions/CRUDFunction";
import { format } from "date-fns";
import {
  URL_LOCAL,
  URL_STAGING,
  CAPTCHA_SITE_KEY,
  PUBLIC_KEY_STRIPE,
} from "../../../constant";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { Helmet } from "react-helmet";

const stripePromise = loadStripe(PUBLIC_KEY_STRIPE);

function Checkout() {
  const countryCodes = require("country-codes-list");
  const listCurrency = countryCodes.customList(
    "countryNameEn",
    "{currencyCode}"
  );
  const [convertRate, setRate] = useState("");
  const [stripeFee, setStripeFee] = useState("");
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const { state } = useLocation();
  const [course, setCourse] = useState();
  const [isAddressExist, setAddressExist] = useState(false);
  const [payment, setPayment] = useState("hrdc");
  const [address, setAddress] = useState([]);
  const [nationalityBasedFee, setBasedFee] = useState(0);

  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [discount, setDiscount] = useState(false);
  const promo_code = useRef();
  const loading = useDisclosure();
  const stripeModal = useDisclosure();
  const [clientSecret, setClientSecret] = useState("");
  const [init, setInit] = useState(true);

  const displayModes = [
    "Full Time Online Inhouse",
    "Full Time Physical Inhouse",
    "Part Time Online Inhouse",
    "Part Time Physical Inhouse",
    "Full Time Online",
    "Full Time Physical",
    "Part Time Online",
    "Part Time Physical",
  ];

  useEffect(() => {
    if (state.login === "company") {
      if (
        JSON.parse(state.company.company_address)[0].address === "" ||
        JSON.parse(state.company.company_address)[0].postcode === "" ||
        JSON.parse(state.company.company_address)[0].state === ""
      ) {
        setAddressExist(false);
      } else {
        setAddressExist(true);
      }
    }
    fetchCourse();
    fetchFee();
  }, []);

  const toast = useToast();

  const fetchCourse = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}coursedetails/${state.course_id}`
    );
    if (res) {
      setCourse(res[0]);
      if (
        (state.login === "company" && !state.isNation) ||
        state.login === "individu" ||
        (state.login === "company" &&
          state.isNation &&
          state.total_trainee < 5 &&
          state.course_id === 3) ||
        state.training.training_hrdc === "0"
      ) {
        setPayment("HLSB");
      }
    }
  };

  const fetchFee = async () => {
    if (JSON.parse(state.training.training_fee)[0]) {
      if (state.isNation) {
        setBasedFee(JSON.parse(state.training.training_fee)[0].ringgit);
      } else {
        setBasedFee(JSON.parse(state.training.training_fee)[0].dollar);
      }
    } else {
      setBasedFee(state.training.training_fee);
    }

    if (init) {
      if (
        (state.login === "company" &&
          JSON.parse(state.company.company_address)[0].country !== undefined &&
          JSON.parse(state.company.company_address)[0].country !==
            "Malaysia") ||
        (state.login === "individu" &&
          JSON.parse(state.participants.trainee_address)[0].country !==
            undefined &&
          JSON.parse(state.participants.trainee_address)[0].country !==
            "Malaysia")
      ) {
        fetchConvertRate();
      }
      setInit(false);
    }
  };

  const fetchConvertRate = async () => {
    const res = await new Promise((resolve) => {
      axios
        .get(
          "https://openexchangerates.org/api/latest.json?app_id=1fac89864ede41d4b6d01f55a63d8f61"
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          resolve(err.response.data);
        });
    });
    if (res) {
      setRate(res.rates);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const onPlaceOrder = async () => {
    const formData = new FormData();
    const token = captchaRef.current.getValue();
    formData.append("captcha_token", token);
    formData.append("collection_title", state.training.training_name);
    const captchaRes = await CRUDFunction.create(
      `${URL_STAGING}captcha`,
      formData
    );
    let collectionID = 0;

    if (!captchaRes.success) {
      setErrorMsg(true);
    } else {
      loading.onOpen();
      setErrorMsg(false);
      captchaRef.current.reset();
      let billFee = nationalityBasedFee * state.total_trainee;

      localStorage.setItem("login", state.login);
      if (state.login === "company") {
        const formData = new FormData();
        if (state.total_trainee > 0) {
          let traineeName = [],
            traineeIC = [],
            traineePhone = [],
            traineeEmail = [],
            traineePosition = [],
            traineeAllergies = [],
            traineeAddress = [],
            traineePayment = [],
            traineeHrdf = [],
            traineePromo = [];
          for (let i = 1; i < state.participants.length; i++) {
            traineeName.push(state.participants[i].trainee_name);
            traineeIC.push(state.participants[i].trainee_ic);
            traineePhone.push(state.participants[i].trainee_phone);
            traineeEmail.push(state.participants[i].trainee_email);
            traineePosition.push(state.participants[i].trainee_position);
            traineeAllergies.push(state.participants[i].trainee_allergies);
            traineeAddress.push(state.company.company_address);
            if (payment === "secure") {
              traineePayment.push("3");
            } else {
              traineePayment.push("1");
            }
            traineeHrdf.push(payment === "hrdc" ? "1" : "0");
            if (discount) {
              traineePromo.push(discount.disc_id);
            }
          }
          formData.append("training_id", state.training.training_id);
          formData.append("company_ssm", state.company.company_ssm);
          formData.append("program_id", state.training.program_id);
          formData.append("trainee_name", JSON.stringify(traineeName));
          formData.append("trainee_ic", JSON.stringify(traineeIC));
          formData.append("trainee_email", JSON.stringify(traineeEmail));
          formData.append("trainee_phone", JSON.stringify(traineePhone));
          formData.append("trainee_position", JSON.stringify(traineePosition));
          formData.append(
            "trainee_allergies",
            JSON.stringify(traineeAllergies)
          );
          formData.append("trainee_address", JSON.stringify(traineeAddress));
          formData.append("trainee_payment", JSON.stringify(traineePayment));
          formData.append("trainee_hrdf", JSON.stringify(traineeHrdf));
          if (discount) {
            formData.append("trainee_promo", JSON.stringify(traineePromo));
            billFee =
              nationalityBasedFee *
              ((100 - discount.disc_percent) / 100) *
              state.total_trainee;
          }
        }

        if (payment === "stripe") {
          formData.append("secure_payment", true);
          const formDataJsonString = JSON.stringify(
            Array.from(formData.entries())
          );
          localStorage.setItem("tempData", formDataJsonString);
          loading.onClose();
          stripeModal.onOpen();
        } else {
          formData.append("secure_payment", false);
          const res = await CRUDFunction.create(
            `${URL_STAGING}traineeCompany/${state.training.training_id}`,
            formData
          );

          if (res) {
            if (res.message) {
              toast({
                title: "Order fail to place",
                description: res.message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            } else {
              loading.onClose();
              if (payment === "HLSB") {
                navigate("/successful", {
                  replace: false,
                });
              } else {
                navigate("/successful", {
                  replace: false,
                });
              }
            }
          } else {
            navigate("/failed", {
              replace: false,
            });
          }
        }
      } else if (state.login === "individu") {
        const formData = new FormData();
        formData.append("training_id", state.training_id);
        formData.append("program_id", state.training.program_id);
        formData.append("company_id", state.company.company_id);
        formData.append("trainee_name", state.participants.trainee_name);
        formData.append("trainee_email", state.participants.trainee_email);
        formData.append("trainee_ic", state.participants.trainee_ic);
        formData.append("trainee_phone", state.participants.trainee_phone);
        formData.append("trainee_position", "Self-Employed");
        formData.append("trainee_payment", 0);
        formData.append("trainee_hrdf", payment === "hrdc" ? "1" : "0");
        formData.append("trainee_address", state.participants.trainee_address);
        formData.append(
          "trainee_allergies",
          state.participants.trainee_allergies
        );
        formData.append(
          "trainee_promo",
          state.participants.trainee_promo.toUpperCase()
        );
        if (state.discount) {
          formData.append("disc_id", state.discount.disc_id);
          billFee =
            nationalityBasedFee *
            ((100 - state.discount.disc_percent) / 100) *
            state.total_trainee;
        }
        if (state.update_address) {
          formData.append("update_address", state.update_address);
        }

        if (payment === "stripe") {
          formData.append("secure_payment", true);
          const formDataJsonString = JSON.stringify(
            Array.from(formData.entries())
          );
          localStorage.setItem("tempData", formDataJsonString);
          loading.onClose();
          stripeModal.onOpen();
        } else {
          formData.append("secure_payment", false);
          const res = await CRUDFunction.create(
            `${URL_STAGING}traineeClient`,
            formData
          );

          if (res) {
            if (res.message) {
              toast({
                title: "Order fail to place",
                description: res.message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            } else {
              loading.onClose();
              if (payment === "HLSB") {
                navigate("/successful", {
                  replace: false,
                });
              } else {
                navigate("/successful", {
                  replace: false,
                });
              }
            }
          } else {
            navigate("/failed", {
              replace: false,
            });
          }
        }
      }
    }
  };

  const onUpdateShippingDetails = async (data) => {
    if (data) {
      if (state.login === "company") {
        const formData = new FormData();
        let combineAddress = [
          {
            address: data.address,
            postcode: data.postcode,
            state: data.state,
          },
        ];
        formData.append("company_address", JSON.stringify(combineAddress));
        formData.append("company_name", state.company.company_name);
        const res = await CRUDFunction.update(
          `${URL_STAGING}company/updateAddress`,
          formData
        );
        if (res) {
          setAddressExist(true);
          setAddress({
            address: JSON.parse(res.company_address)[0].address,
            postcode: JSON.parse(res.company_address)[0].postcode,
            state: JSON.parse(res.company_address)[0].state,
          });
        }
      }
    }
  };

  const promoCode = async (code) => {
    if (code) {
      promo_code.current.value = "";
      if (!discount) {
        const res = await CRUDFunction.get(
          `${URL_STAGING}checkdiscount/training/${
            state.training.training_id
          }/${code.toUpperCase()}`
        );
        if (res === "invalid") {
          toast({
            title: "Failed",
            description: "Invalid promo code.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else if (res === "expired") {
          toast({
            title: "Failed",
            description: "The promo code has expired.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else if (res === "not active") {
          toast({
            title: "Failed",
            description: "The promo code is not active yet.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else if (res === "zero") {
          toast({
            title: "Failed",
            description: "The promo code has reached its redeem limit.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else {
          setDiscount(res);
          toast({
            title: "Success",
            description: "Promo code redeem succeed.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Failed",
          description: "Only one coupon can be redeem at a time.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const appearance = { theme: "stripe" };
  const options = { clientSecret, appearance };

  const createPaymentIntent = async () => {
    let currency = "MYR";
    if (state.login === "company") {
      let country = JSON.parse(state.company.company_address)[0].country;
      if (country !== undefined) {
        currency = listCurrency[country];
      }
    } else {
      let country = JSON.parse(state.participants.trainee_address)[0].country;
      if (country !== undefined) {
        currency = listCurrency[country];
      }
    }

    let finalFee = nationalityBasedFee * 100;
    if (state.discount || discount) {
      finalFee = (
        nationalityBasedFee *
        ((100 -
          (state.discount
            ? state.discount.disc_percent
            : discount.disc_percent)) /
          100)
      ).toFixed(2);
    }

    let billFee = finalFee * state.total_trainee;
    if (currency !== "MYR") {
      billFee = Math.round(billFee * convertRate[currency]);
    }
    setStripeFee(currency + " " + billFee / 100);

    const formData = new FormData();
    formData.append("currency", currency);
    formData.append("billFee", billFee);
    formData.append(
      "description",
      "Payment for " + state.training.training_name
    );

    let customerInfo = {
      name: "",
      email: "",
    };
    if (state.login === "individu") {
      customerInfo = {
        name: state.participants.trainee_name,
        email: state.participants.trainee_email,
      };
    } else if (state.login === "company") {
      customerInfo = {
        name: state.company.company_picname,
        email: state.company.company_picemail,
      };
    }
    formData.append("customer", JSON.stringify(customerInfo));

    const res = await CRUDFunction.create(
      `${URL_STAGING}stripe/intent/`,
      formData
    );
    if (res) {
      setClientSecret(res.clientSecret);
    }
  };

  return (
    <>
      <Helmet>
        <title>Training Registration</title>
      </Helmet>
      {
        <Grid
          mx={windowSize.current[0] > 500 ? "100px" : "0px"}
          my={windowSize.current[0] > 500 ? "0px" : "100px"}
          templateColumns="repeat(6, 1fr)"
          gap={3}
        >
          <GridItem colSpan={windowSize.current[0] > 500 ? "4" : "6"}>
            <Container maxW={"auto"} h={"auto"}>
              <Box
                minW="100%"
                style={{ boxShadow: "0px 2px 4px #c4c4c4" }}
                my="10"
              >
                <Center>
                  <Heading size={"xl"}>Checkout</Heading>
                </Center>
                {windowSize.current[0] > 500 && (
                  <Grid templateColumns={"repeat(7,1fr)"}>
                    <GridItem colSpan="3">
                      <form onSubmit={handleSubmit(onUpdateShippingDetails)}>
                        <Box p="5">
                          <Heading size="md" mb="8px">
                            Billing Details
                          </Heading>
                          <Text fontSize="sm" style={{ fontWeight: "700" }}>
                            PIC Name:
                          </Text>
                          <Box fontSize="18px" my="10px" pl="20px">
                            {state.login === "company"
                              ? state.company.company_picname
                              : state.participants.trainee_name}
                          </Box>
                          <Text fontSize="sm" style={{ fontWeight: "700" }}>
                            Company Name:
                          </Text>
                          <Box fontSize="18px" my="10px" pl="20px">
                            {state.login === "company"
                              ? state.company.company_name
                              : state.participants.trainee_position}
                          </Box>
                        </Box>
                        <Box pl="5">
                          <Heading size="md" mb="8px">
                            Shipping Details
                          </Heading>
                          <Text fontSize="sm" style={{ fontWeight: "700" }}>
                            Certificate Post Address:
                          </Text>
                          <Box fontSize="18px">
                            {state.login === "company" ? (
                              !isAddressExist ? (
                                <FormControl>
                                  <FormLabel mt="10px">
                                    <Text
                                      fontSize="sm"
                                      style={{ fontWeight: "700" }}
                                    >
                                      Address{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Text>
                                  </FormLabel>
                                  <Input
                                    fontSize="14px"
                                    placeholder={
                                      state.login === "company"
                                        ? "Enter company address"
                                        : "Enter address"
                                    }
                                    {...register("address")}
                                  ></Input>
                                </FormControl>
                              ) : JSON.parse(state.company.company_address)[0]
                                  .address === "" ? (
                                <>
                                  <Text
                                    fontSize="sm"
                                    my="10px"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Address:
                                  </Text>
                                  <Box fontSize="18px" my="10px" pl="20px">
                                    {address.address}
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Text
                                    fontSize="sm"
                                    my="10px"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Address:
                                  </Text>
                                  <Box fontSize="18px" my="10px" pl="20px">
                                    {
                                      JSON.parse(
                                        state.company.company_address
                                      )[0].address
                                    }
                                  </Box>
                                </>
                              )
                            ) : (
                              <>
                                <Text
                                  fontSize="sm"
                                  my="10px"
                                  style={{ fontWeight: "700" }}
                                >
                                  Address:
                                </Text>
                                <Box fontSize="14px" my="10px" pl="20px">
                                  {
                                    JSON.parse(
                                      state.participants.trainee_address
                                    )[0].address
                                  }
                                </Box>
                              </>
                            )}
                          </Box>
                          <Stack direction={"row"}>
                            <Box width="40%">
                              <Box fontSize="18px" my="10px">
                                {state.login === "company" ? (
                                  !isAddressExist ? (
                                    <FormControl>
                                      <FormLabel mt="10px">
                                        <Text
                                          fontSize="sm"
                                          style={{ fontWeight: "700" }}
                                        >
                                          Postcode{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </Text>
                                      </FormLabel>
                                      <Input
                                        fontSize="14px"
                                        placeholder={"Enter postcode"}
                                        {...register("postcode")}
                                      ></Input>
                                    </FormControl>
                                  ) : JSON.parse(
                                      state.company.company_address
                                    )[0].postcode === "" ? (
                                    <>
                                      <Text
                                        fontSize="sm"
                                        my="10px"
                                        style={{ fontWeight: "700" }}
                                      >
                                        Postcode
                                      </Text>
                                      <Box fontSize="18px" my="10px" pl="20px">
                                        {address.postcode}
                                      </Box>
                                    </>
                                  ) : (
                                    <>
                                      <Text
                                        fontSize="sm"
                                        my="10px"
                                        style={{ fontWeight: "700" }}
                                      >
                                        Postcode
                                      </Text>
                                      <Box fontSize="18px" my="10px" pl="20px">
                                        {JSON.parse(
                                          state.company.company_address
                                        )[0].postcode +
                                          ", " +
                                          JSON.parse(
                                            state.company.company_address
                                          )[0].city}
                                      </Box>
                                    </>
                                  )
                                ) : (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      Postcode
                                    </Text>
                                    <Box fontSize="14px" my="10px" pl="20px">
                                      {JSON.parse(
                                        state.participants.trainee_address
                                      )[0].postcode +
                                        ", " +
                                        JSON.parse(
                                          state.participants.trainee_address
                                        )[0].city}
                                    </Box>
                                  </>
                                )}
                              </Box>
                            </Box>

                            <Box width={"60%"}>
                              <Box fontSize="18px" my="10px">
                                {state.login === "company" ? (
                                  !isAddressExist ? (
                                    <FormControl>
                                      <FormLabel mt="10px">
                                        <Text
                                          fontSize="sm"
                                          style={{ fontWeight: "700" }}
                                        >
                                          State{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </Text>
                                      </FormLabel>
                                      <Input
                                        fontSize="14px"
                                        placeholder={"Enter state"}
                                        {...register("state")}
                                      ></Input>
                                    </FormControl>
                                  ) : JSON.parse(
                                      state.company.company_address
                                    )[0].state === "" ? (
                                    <>
                                      <Text
                                        fontSize="sm"
                                        my="10px"
                                        style={{ fontWeight: "700" }}
                                      >
                                        State
                                      </Text>
                                      <Box fontSize="18px" my="10px" pl="20px">
                                        {address.state}
                                      </Box>
                                    </>
                                  ) : (
                                    <>
                                      <Text
                                        fontSize="sm"
                                        my="10px"
                                        style={{ fontWeight: "700" }}
                                      >
                                        State
                                      </Text>
                                      <Box fontSize="18px" my="10px" pl="20px">
                                        {JSON.parse(
                                          state.company.company_address
                                        )[0].state !== "-" &&
                                          JSON.parse(
                                            state.company.company_address
                                          )[0].state}
                                      </Box>
                                    </>
                                  )
                                ) : (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      State
                                    </Text>
                                    <Box fontSize="14px" my="10px" pl="20px">
                                      {JSON.parse(
                                        state.participants.trainee_address
                                      )[0].state !== "-" &&
                                        JSON.parse(
                                          state.participants.trainee_address
                                        )[0].state}
                                    </Box>
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Stack>

                          <Box width={"60%"}>
                            <Box fontSize="18px" my="10px">
                              {state.login === "company" ? (
                                !isAddressExist ? (
                                  <FormControl>
                                    <FormLabel mt="10px">
                                      <Text
                                        fontSize="sm"
                                        style={{ fontWeight: "700" }}
                                      >
                                        Country{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </Text>
                                    </FormLabel>
                                    <Input
                                      fontSize="14px"
                                      placeholder={"Enter country"}
                                      {...register("country")}
                                    ></Input>
                                  </FormControl>
                                ) : JSON.parse(state.company.company_address)[0]
                                    .country === "" ? (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      Country
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {address.country}
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      Country
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {JSON.parse(
                                        state.company.company_address
                                      )[0].country === undefined
                                        ? "Malaysia"
                                        : JSON.parse(
                                            state.company.company_address
                                          )[0].country}
                                    </Box>
                                  </>
                                )
                              ) : (
                                <>
                                  <Text
                                    fontSize="sm"
                                    my="10px"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Country
                                  </Text>
                                  <Box fontSize="14px" my="10px" pl="20px">
                                    {JSON.parse(
                                      state.participants.trainee_address
                                    )[0].country === undefined
                                      ? "Malaysia"
                                      : JSON.parse(
                                          state.participants.trainee_address
                                        )[0].country}
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Box>

                          {state.login === "company" && state.isNation && (
                            <Box>
                              <Text fontWeight="bold">Promo code:</Text>
                              <Input
                                type="text"
                                w="50%"
                                mr="3"
                                ref={promo_code}
                              />
                              <Button
                                onClick={() => {
                                  promoCode(promo_code.current.value);
                                }}
                              >
                                Apply
                              </Button>
                            </Box>
                          )}
                          {!isAddressExist && state.login === "company" ? (
                            <Button
                              isDisabled={isAddressExist ? true : false}
                              width="100%"
                              bg="#33945f"
                              color="white"
                              _hover={{ background: "#f9c611", color: "white" }}
                              type="submit"
                            >
                              Update Shipping Details
                            </Button>
                          ) : (
                            ""
                          )}
                        </Box>
                      </form>
                    </GridItem>
                    <GridItem colSpan="4">
                      <Box p="5">
                        <Heading size="md" mb="8px">
                          Your Order
                        </Heading>
                        <Table variant="simple">
                          <Thead>
                            <Tr>
                              <Th>Product</Th>
                              <Th textAlign="center">Pax</Th>
                              <Th textAlign="center" w="140px">
                                Package
                              </Th>
                              <Th textAlign="center" w="140px">
                                Total
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td>
                                {course && course.course_name}
                                {", "}
                                <Text as="b">
                                  {state.training.training_name}
                                </Text>
                              </Td>
                              <Td isNumeric>{state.total_trainee}</Td>
                              <Td isNumeric>
                                <Stack direction="row">
                                  <div
                                    style={{
                                      textDecoration:
                                        (state.discount || discount) &&
                                        "line-through",
                                      fontSize:
                                        (state.discount || discount) && "14px",
                                    }}
                                  >
                                    {state.isNation
                                      ? `RM${nationalityBasedFee}`
                                      : `USD${nationalityBasedFee}`}
                                  </div>
                                  <div>
                                    {(state.discount || discount) &&
                                      "RM" +
                                        Math.round(
                                          nationalityBasedFee *
                                            ((100 -
                                              (state.discount
                                                ? state.discount.disc_percent
                                                : discount.disc_percent)) /
                                              100) *
                                            100
                                        ) /
                                          100}
                                  </div>
                                </Stack>
                              </Td>
                              <Td isNumeric>
                                {state.isNation ? "RM" : "USD"}
                                {state.discount
                                  ? Math.round(
                                      nationalityBasedFee *
                                        ((100 - state.discount.disc_percent) /
                                          100) *
                                        state.total_trainee
                                    )
                                  : discount
                                  ? Math.round(
                                      nationalityBasedFee *
                                        ((100 - discount.disc_percent) / 100) *
                                        state.total_trainee
                                    )
                                  : nationalityBasedFee * state.total_trainee}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>Course Materials</Td>
                              <Td textColor="#33945F">Free</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                        <RadioGroup
                          defaultValue={
                            state.login === "company" &&
                            state.isNation &&
                            state.training.training_hrdc !== "0"
                              ? state.course_id === 3 && state.total_trainee < 5
                                ? "HLSB"
                                : "hrdc"
                              : "HLSB"
                          }
                          onChange={(value) => {
                            setPayment(value);
                            // if (value === "stripe") {
                            //   clientSecret === "" && createPaymentIntent();
                            // }
                          }}
                        >
                          {state.login === "company" &&
                            state.isNation &&
                            state.training.training_hrdc !== "0" && (
                              <Box
                                bg={
                                  payment === "hrdc" ? "green.200" : "gray.200"
                                }
                                borderLeft="7px solid"
                                p="4"
                              >
                                <Stack direction="row">
                                  <Radio
                                    colorScheme={"green"}
                                    value="hrdc"
                                    isDisabled={
                                      state.login === "company" &&
                                      state.isNation &&
                                      state.course_id === 3 &&
                                      state.total_trainee < 5
                                    }
                                  >
                                    Claim via HRDCorp
                                  </Radio>
                                </Stack>
                              </Box>
                            )}
                          {state.isNation && payment === "hrdc" && (
                            <div class="animate__animated animate__fadeIn">
                              <Text p="3">
                                Payment method under Human Resources Development
                                Corporation. Please proceed with the place order
                                button, and our designated team will contact you
                                soon.
                              </Text>
                            </div>
                          )}
                          {state.login === "company" &&
                            state.isNation &&
                            state.course_id === 3 &&
                            state.total_trainee < 5 && (
                              <Text
                                color="gray"
                                textAlign="center"
                                fontSize="15px"
                              >
                                <b>Cannot claim via HRDCorp.</b>
                                <br />
                                Does not meet min requirements of 5 participants
                                per company.
                              </Text>
                            )}
                          <Box
                            bg={payment === "HLSB" ? "green.200" : "gray.200"}
                            borderLeft="7px solid"
                            p="4"
                          >
                            <Stack direction="row">
                              <Radio colorScheme={"green"} value="HLSB">
                                Direct pay with Holistics Lab
                              </Radio>
                            </Stack>
                          </Box>
                          {payment === "HLSB" && (
                            <div class="animate__animated animate__fadeIn">
                              <Text p="3">
                                Please proceed with the place order button, and
                                our designated team will contact you soon.
                              </Text>
                            </div>
                          )}
                          {/* ---------------------------------------------stripe---------------------------------------- */}
                          <Box
                            bg={payment === "stripe" ? "green.200" : "gray.200"}
                            borderLeft="7px solid"
                            p="4"
                          >
                            <Stack direction="row">
                              <Radio
                                //COMMENT OR/AND UNCOMMENT FOR TEST SECURE PAYMENT PURPOSE
                                isDisabled
                                colorScheme="green"
                                value="stripe"
                              >
                                Secure Pay (Coming Soon)
                              </Radio>
                            </Stack>
                          </Box>
                          {payment === "stripe" && (
                            <div class="animate_animated animate_fadeIn">
                              {clientSecret ? (
                                <>
                                  <Image
                                    mt="10px"
                                    src={paymentImage}
                                    width="100%"
                                    border="1px solid"
                                    borderRadius={"20px"}
                                  />
                                  <Text
                                    textAlign="justify"
                                    textJustify="interWord"
                                    p="3"
                                  >
                                    Your personal data will be used to process
                                    your order, support your experience
                                    throughout this website, and for other
                                    purposes described in our privacy policy.
                                  </Text>
                                  <Text px="3" fontWeight="bold">
                                    Please proceed with the place order button.
                                  </Text>
                                </>
                              ) : (
                                <Box p="3">
                                  <Center>
                                    <Spinner
                                      thickness="4px"
                                      speed="0.65s"
                                      emptyColor="gray.200"
                                      color="blue.500"
                                      size="xl"
                                    />
                                  </Center>
                                </Box>
                              )}
                            </div>
                          )}
                        </RadioGroup>
                        <br />
                        <Text color="red" display={!errorMsg && "none"}>
                          Captcha is required to complete before proceed to
                          place order.
                        </Text>
                        <ReCAPTCHA
                          sitekey={CAPTCHA_SITE_KEY}
                          ref={captchaRef}
                        />
                        <Button
                          //COMMENT OR/AND UNCOMMENT FOR TEST SECURE PAYMENT PURPOSE
                          isDisabled={payment === "stripe" ? true : false}
                          // isDisabled={!clientSecret}
                          width="100%"
                          mt="5"
                          bg="#33945f"
                          color="white"
                          _hover={{ background: "#f9c611", color: "white" }}
                          onClick={onPlaceOrder}
                        >
                          Place Order
                        </Button>

                        <br />
                      </Box>
                    </GridItem>
                  </Grid>
                )}
                {windowSize.current[0] < 500 && (
                  <VStack align="left">
                    <form onSubmit={handleSubmit(onUpdateShippingDetails)}>
                      <Box p="5">
                        <Heading size="md" mb="8px">
                          Billing Details
                        </Heading>
                        <Text fontSize="sm" style={{ fontWeight: "700" }}>
                          PIC Name:
                        </Text>
                        <Box fontSize="18px" my="10px" pl="20px">
                          {state.login === "company"
                            ? state.company.company_picname
                            : state.participants.trainee_name}
                        </Box>
                        <Text fontSize="sm" style={{ fontWeight: "700" }}>
                          Company Name:
                        </Text>
                        <Box fontSize="18px" my="10px" pl="20px">
                          {state.login === "company"
                            ? state.company.company_name
                            : state.participants.trainee_position}
                        </Box>
                      </Box>
                      <Box pl="5">
                        <Heading size="md" mb="8px">
                          Shipping Details
                        </Heading>
                        <Text fontSize="sm" style={{ fontWeight: "700" }}>
                          Certificate Post Address:
                        </Text>
                        <Box fontSize="18px">
                          {state.login === "company" ? (
                            !isAddressExist ? (
                              <FormControl>
                                <FormLabel mt="10px">
                                  <Text
                                    fontSize="sm"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Address{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Text>
                                </FormLabel>
                                <Input
                                  fontSize="14px"
                                  placeholder={
                                    state.login === "company"
                                      ? "Enter company address"
                                      : "Enter address"
                                  }
                                  {...register("address")}
                                ></Input>
                              </FormControl>
                            ) : JSON.parse(state.company.company_address)[0]
                                .address === "" ? (
                              <>
                                <Text
                                  fontSize="sm"
                                  my="10px"
                                  style={{ fontWeight: "700" }}
                                >
                                  Address:
                                </Text>
                                <Box fontSize="18px" my="10px" pl="20px">
                                  {address.address}
                                </Box>
                              </>
                            ) : (
                              <>
                                <Text
                                  fontSize="sm"
                                  my="10px"
                                  style={{ fontWeight: "700" }}
                                >
                                  Address:
                                </Text>
                                <Box fontSize="18px" my="10px" pl="20px">
                                  {
                                    JSON.parse(state.company.company_address)[0]
                                      .address
                                  }
                                </Box>
                              </>
                            )
                          ) : (
                            <>
                              <Text
                                fontSize="sm"
                                my="10px"
                                style={{ fontWeight: "700" }}
                              >
                                Address:
                              </Text>
                              <Box fontSize="14px" my="10px" pl="20px">
                                {
                                  JSON.parse(
                                    state.participants.trainee_address
                                  )[0].address
                                }
                              </Box>
                            </>
                          )}
                        </Box>
                        <Stack direction={"row"}>
                          <Box width="40%">
                            <Box fontSize="18px" my="10px">
                              {state.login === "company" ? (
                                !isAddressExist ? (
                                  <FormControl>
                                    <FormLabel mt="10px">
                                      <Text
                                        fontSize="sm"
                                        style={{ fontWeight: "700" }}
                                      >
                                        Postcode{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </Text>
                                    </FormLabel>
                                    <Input
                                      fontSize="14px"
                                      placeholder={"Enter postcode"}
                                      {...register("postcode")}
                                    ></Input>
                                  </FormControl>
                                ) : JSON.parse(state.company.company_address)[0]
                                    .postcode === "" ? (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      Postcode
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {address.postcode}
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      Postcode
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {
                                        JSON.parse(
                                          state.company.company_address
                                        )[0].postcode
                                      }
                                    </Box>
                                  </>
                                )
                              ) : (
                                <>
                                  <Text
                                    fontSize="sm"
                                    my="10px"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Postcode
                                  </Text>
                                  <Box fontSize="14px" my="10px" pl="20px">
                                    {
                                      JSON.parse(
                                        state.participants.trainee_address
                                      )[0].postcode
                                    }
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Box>
                          <Box width={"60%"}>
                            <Box fontSize="18px" my="10px">
                              {state.login === "company" ? (
                                !isAddressExist ? (
                                  <FormControl>
                                    <FormLabel mt="10px">
                                      <Text
                                        fontSize="sm"
                                        style={{ fontWeight: "700" }}
                                      >
                                        State{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </Text>
                                    </FormLabel>
                                    <Input
                                      fontSize="14px"
                                      placeholder={"Enter state"}
                                      {...register("state")}
                                    ></Input>
                                  </FormControl>
                                ) : JSON.parse(state.company.company_address)[0]
                                    .state === "" ? (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      State
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {address.state}
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      State
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {JSON.parse(
                                        state.company.company_address
                                      )[0].state !== "-" &&
                                        JSON.parse(
                                          state.company.company_address
                                        )[0].state}
                                    </Box>
                                  </>
                                )
                              ) : (
                                <>
                                  <Text
                                    fontSize="sm"
                                    my="10px"
                                    style={{ fontWeight: "700" }}
                                  >
                                    State
                                  </Text>
                                  <Box fontSize="14px" my="10px" pl="20px">
                                    {JSON.parse(
                                      state.participants.trainee_address
                                    )[0].state !== "-" &&
                                      JSON.parse(
                                        state.participants.trainee_address
                                      )[0].state}
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Stack>

                        <Box width={"60%"}>
                          <Box fontSize="18px" my="10px">
                            {state.login === "company" ? (
                              !isAddressExist ? (
                                <FormControl>
                                  <FormLabel mt="10px">
                                    <Text
                                      fontSize="sm"
                                      style={{ fontWeight: "700" }}
                                    >
                                      Country{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Text>
                                  </FormLabel>
                                  <Input
                                    fontSize="14px"
                                    placeholder={"Enter country"}
                                    {...register("country")}
                                  ></Input>
                                </FormControl>
                              ) : JSON.parse(state.company.company_address)[0]
                                  .country === "" ? (
                                <>
                                  <Text
                                    fontSize="sm"
                                    my="10px"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Country
                                  </Text>
                                  <Box fontSize="18px" my="10px" pl="20px">
                                    {address.country}
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Text
                                    fontSize="sm"
                                    my="10px"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Country
                                  </Text>
                                  <Box fontSize="18px" my="10px" pl="20px">
                                    {JSON.parse(
                                      state.company.company_address
                                    )[0].country === undefined
                                      ? "Malaysia"
                                      : JSON.parse(
                                          state.company.company_address
                                        )[0].country}
                                  </Box>
                                </>
                              )
                            ) : (
                              <>
                                <Text
                                  fontSize="sm"
                                  my="10px"
                                  style={{ fontWeight: "700" }}
                                >
                                  Country
                                </Text>
                                <Box fontSize="14px" my="10px" pl="20px">
                                  {JSON.parse(
                                    state.participants.trainee_address
                                  )[0].country === undefined
                                    ? "Malaysia"
                                    : JSON.parse(
                                        state.participants.trainee_address
                                      )[0].country}
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>

                        {state.login === "company" && state.isNation && (
                          <Box>
                            <Text fontWeight="bold">Promo code:</Text>
                            <Input
                              type="text"
                              w="50%"
                              mr="3"
                              ref={promo_code}
                            />
                            <Button
                              onClick={() => {
                                promoCode(promo_code.current.value);
                              }}
                            >
                              Apply
                            </Button>
                          </Box>
                        )}
                        {!isAddressExist && state.login === "company" ? (
                          <Button
                            isDisabled={isAddressExist ? true : false}
                            width="100%"
                            bg="#33945f"
                            color="white"
                            _hover={{
                              background: "#f9c611",
                              color: "white",
                            }}
                            type="submit"
                          >
                            Update Shipping Details
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>
                    </form>
                    <Box p="5" w="100%">
                      <Heading size="md" mb="8px">
                        Your Order
                      </Heading>

                      <Table variant="simple">
                        <Tr>
                          <Th>Product</Th>
                          <Td>
                            {course && course.course_name}
                            {", "}
                            <Text as="b">{state.training.training_name}</Text>
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>Pax</Th>
                          <Td isNumeric>{state.total_trainee}</Td>
                        </Tr>
                        <Tr>
                          <Th w="140px">Package</Th>
                          <Td isNumeric>
                            <Stack direction="row">
                              <div
                                style={{
                                  textDecoration:
                                    (state.discount || discount) &&
                                    "line-through",
                                  fontSize:
                                    (state.discount || discount) && "14px",
                                }}
                              >
                                {state.isNation
                                  ? `RM${nationalityBasedFee}`
                                  : `USD${nationalityBasedFee}`}
                              </div>
                              <div>
                                {(state.discount || discount) &&
                                  "RM" +
                                    Math.round(
                                      nationalityBasedFee *
                                        ((100 -
                                          (state.discount
                                            ? state.discount.disc_percent
                                            : discount.disc_percent)) /
                                          100) *
                                        100
                                    ) /
                                      100}
                              </div>
                            </Stack>
                          </Td>
                        </Tr>
                        <Tr>
                          <Th w="140px">Total</Th>
                          <Td isNumeric>
                            {state.isNation ? "RM" : "USD"}
                            {state.discount
                              ? Math.round(
                                  nationalityBasedFee *
                                    ((100 - state.discount.disc_percent) /
                                      100) *
                                    state.total_trainee
                                )
                              : discount
                              ? Math.round(
                                  nationalityBasedFee *
                                    ((100 - discount.disc_percent) / 100) *
                                    state.total_trainee
                                )
                              : nationalityBasedFee * state.total_trainee}
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>Course Materials</Th>
                          <Td textColor="#33945F">Free</Td>
                        </Tr>
                      </Table>
                      <RadioGroup
                        defaultValue={
                          state.login === "company" &&
                          state.isNation &&
                          state.training.training_hrdc !== "0"
                            ? state.course_id === 3 && state.total_trainee < 5
                              ? "HLSB"
                              : "hrdc"
                            : "HLSB"
                        }
                        onChange={(value) => {
                          setPayment(value);
                          // if (value === "stripe") {
                          //   clientSecret === "" && createPaymentIntent();
                          // }
                        }}
                      >
                        {state.login === "company" &&
                          state.isNation &&
                          state.training.training_hrdc !== "0" && (
                            <Box
                              bg={payment === "hrdc" ? "green.200" : "gray.200"}
                              borderLeft="7px solid"
                              p="4"
                            >
                              <Stack direction="row">
                                <Radio
                                  colorScheme={"green"}
                                  value="hrdc"
                                  isDisabled={
                                    state.login === "company" &&
                                    state.isNation &&
                                    state.course_id === 3 &&
                                    state.total_trainee < 5
                                  }
                                >
                                  Claim via HRDCorp
                                </Radio>
                              </Stack>
                            </Box>
                          )}
                        {payment === "hrdc" && (
                          <div class="animate__animated animate__fadeIn">
                            <Text p="3">
                              Payment method under Human Resources Development
                              Corporation. Please proceed with the place order
                              button, and our designated team will contact you
                              soon.
                            </Text>
                          </div>
                        )}
                        {state.login === "company" &&
                          state.isNation &&
                          state.course_id === 3 &&
                          state.total_trainee < 5 && (
                            <Text
                              px="10px"
                              color="gray"
                              textAlign="center"
                              fontSize="15px"
                            >
                              <b>Cannot claim via HRDCorp.</b> Does not meet min
                              requirements of 5 participants per company.
                            </Text>
                          )}
                        <Box
                          bg={payment === "HLSB" ? "green.200" : "gray.200"}
                          borderLeft="7px solid"
                          p="4"
                        >
                          <Stack direction="row">
                            <Radio colorScheme={"green"} value="HLSB">
                              Direct pay with Holistics Lab
                            </Radio>
                          </Stack>
                        </Box>
                        {payment === "HLSB" && (
                          <div class="animate__animated animate__fadeIn">
                            <Text p="3">
                              Please proceed with the place order button, and
                              our designated team will contact you soon.
                            </Text>
                          </div>
                        )}
                        {/* ---------------------------------------------stripe---------------------------------------- */}
                        <Box
                          bg={payment === "stripe" ? "green.200" : "gray.200"}
                          borderLeft="7px solid"
                          p="4"
                        >
                          <Stack direction="row">
                            <Radio
                              //COMMENT OR/AND UNCOMMENT FOR TEST SECURE PAYMENT PURPOSE
                              isDisabled
                              colorScheme="green"
                              value="stripe"
                            >
                              Secure Pay (Coming Soon)
                            </Radio>
                          </Stack>
                        </Box>
                        {payment === "stripe" && (
                          <div class="animate_animated animate_fadeIn">
                            {clientSecret ? (
                              <>
                                <Image
                                  mt="10px"
                                  src={paymentImage}
                                  width="100%"
                                  border="1px solid"
                                  borderRadius={"20px"}
                                />
                                <Text
                                  textAlign="justify"
                                  textJustify="interWord"
                                  p="3"
                                >
                                  Your personal data will be used to process
                                  your order, support your experience throughout
                                  this website, and for other purposes described
                                  in our privacy policy.
                                </Text>
                                <Text px="3" fontWeight="bold">
                                  Please proceed with the place order button.
                                </Text>
                              </>
                            ) : (
                              <Box p="3">
                                <Center>
                                  <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                  />
                                </Center>
                              </Box>
                            )}
                          </div>
                        )}
                      </RadioGroup>
                      <br />
                      <Text color="red" display={!errorMsg && "none"}>
                        Captcha is required to complete before proceed to place
                        order.
                      </Text>
                      <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} ref={captchaRef} />
                      <Button
                        //COMMENT OR/AND UNCOMMENT FOR TEST SECURE PAYMENT PURPOSE
                        isDisabled={payment === "stripe" ? true : false}
                        // isDisabled={!clientSecret}
                        width="100%"
                        mt="5"
                        bg="#33945f"
                        color="white"
                        _hover={{ background: "#f9c611", color: "white" }}
                        onClick={onPlaceOrder}
                      >
                        Place Order
                      </Button>

                      <br />
                    </Box>
                  </VStack>
                )}
              </Box>
              <br />
            </Container>
          </GridItem>
          {windowSize.current[0] > 500 && (
            <GridItem colSpan={2}>
              <Container maxW={"auto"} maxH={"auto"}>
                <Box
                  display="grid"
                  p="10px 20px 10px 20px"
                  borderRadius="10px"
                  boxShadow="xl"
                >
                  <br />
                  <Heading size="lg" align="center">
                    Training Details
                  </Heading>
                  <br />

                  <Divider
                    orientation="horizontal"
                    border="5px solid"
                    borderColor="#33945f"
                    mb="5"
                  />
                  <div style={{ padding: "1px 3px" }}>
                    <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                      <b>COURSE</b>
                      <Text fontSize="14px" py="10px">
                        {course && course.course_name}
                      </Text>
                    </Box>
                    <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                      <b>TRAINING NAME</b>
                      <Text fontSize="14px" py="10px">
                        {state.training.training_name}
                      </Text>
                    </Box>
                    <Box
                      borderBottomWidth="2px"
                      borderColor="gray"
                      mb="10"
                      pb="10px"
                    >
                      <b>TRAINING DATE</b>
                      {JSON.parse(state.training.training_date).map(
                        (item, index) => (
                          <>
                            {item.endDate === item.startDate ? (
                              <Text fontSize="14px">
                                {format(new Date(item.startDate), "dd LLLL y")}
                              </Text>
                            ) : (
                              <Text fontSize="14px">
                                Week {index + 1}:{" "}
                                {format(new Date(item.startDate), "dd LLLL y")}{" "}
                                - {format(new Date(item.endDate), "dd LLLL y")}
                              </Text>
                            )}
                          </>
                        )
                      )}
                    </Box>
                    <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                      <b>TRAINING PLACE</b>
                      <Text fontSize="14px" py="10px">
                        {state.training.training_place}
                      </Text>
                    </Box>
                    <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                      <b>TRAINING MODE</b>
                      <Text fontSize="14px" py="10px">
                        {displayModes[state.training.training_mode]}
                      </Text>
                    </Box>
                    <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                      <b>TRAINING DETAILS</b>
                      <Text fontSize="14px" py="10px">
                        {state.training.training_detail !== "undefined" ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: state.training.training_detail,
                            }}
                          />
                        ) : (
                          "-"
                        )}
                      </Text>
                    </Box>
                    <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                      <b>TRAINING FEE</b>
                      <Text fontSize="14px" py="10px">
                        {state.isNation ? "RM" : "USD"} {nationalityBasedFee}
                      </Text>
                    </Box>
                    <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                      <b>HRDC CLAIMABLE</b>&nbsp;
                      {state.training.training_hrdc &&
                      JSON.parse(state.training.training_hrdc)[0] ? (
                        JSON.parse(state.training.training_hrdc)[0].hrdc ===
                        1 ? (
                          <Icon as={BsCheckCircleFill} color="green" />
                        ) : (
                          <Icon as={BsXCircleFill} color="red" />
                        )
                      ) : parseInt(state.training.training_hrdc) === 1 ? (
                        <Icon as={BsCheckCircleFill} color="green" />
                      ) : (
                        <Icon as={BsXCircleFill} color="red" />
                      )}
                    </Box>
                  </div>
                </Box>
              </Container>
            </GridItem>
          )}
        </Grid>
      }

      {/* -----------------------------------------------------loading modal------------------------------------------------- */}
      <Modal size="xs" isOpen={loading.isOpen} onClose={loading.onClose}>
        <ModalOverlay />
        <ModalContent my="auto">
          <ModalBody>
            <Center>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* -----------------------------------------------------stripe modal------------------------------------------------- */}
      <Modal
        size="xl"
        isOpen={stripeModal.isOpen}
        onClose={stripeModal.onClose}
      >
        <ModalOverlay />
        <ModalContent my="auto">
          <ModalHeader ml="3">Secure Payment</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Box p="3">
              <Box bgColor="cyan.200" borderRadius="5px">
                <Text textAlign="center" mb="10px">
                  <b>Payable Amount:</b> {stripeFee}
                </Text>
              </Box>
              <Elements stripe={stripePromise} options={options}>
                <StripeGateway amount={stripeFee} />
              </Elements>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function StripeGateway({ stripeFee }) {
  const stripe = useStripe();
  const elements = useElements();

  // ----------------------------------------------------handle stripe----------------------------------------------------
  const handleStripe = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/client/payment",
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  // ----------------------------------------------------handle stripe----------------------------------------------------
  return (
    <div>
      <form onSubmit={handleStripe}>
        <PaymentElement
          id="payment-element"
          options={{
            layout: {
              type: "accordion",
              defaultCollapsed: true,
              radios: true,
              spacedAccordionItems: true,
            },
          }}
        />
        <Center>
          <Button my="10px" type="submit" colorScheme="teal" variant="outline">
            Pay {stripeFee}
          </Button>
        </Center>
      </form>
    </div>
  );
}

export { Checkout };

import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Grid,
  GridItem,
  Divider,
  InputLeftElement,
  useToast,
  Image,
  Icon,
} from "@chakra-ui/react";
import { FiLock } from "react-icons/fi";
import { RiAddFill, RiDeleteBinLine } from "react-icons/ri";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import { format } from "date-fns";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { Helmet } from "react-helmet";
import { findPostcode, getStates } from "malaysia-postcodes";

const Company = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [trainingType, setTrainingType] = useState("online");
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...participant];
    list[index][name] = value;
    setParticipant(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...participant];
    list.splice(index, 1);
    setParticipant(list);
  };

  const handleAddClick = () => {
    if (trainingDetails.length === 0) {
      toast({
        title: "No training selected",
        description: "Select a training first.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      let initAmount = 0;
      trainingDetails.trainees.map((data) => {
        if (data.pivot.join_status !== "3") {
          initAmount++;
        }
      });
      let addedTrainee = participant.length - 1;
      let currentTotalTrainee = initAmount + addedTrainee;
      if (typeof JSON.parse(trainingDetails.training_hrdc) === "object") {
        if (
          JSON.parse(trainingDetails.training_hrdc)[0].pax === "" ||
          currentTotalTrainee <
            parseFloat(JSON.parse(trainingDetails.training_hrdc)[0].pax)
        ) {
          addParticipantModal.onOpen();
        } else {
          toast({
            title: "Maximum training capacity reached.",
            description: "Sorry you can no longer add more participants.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    }
    // setParticipant([
    //   ...participant,
    //   { name: "", ic: "", email: "", phoneNo: "" },
    // ]);
  };

  const handleChange = (event) => {
    console.log(event.target.value);
  };

  const handleNewCompany = (event) => {
    setCompanySSM(event.target.value);
  };

  let navigate = useNavigate();

  const { state } = useLocation();
  const onlineModal = useDisclosure();
  const physicalModal = useDisclosure();
  const companyModal = useDisclosure();
  const addParticipantModal = useDisclosure();
  const remindHrdc = useDisclosure();
  const [radioId, setRadioId] = useState();
  const [companySSM, setCompanySSM] = useState("");
  const [trainingDetails, setTrainingDetails] = useState([]);
  const [date, setDate] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [companyLogin, setCompanyLogin] = useState({
    company_id: "",
    company_image: "",
    company_name: "",
    company_ssm: "",
    company_address: "",
    company_picname: "",
    company_picphone: "",
    company_picic: "",
    company_picemail: "",
    company_picposition: "",
  });
  const [selectCompany, setSelectCompany] = useState([]);
  const [course, setCourse] = useState([
    {
      course_about: "",
      course_eligibility: "",
      course_id: "",
      course_image: "",
      course_name: "",
      course_outline: "",
      trainings: [],
    },
  ]);
  const [company, setCompany] = useState();
  const trainingModes = [
    "Fulltime online inhouse",
    "Fulltime physical inhouse",
    "Parttime online inhouse",
    "Parttime physical inhouse",
    "Fulltime online public",
    "Fulltime physical public",
    "Parttime online public",
    "Parttime physical public",
  ];
  const displayModes = [
    "Full Time Online Inhouse",
    "Full Time Physical Inhouse",
    "Part Time Online Inhouse",
    "Part Time Physical Inhouse",
    "Full Time Online",
    "Full Time Physical",
    "Part Time Online",
    "Part Time Physical",
  ];
  const [participant, setParticipant] = useState([
    {
      trainee_name: "",
      trainee_ic: "",
      trainee_email: "",
      trainee_phone: "",
      trainee_position: "",
      trainee_allergies: "",
    },
  ]);
  const [enrollid, setEnrollId] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [isNation, setNation] = useState(true);
  const countryCodes = require("country-codes-list");
  const [countryCode, setCountryCode] = useState("");
  const [countries, setCountries] = useState([]);
  const [MYStates, setMYStates] = useState();
  const [openToAll, setAll] = useState(false);
  const [trainingFull, setTrainingFull] = useState(false);
  const [schemeList, setSchemeList] = useState([]);

  const toast = useToast();

  useEffect(() => {
    fetchCourse();
    fetchCountry();
  }, []);

  if (
    sessionStorage.getItem("isRefreshed") === 0 ||
    !sessionStorage.getItem("isRefreshed")
  ) {
    sessionStorage.setItem("isRefreshed", true);
    window.location.reload();
  }

  const fetchCourse = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}coursedetails/${state.id}`
    );
    if (res) {
      let trainings = [];
      if (res[0].training_id !== null) {
        res.map(
          (i) =>
            new Date(JSON.parse(i.training_date)[0].startDate).getTime() >
              new Date().getTime() &&
            trainings.push({
              training_id: i.training_id,
              training_name: i.training_name,
              training_date: JSON.parse(i.training_date),
              training_detail: i.training_detail,
              training_fee: i.training_fee,
              training_place: i.training_place,
              training_mode: i.training_mode,
              training_hrdc: i.training_hrdc,
              training_status: i.training_status,
            })
        );
      }
      setCourse({
        course_about: res[0].course_about,
        course_eligibility: res[0].course_eligibility,
        course_id: res[0].course_id,
        course_image: res[0].course_image,
        course_name: res[0].course_name.toUpperCase(),
        course_outline: res[0].course_outline,
        trainings: trainings,
      });
      setAll(checkEligibility(trainings));
    }
  };

  const fetchCountry = async () => {
    let listName = countryCodes.customList("countryNameEn", "{countryCode}");
    let listCode = countryCodes.customList(
      "countryNameEn",
      "+{countryCallingCode}"
    );
    let key = Object.keys(listName);
    let name = Object.values(listName);
    let code = Object.values(listCode);
    let tempList = [];
    for (let i = 0; i < key.length; i++) {
      tempList.push({
        value: key[i],
        label: key[i],
        name: name[i],
        code: code[i],
      });
    }
    setCountries(tempList);
    setMYStates(getStates);
  };

  const checkEligibility = (trainings) => {
    for (let i = 0; i < trainings.length; i++) {
      if (JSON.parse(trainings[i].training_fee)[0]) {
        return true;
      }
    }
    return false;
  };

  const modeSplitter = (mode) => {
    return mode.split(" ");
  };

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm();

  const modalSessionSubmit = async (data) => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}training/${data.trainingId}`
    );
    if (res) {
      var joinTrainees = 0;
      res.trainees.map((data) => {
        if (data.pivot.join_status !== "3") {
          joinTrainees++;
        }
      });
      if (typeof JSON.parse(res.training_hrdc) === "object") {
        if (joinTrainees >= parseFloat(JSON.parse(res.training_hrdc)[0].pax)) {
          setTrainingFull(true);
          toast({
            title: "Failed",
            description:
              "We are sorry, this training is full. Kindly choose other training date.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else {
          setTrainingFull(false);
          setTrainingDetails(res);
          setEnrollId(data.trainingId);
          setDate(JSON.parse(res.training_date));
          onlineModal.isOpen ? onlineModal.onClose() : physicalModal.onClose();
        }
      } else {
        setTrainingFull(false);
        setTrainingDetails(res);
        setEnrollId(data.trainingId);
        setDate(JSON.parse(res.training_date));
        onlineModal.isOpen ? onlineModal.onClose() : physicalModal.onClose();
      }
    }
  };

  const verifyCompany = async (data) => {
    if (data.company !== "") {
      const formData = new FormData();
      formData.append("company", data.company);
      const res = await CRUDFunction.create(
        `${URL_STAGING}verifycompany`,
        formData
      );

      if (res) {
        setCountryCode(
          checkCountryCode(JSON.parse(res.company_address)[0].country)
        );
        setNation(checkNation(JSON.parse(res.company_address)[0].country));
        if (checkNation(JSON.parse(res.company_address)[0].country)) {
          setCompanyLogin(res);
          setAuthenticated(true);
          course.course_id === 3 && remindHrdc.onOpen();
        } else {
          if (openToAll) {
            setCompanyLogin(res);
            setAuthenticated(true);
            course.course_id === 3 && remindHrdc.onOpen();
          } else {
            toast({
              title:
                "This course is currently close for international company.",
              description: "Kindly contact us for further information.",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        }
      } else {
        toast({
          title: "Account not exist.",
          description:
            "We can't find your account. Please re-enter your company SSM or register new company.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "No input.",
        description: "Please enter your SSM number",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const checkPostcodes = (e) => {
    if (e.target.value !== null && e.target.value.length === 5) {
      var location = findPostcode(e.target.value);
      if (location.found === true) {
        resetField("companyFormCity", {
          defaultValue: location.city,
        });
        resetField("companyFormState", {
          defaultValue: location.state,
        });
        resetField("companyFormCountry", {
          defaultValue: "Malaysia",
        });
        setNation(true);
      } else {
        resetField("companyFormCity", {
          defaultValue: "",
        });
        resetField("companyFormState", {
          defaultValue: "",
        });
        setNation(false);
      }
    } else {
      resetField("companyFormCity", {
        defaultValue: "",
      });
      resetField("companyFormState", {
        defaultValue: "",
      });
      resetField("companyFormCountry", {
        defaultValue: "",
      });
      setNation(false);
    }
  };

  const checkNation = (input) => {
    if (input === "Malaysia" || input === undefined) {
      return true;
    } else {
      return false;
    }
  };

  const checkCountryCode = (input) => {
    for (let i = 0; i < countries.length; i++) {
      if (input === countries[i].label) {
        return countries[i].code;
      }
    }
  };

  const checkState = (e) => {
    for (let i = 0; i < MYStates.length; i++) {
      if (e.target.value === MYStates[i]) {
        setNation(true);
        resetField("companyFormCountry", {
          defaultValue: "Malaysia",
        });
        return;
      }
    }
    setNation(false);
    return;
  };

  const convertToCaps = (e) => {
    let capsName = e.target.value.toUpperCase();
    resetField("companyFormName", {
      defaultValue: capsName,
    });
  };

  const companyModalSubmit = async (data) => {
    if (data && schemeList.length > 0) {
      const formData = new FormData();
      formData.append("company_name", data.companyFormName);
      formData.append("company_ssm", companySSM);
      let combineAddress = [
        {
          address: data.companyFormAddress,
          postcode: data.companyFormPostcode,
          city: data.companyFormCity,
          state: data.companyFormState,
          country: data.companyFormCountry,
        },
      ];
      formData.append("company_address", JSON.stringify(combineAddress));
      formData.append("company_scheme", JSON.stringify(schemeList));
      formData.append("company_picname", data.companyFormPicName);
      formData.append(
        "company_picphone",
        data.companyFormPicCodePhone + data.companyFormPicPhone
      );
      formData.append("company_picic", data.companyFormPicIC);
      formData.append("company_picemail", data.companyFormPicEmail);
      formData.append("company_picposition", data.companyFormPicPosition);
      if (data.companyFormImage.length !== 0) {
        formData.append("company_image", data.companyFormImage[0]);
      }
      if (data.companyFormFinanceEmail) {
        formData.append("company_finance_email", data.companyFormFinanceEmail);
      }

      const res = await CRUDFunction.create(`${URL_STAGING}company`, formData);
      if (res) {
        toast({
          title: "Company successfully added.",
          description: "Please login to continue registering.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // setCompanyLogin({
        //   company_name: data.companyFormName,
        //   company_ssm: companySSM,
        //   company_address: JSON.stringify(combineAddress),
        //   company_picname: data.companyFormPicName,
        //   company_picphone: data.companyFormPicPhone,
        //   company_picic: data.companyFormPicIC,
        //   company_picemail: data.companyFormPicEmail,
        //   company_picposition: data.companyFormPicPosition,
        //   company_image: data.companyFormImage[0],
        //   company_finance_email: data.companyFormFinanceEmail,
        // });
        // setAuthenticated(true);
        reset();
        companyModal.onClose();
        setTabIndex(0);
      }
    } else {
      toast({
        title: "Fill all required fields.",
        description: "Try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const modalAddParticipantSubmit = async (data) => {
    if (data) {
      let finalTraineeIC = data.ic;
      if (isNation) {
        finalTraineeIC = data.ic.replace(/-/g, "");
      }
      if (trainingType === "online") {
        setParticipant([
          ...participant,
          {
            trainee_name: data.name,
            trainee_ic: finalTraineeIC,
            trainee_email: data.email,
            trainee_phone: data.codePhone + data.phoneNo,
            trainee_position: data.position,
            trainee_allergies: null,
          },
        ]);
      } else if (trainingType === "physical") {
        setParticipant([
          ...participant,
          {
            trainee_name: data.name,
            trainee_ic: finalTraineeIC,
            trainee_email: data.email,
            trainee_phone: data.codePhone + data.phoneNo,
            trainee_position: data.position,
            trainee_allergies: data.allergies,
          },
        ]);
      }
      reset();
      addParticipantModal.onClose();
    }
  };

  const companyRegisterTraining = async (data) => {
    if (data) {
      if (enrollid !== 0) {
        // const res = await CRUDFunction.create(
        //   `${URL_STAGING}traineeCompany/${trainingDetails.training_id}`,
        //   formData
        // );
        if (participant.length > 1) {
          reset();
          setDate([]);
          navigate("../checkout", {
            replace: false,
            state: {
              isNation: isNation,
              company: companyLogin,
              participants: participant,
              training: trainingDetails,
              course_id: state.id,
              total_trainee: participant.length - 1,
              training_id: trainingDetails.training_id,
              login: "company",
            },
          });
        } else {
          toast({
            title: "No participant information.",
            description: "Please insert information required.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Training not selected.",
          description: "Please select training",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Training Registration</title>
      </Helmet>
      {/* <Grid mx="100px" templateColumns="repeat(6, 1fr)" gap={3}> */}
      <Grid
        mx={windowSize.current[0] > 500 ? "100px" : "0px"}
        my={windowSize.current[0] > 500 ? "0px" : "70px"}
        templateColumns="repeat(6, 1fr)"
        gap={3}
      >
        {/* <GridItem colSpan={4}> */}
        <GridItem colSpan={windowSize.current[0] > 500 ? "4" : "6"}>
          <Container maxW={"auto"} maxH={"auto"} height="100%">
            <Box
              p="10px 20px 10px 20px"
              borderRadius="10px"
              boxShadow="xl"
              height="100%"
              // p="30px 70px 20px 70px"
            >
              {!authenticated && (
                <>
                  <br />
                  <Heading size="3xl" align="center">
                    Registration
                  </Heading>
                  <br />
                  <Heading size="md" align="center" pl={10} pr={10}>
                    {course.course_name}
                  </Heading>
                  <br />
                  <Container>
                    <Tabs index={tabIndex} onChange={handleTabsChange}>
                      <TabList>
                        <Tab width={"50%"}>Existing</Tab>
                        <Tab width={"50%"}>New</Tab>
                      </TabList>
                      <TabPanels
                        style={{
                          background: "white",
                          boxShadow: "0px 46px 130px rgba(0, 0, 0, 0.15)",
                          borderRadius: "8px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <TabPanel>
                          <form onSubmit={handleSubmit(verifyCompany)}>
                            <Text fontSize="md" textAlign={"center"} mb="8px">
                              <b>Existing Company</b>
                            </Text>
                            <br />
                            <Text mb="8px">
                              SSM NUMBER / COMPANY REGISTRATION NO.
                            </Text>
                            <InputGroup>
                              <InputLeftElement>
                                <InputLeftElement
                                  pointerEvents="none"
                                  children={<FiLock color="gray.300" />}
                                />
                              </InputLeftElement>
                              <Input
                                style={{
                                  background: "#FCFDFE",
                                  border: "1px solid #F0F1F7",
                                  borderRadius: "8px",
                                  height: "40px",
                                }}
                                {...register("company")}
                                placeholder="ABCD-Y"
                                size="sm"
                              />
                            </InputGroup>
                            <br />
                            <br />
                            <Button
                              background="#33945F"
                              color="white"
                              width="100%"
                              type="submit"
                            >
                              Login
                            </Button>
                          </form>
                        </TabPanel>
                        <TabPanel>
                          <Text fontSize="md" textAlign={"center"} mb="8px">
                            <b>Create a new company</b>
                          </Text>
                          <br />
                          <Text>SSM NUMBER / COMPANY REGISTRATION NO.</Text>
                          <InputGroup mt="8px">
                            <InputLeftElement>
                              <InputLeftElement
                                pointerEvents="none"
                                children={<FiLock color="gray.300" />}
                              />
                            </InputLeftElement>
                            <Input
                              style={{
                                background: "#FCFDFE",
                                border: "1px solid #F0F1F7",
                                borderRadius: "8px",
                                height: "40px",
                              }}
                              onChange={handleNewCompany}
                              placeholder="ABCD-Y"
                              size="sm"
                            />
                          </InputGroup>
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              fontStyle: "italic",
                            }}
                          >
                            *Reminder: SSM Number will be used to login. Please
                            make sure the information is entered correctly.
                          </span>
                          <br />
                          <br />
                          <Button
                            background="#33945F"
                            color="white"
                            width="100%"
                            onClick={async () => {
                              if (companySSM !== "") {
                                const res = await CRUDFunction.create(
                                  `${URL_STAGING}verifycompany`,
                                  { company: companySSM }
                                );
                                if (!res) {
                                  companyModal.onOpen();
                                } else {
                                  toast({
                                    title: "Account already exist.",
                                    description:
                                      "Please re-enter your company ssm.",
                                    status: "error",
                                    duration: 9000,
                                    isClosable: true,
                                  });
                                }
                              } else {
                                toast({
                                  title: "No company SSM entered.",
                                  description:
                                    "Please re-enter your company ssm.",
                                  status: "error",
                                  duration: 9000,
                                  isClosable: true,
                                });
                              }
                            }}
                          >
                            Create
                          </Button>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Container>
                </>
              )}
              {authenticated && (
                <>
                  <br />
                  <Heading size="3xl" align="center">
                    Registration
                  </Heading>
                  <br />
                  <Heading size="md" align="center" pl={10} pr={10}>
                    {course.course_name}
                  </Heading>
                  <br />
                  <Box
                    p={
                      windowSize.current[0] > 500
                        ? "20px 70px"
                        : "10px 5px 50px 5px"
                    }
                  >
                    {/* <div style={{ padding: "20px 70px", height: "100%" }}> */}
                    <form
                      onSubmit={handleSubmit(companyRegisterTraining)}
                      style={{ height: "100%" }}
                    >
                      <div style={{ height: "90%", position: "relative" }}>
                        <FormControl isRequired>
                          <FormLabel
                            fontSize="13px"
                            mt="10px"
                            fontWeight="bold"
                          >
                            TRAINING SESSION
                          </FormLabel>
                          <InputGroup size="md" mb="8px">
                            <Input
                              fontSize="14px"
                              disabled
                              value={trainingDetails.training_name}
                              placeholder="Select session >"
                              style={{ opacity: "1", cursor: "default" }}
                            />
                            {windowSize.current[0] < 500 && (
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  w="260px"
                                  borderLeftRadius="0px"
                                  borderRightRadius="10px"
                                  bgColor="white"
                                  borderWidth="2px"
                                  borderColor="black"
                                  letterSpacing={1}
                                  fontSize="14px"
                                  whiteSpace="normal"
                                >
                                  Select training session
                                </MenuButton>
                                <MenuList>
                                  <MenuItem
                                    pl="30px"
                                    fontSize="15px"
                                    onClick={() => {
                                      setTrainingType("online");
                                      onlineModal.onOpen();
                                    }}
                                  >
                                    Online
                                  </MenuItem>
                                  <MenuItem
                                    pl="30px"
                                    fontSize="15px"
                                    onClick={() => {
                                      setTrainingType("physical");
                                      physicalModal.onOpen();
                                    }}
                                  >
                                    Physical
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            )}
                            {windowSize.current[0] > 500 && (
                              <InputRightElement width="auto">
                                <Menu>
                                  <MenuButton
                                    as={Button}
                                    w="260px"
                                    borderLeftRadius="0px"
                                    borderRightRadius="10px"
                                    bgColor="white"
                                    borderWidth="2px"
                                    borderColor="black"
                                    letterSpacing={1}
                                    fontSize="14px"
                                  >
                                    Select training session
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      pl="30px"
                                      fontSize="15px"
                                      onClick={() => {
                                        setTrainingType("online");
                                        onlineModal.onOpen();
                                      }}
                                    >
                                      Online
                                    </MenuItem>
                                    <MenuItem
                                      pl="30px"
                                      fontSize="15px"
                                      onClick={() => {
                                        setTrainingType("physical");
                                        physicalModal.onOpen();
                                      }}
                                    >
                                      Physical
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </InputRightElement>
                            )}
                          </InputGroup>
                          <HStack mt="10px">
                            <Text fontSize="14px">Company's Name:</Text>
                            <Text fontSize="14px">
                              {companyLogin.company_name}
                            </Text>
                          </HStack>
                          <HStack mt="10px">
                            <Text fontSize="14px">Person in Charge (PIC):</Text>
                            <Text fontSize="14px">
                              {companyLogin.company_picname}
                            </Text>
                          </HStack>
                        </FormControl>
                        <br />
                        <FormControl>
                          <HStack>
                            <FormLabel
                              fontSize="13px"
                              mt="10px"
                              fontWeight="bold"
                            >
                              PARTICIPANT
                            </FormLabel>
                            <IconButton
                              size="xs"
                              icon={<RiAddFill />}
                              onClick={handleAddClick}
                            />
                            <Text fontStyle="italic" fontSize="15px">
                              No. of participant: {participant.length - 1}
                            </Text>
                          </HStack>
                          <Box
                            mb="20px"
                            overflowY="scroll"
                            height={trainingDetails ? "35em" : "30em"}
                          >
                            <VStack align="left">
                              <VStack align="left">
                                {participant.map((x, i) => {
                                  if (i > 0) {
                                    return (
                                      <>
                                        <HStack
                                          width="100%"
                                          borderBottom={"1px solid gray"}
                                          paddingY="10px"
                                          _hover={{
                                            backgroundColor: "gray.100",
                                            color: "black",
                                          }}
                                        >
                                          <Text
                                            fontStyle="italic"
                                            fontSize="15px"
                                            mb="auto"
                                          >
                                            {i}
                                          </Text>
                                          {windowSize.current[0] > 500 && (
                                            <HStack w="100%">
                                              <VStack
                                                width="50%"
                                                align={"left"}
                                              >
                                                <Text>
                                                  Name: <b>{x.trainee_name}</b>
                                                </Text>
                                                <Text>
                                                  IC: <b>{x.trainee_ic}</b>
                                                </Text>
                                                <Text>
                                                  Email:{" "}
                                                  <b>{x.trainee_email}</b>
                                                </Text>
                                              </VStack>
                                              <VStack
                                                width="50%"
                                                align={"left"}
                                              >
                                                <Text>
                                                  Phone No.:{" "}
                                                  <b>{x.trainee_phone}</b>
                                                </Text>
                                                <Text>
                                                  Position:{" "}
                                                  <b>{x.trainee_position}</b>
                                                </Text>
                                                {trainingType ===
                                                  "physical" && (
                                                  <Text>
                                                    Dietary / Allergies:{" "}
                                                    <b>{x.trainee_allergies}</b>
                                                  </Text>
                                                )}
                                              </VStack>
                                            </HStack>
                                          )}
                                          {windowSize.current[0] < 500 && (
                                            <VStack width="100%" align={"left"}>
                                              <Text>
                                                Name: <b>{x.trainee_name}</b>
                                              </Text>
                                              <Text>
                                                IC: <b>{x.trainee_ic}</b>
                                              </Text>
                                              <Text>
                                                Email: <b>{x.trainee_email}</b>
                                              </Text>
                                              <Text>
                                                Phone No.:{" "}
                                                <b>{x.trainee_phone}</b>
                                              </Text>
                                              <Text>
                                                Position:{" "}
                                                <b>{x.trainee_position}</b>
                                              </Text>
                                              {trainingType === "physical" && (
                                                <Text>
                                                  Dietary / Allergies:{" "}
                                                  <b>{x.trainee_allergies}</b>
                                                </Text>
                                              )}
                                            </VStack>
                                          )}
                                          <div>
                                            <HStack>
                                              {participant.length !== 0 && (
                                                <IconButton
                                                  icon={<RiDeleteBinLine />}
                                                  variant="ghost"
                                                  color="red"
                                                  onClick={() =>
                                                    handleRemoveClick(i)
                                                  }
                                                />
                                              )}
                                            </HStack>
                                          </div>
                                        </HStack>
                                      </>
                                    );
                                  }
                                })}
                              </VStack>
                            </VStack>
                          </Box>
                        </FormControl>
                      </div>
                      <div style={{ height: "10%", position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                          }}
                        >
                          <Button
                            isDisabled={trainingFull}
                            type="submit"
                            w="49%"
                            p="7px"
                            fontWeight="bold"
                            color="white"
                            borderRadius="10px"
                            bgColor="#33945f"
                            _hover={{
                              backgroundColor: "white",
                              color: "#33945f",
                              border: "1px solid #33945f",
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            marginLeft="5px"
                            type="submit"
                            w="49%"
                            p="7px"
                            fontWeight="bold"
                            borderRadius="10px"
                            color="#33945f"
                            border={"1px solid #33945f"}
                            bgColor="white"
                            _hover={{
                              backgroundColor: "#33945f",
                              color: "white",
                              border: "1px solid white",
                            }}
                            onClick={() => {
                              setTrainingDetails([]);
                              setDate([]);
                              setAuthenticated(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </form>
                    {/* </div> */}
                  </Box>
                </>
              )}
            </Box>
          </Container>
        </GridItem>
        {windowSize.current[0] > 500 && (
          <GridItem colSpan={2}>
            <Container maxW={"auto"} maxH={"auto"}>
              <Box
                display="grid"
                p="10px 20px 10px 20px"
                borderRadius="10px"
                boxShadow="xl"
              >
                <br />
                <Heading size="lg" align="center">
                  Training Details
                </Heading>
                <br />

                <Divider
                  orientation="horizontal"
                  border="5px solid"
                  borderColor="#33945f"
                  mb="5"
                />
                <div style={{ padding: "1px 3px" }}>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>COURSE</b>
                    <Text fontSize="14px" py="10px">
                      {course.course_name}
                    </Text>
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING NAME</b>
                    <Text fontSize="14px" py="10px">
                      {trainingDetails.training_name}
                    </Text>
                  </Box>
                  <Box
                    borderBottomWidth="2px"
                    borderColor="gray"
                    mb="10"
                    pb="10px"
                  >
                    <b>TRAINING DATE</b>
                    {trainingDetails &&
                      date.map((item, index) => (
                        <>
                          {item.endDate === item.startDate ? (
                            <Text fontSize="14px">
                              {format(new Date(item.startDate), "dd LLLL y")}
                            </Text>
                          ) : (
                            <Text fontSize="14px">
                              Week {index + 1}:{" "}
                              {format(new Date(item.startDate), "dd LLLL y")} -{" "}
                              {format(new Date(item.endDate), "dd LLLL y")}
                            </Text>
                          )}
                        </>
                      ))}
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING TIME</b>
                    <Text fontSize="14px" py="10px">
                      {trainingDetails.training_time && (
                        <Text>
                          {Number(
                            JSON.parse(trainingDetails.training_time)[0][0] * 10
                          ) +
                            Number(
                              JSON.parse(trainingDetails.training_time)[0][1]
                            ) <
                          11 ? (
                            <>
                              {Number(
                                JSON.parse(
                                  trainingDetails.training_time
                                )[0][0] * 10
                              ) +
                                Number(
                                  JSON.parse(
                                    trainingDetails.training_time
                                  )[0][1]
                                )}
                              {JSON.parse(
                                trainingDetails.training_time
                              )[0].slice(2)}{" "}
                              AM
                            </>
                          ) : Number(
                              JSON.parse(trainingDetails.training_time)[0][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[0][1]
                              ) >
                            12 ? (
                            <>
                              {Number(
                                JSON.parse(
                                  trainingDetails.training_time
                                )[0][0] * 10
                              ) +
                                Number(
                                  JSON.parse(
                                    trainingDetails.training_time
                                  )[0][1]
                                ) -
                                12}
                              {JSON.parse(
                                trainingDetails.training_time
                              )[0].slice(2)}{" "}
                              PM
                            </>
                          ) : (
                            <>
                              {Number(
                                JSON.parse(
                                  trainingDetails.training_time
                                )[0][0] * 10
                              ) +
                                Number(
                                  JSON.parse(
                                    trainingDetails.training_time
                                  )[0][1]
                                )}
                              {JSON.parse(
                                trainingDetails.training_time
                              )[0].slice(2)}{" "}
                              PM
                            </>
                          )}{" "}
                          -{" "}
                          {Number(
                            JSON.parse(trainingDetails.training_time)[1][0] * 10
                          ) +
                            Number(
                              JSON.parse(trainingDetails.training_time)[1][1]
                            ) <
                          11 ? (
                            <>
                              {Number(
                                JSON.parse(
                                  trainingDetails.training_time
                                )[1][0] * 10
                              ) +
                                Number(
                                  JSON.parse(
                                    trainingDetails.training_time
                                  )[1][1]
                                )}
                              {JSON.parse(
                                trainingDetails.training_time
                              )[1].slice(2)}{" "}
                              AM
                            </>
                          ) : Number(
                              JSON.parse(trainingDetails.training_time)[1][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[1][1]
                              ) >
                            12 ? (
                            <>
                              {Number(
                                JSON.parse(
                                  trainingDetails.training_time
                                )[1][0] * 10
                              ) +
                                Number(
                                  JSON.parse(
                                    trainingDetails.training_time
                                  )[1][1]
                                ) -
                                12}
                              {JSON.parse(
                                trainingDetails.training_time
                              )[1].slice(2)}{" "}
                              PM
                            </>
                          ) : (
                            <>
                              {Number(
                                JSON.parse(
                                  trainingDetails.training_time
                                )[1][0] * 10
                              ) +
                                Number(
                                  JSON.parse(
                                    trainingDetails.training_time
                                  )[1][1]
                                )}
                              {JSON.parse(
                                trainingDetails.training_time
                              )[1].slice(2)}{" "}
                              PM
                            </>
                          )}
                        </Text>
                      )}
                    </Text>
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING PLACE</b>
                    <Text fontSize="14px" py="10px">
                      {trainingDetails.training_place}
                    </Text>
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING MODE</b>
                    <Text fontSize="14px" py="10px">
                      {displayModes[trainingDetails.training_mode]}
                    </Text>
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING DETAILS</b>
                    <Text fontSize="14px" py="10px">
                      {trainingDetails.training_detail !== "undefined" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: trainingDetails.training_detail,
                          }}
                        />
                      ) : (
                        "-"
                      )}
                    </Text>
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING FEE</b>
                    {course.trainings && trainingDetails.training_fee ? (
                      JSON.parse(trainingDetails.training_fee)[0] ? (
                        isNation ? (
                          <Text fontSize="14px" py="10px">
                            RM{" "}
                            {
                              JSON.parse(trainingDetails.training_fee)[0]
                                .ringgit
                            }
                          </Text>
                        ) : (
                          <Text fontSize="14px" py="10px">
                            USD{" "}
                            {JSON.parse(trainingDetails.training_fee)[0].dollar}
                          </Text>
                        )
                      ) : (
                        <Text fontSize="14px" py="10px">
                          RM {trainingDetails.training_fee}
                        </Text>
                      )
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>HRDC CLAIMABLE</b>&nbsp;
                    {course.trainings &&
                    trainingDetails.training_hrdc &&
                    JSON.parse(trainingDetails.training_hrdc)[0] ? (
                      JSON.parse(trainingDetails.training_hrdc)[0].hrdc ===
                      1 ? (
                        <Icon as={BsCheckCircleFill} color="green" />
                      ) : (
                        <Icon as={BsXCircleFill} color="red" />
                      )
                    ) : parseInt(trainingDetails.training_hrdc) === 1 ? (
                      <Icon as={BsCheckCircleFill} color="green" />
                    ) : (
                      <Icon as={BsXCircleFill} color="red" />
                    )}
                  </Box>
                </div>
              </Box>
            </Container>
          </GridItem>
        )}
      </Grid>

      {/* -----------------------------------ONLINE MODAL----------------------------------- */}
      <Modal
        size="2xl"
        isOpen={onlineModal.isOpen}
        onClose={() => {
          reset();
          onlineModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(modalSessionSubmit)}>
            <Box my="20px">
              <ModalHeader textAlign="center">
                Select training session
              </ModalHeader>
            </Box>
            <ModalBody>
              <RadioGroup
                value={radioId}
                onChange={(event) => {
                  const choiceId = Number(event);
                  setRadioId(choiceId);
                }}
              >
                <Text ml="30px">Full time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {/* THIS CODE IS FOR CLOSE REGISTRATION 4 DAYS BEFORE TRAINING (based on course id) */}
                    {course.course_id === 1 || course.course_id === 2
                      ? course.trainings &&
                        (course.trainings.length > 0 ? (
                          course.trainings
                            .sort(
                              (a, b) =>
                                new Date(
                                  a.training_date[0].startDate
                                ).getTime() -
                                new Date(b.training_date[0].startDate).getTime()
                            )
                            .map((item) => {
                              if (isNation) {
                                if (
                                  item.training_status != 0 &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Fulltime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "online" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              } else {
                                if (
                                  JSON.parse(item.training_fee)[0] &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Fulltime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "online" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              }
                            })
                        ) : (
                          <Text>adfs</Text>
                        ))
                      : course.trainings &&
                        (course.trainings.length > 0 ? (
                          course.trainings
                            .sort(
                              (a, b) =>
                                new Date(
                                  a.training_date[0].startDate
                                ).getTime() -
                                new Date(b.training_date[0].startDate).getTime()
                            )
                            .map((item) => {
                              if (isNation) {
                                if (
                                  item.training_status != 0 &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Fulltime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "online" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              } else {
                                if (
                                  JSON.parse(item.training_fee)[0] &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Fulltime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "online" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              }
                            })
                        ) : (
                          <Text>adfs</Text>
                        ))}
                  </Stack>
                </Box>
                <Text ml="30px">Part time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {/* THIS CODE IS FOR CLOSE REGISTRATION 4 DAYS BEFORE TRAINING (based on course id) */}
                    {course.course_id === 1 || course.course_id === 2
                      ? course.trainings &&
                        (course.trainings.length > 0 ? (
                          course.trainings
                            .sort(
                              (a, b) =>
                                new Date(
                                  a.training_date[0].startDate
                                ).getTime() -
                                new Date(b.training_date[0].startDate).getTime()
                            )
                            .map((item) => {
                              if (isNation) {
                                if (
                                  item.training_status != 0 &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Parttime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "online" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              } else {
                                if (
                                  JSON.parse(item.training_fee)[0] &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Parttime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "online" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              }
                            })
                        ) : (
                          <Text>adfs</Text>
                        ))
                      : course.trainings &&
                        (course.trainings.length > 0 ? (
                          course.trainings
                            .sort(
                              (a, b) =>
                                new Date(
                                  a.training_date[0].startDate
                                ).getTime() -
                                new Date(b.training_date[0].startDate).getTime()
                            )
                            .map((item) => {
                              if (isNation) {
                                if (
                                  item.training_status != 0 &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Parttime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "online" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              } else {
                                if (
                                  JSON.parse(item.training_fee)[0] &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Parttime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "online" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              }
                            })
                        ) : (
                          <Text>adfs</Text>
                        ))}
                  </Stack>
                </Box>
              </RadioGroup>
            </ModalBody>
            <Center>
              <ModalFooter>
                <Button
                  type="submit"
                  w="170px"
                  mr="10px"
                  fontSize="15px"
                  bg="#33945f"
                  color="white"
                  mt="20px"
                  _hover={{
                    backgroundColor: "white",
                    color: "#33945f",
                    border: "1px solid #33945f",
                  }}
                >
                  Select
                </Button>
                <Button
                  w="170px"
                  fontSize="15px"
                  bg="white"
                  color="#33945f"
                  mt="20px"
                  onClick={() => {
                    reset();
                    onlineModal.onClose();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Center>
          </form>
        </ModalContent>
      </Modal>
      {/* -----------------------------------END ONLINE MODAL----------------------------------- */}

      {/* -----------------------------------PHYSICAL MODAL----------------------------------- */}
      <Modal
        size="2xl"
        isOpen={physicalModal.isOpen}
        onClose={() => {
          reset();
          physicalModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(modalSessionSubmit)}>
            <Box my="20px">
              <ModalHeader textAlign="center">
                Select training session
              </ModalHeader>
            </Box>
            <ModalBody>
              <RadioGroup
                value={radioId}
                onChange={(event) => {
                  const choiceId = Number(event);
                  setRadioId(choiceId);
                }}
              >
                <Text ml="30px">Full time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {/* THIS CODE IS FOR CLOSE REGISTRATION 4 DAYS BEFORE TRAINING (based on course id) */}
                    {course.course_id === 1 || course.course_id === 2
                      ? course.trainings &&
                        (course.trainings.length > 0 ? (
                          course.trainings
                            .sort(
                              (a, b) =>
                                new Date(
                                  a.training_date[0].startDate
                                ).getTime() -
                                new Date(b.training_date[0].startDate).getTime()
                            )
                            .map((item) => {
                              if (isNation) {
                                if (
                                  item.training_status != 0 &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Fulltime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "physical" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              } else {
                                if (
                                  JSON.parse(item.training_fee)[0] &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Fulltime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "physical" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              }
                            })
                        ) : (
                          <Text>adfs</Text>
                        ))
                      : course.trainings &&
                        (course.trainings.length > 0 ? (
                          course.trainings
                            .sort(
                              (a, b) =>
                                new Date(
                                  a.training_date[0].startDate
                                ).getTime() -
                                new Date(b.training_date[0].startDate).getTime()
                            )
                            .map((item) => {
                              if (isNation) {
                                if (
                                  item.training_status != 0 &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Fulltime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "physical" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              } else {
                                if (
                                  JSON.parse(item.training_fee)[0] &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Fulltime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "physical" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              }
                            })
                        ) : (
                          <Text>adfs</Text>
                        ))}
                  </Stack>
                </Box>
                <Text ml="30px">Part time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {/* THIS CODE IS FOR CLOSE REGISTRATION 4 DAYS BEFORE TRAINING (based on course id) */}
                    {course.course_id === 1 || course.course_id === 2
                      ? course.trainings &&
                        (course.trainings.length > 0 ? (
                          course.trainings
                            .sort(
                              (a, b) =>
                                new Date(
                                  a.training_date[0].startDate
                                ).getTime() -
                                new Date(b.training_date[0].startDate).getTime()
                            )
                            .map((item) => {
                              if (isNation) {
                                if (
                                  item.training_status != 0 &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Parttime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "physical" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              } else {
                                if (
                                  JSON.parse(item.training_fee)[0] &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Parttime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "physical" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              }
                            })
                        ) : (
                          <Text>adfs</Text>
                        ))
                      : course.trainings &&
                        (course.trainings.length > 0 ? (
                          course.trainings
                            .sort(
                              (a, b) =>
                                new Date(
                                  a.training_date[0].startDate
                                ).getTime() -
                                new Date(b.training_date[0].startDate).getTime()
                            )
                            .map((item) => {
                              if (isNation) {
                                if (
                                  item.training_status != 0 &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Parttime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "physical" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              } else {
                                if (
                                  JSON.parse(item.training_fee)[0] &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[0] === "Parttime" &&
                                  modeSplitter(
                                    trainingModes[item.training_mode]
                                  )[1] === "physical" &&
                                  new Date(
                                    item.training_date[0].startDate
                                  ).getTime() -
                                    86400000 * 4 >
                                    new Date().getTime()
                                ) {
                                  return (
                                    <Radio
                                      value={item.training_id}
                                      {...register("trainingId")}
                                    >
                                      {item.training_date[0].startDate !=
                                      item.training_date[0].endDate
                                        ? format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL"
                                          ) +
                                          " - " +
                                          format(
                                            new Date(
                                              item.training_date[
                                                item.training_date.length - 1
                                              ].endDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name
                                        : format(
                                            new Date(
                                              item.training_date[0].startDate
                                            ),
                                            "dd LLLL y"
                                          ) +
                                          ", " +
                                          item.training_name}
                                    </Radio>
                                  );
                                }
                              }
                            })
                        ) : (
                          <Text>adfs</Text>
                        ))}
                  </Stack>
                </Box>
              </RadioGroup>
            </ModalBody>
            <Center>
              <ModalFooter>
                <Button
                  type="submit"
                  w="170px"
                  mr="10px"
                  fontSize="15px"
                  bg="#33945f"
                  color="white"
                  mt="20px"
                  _hover={{
                    backgroundColor: "white",
                    color: "#33945f",
                    border: "1px solid #33945f",
                  }}
                >
                  Select
                </Button>
                <Button
                  w="170px"
                  fontSize="15px"
                  bg="white"
                  color="#33945f"
                  mt="20px"
                  onClick={() => {
                    reset();
                    physicalModal.onClose();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Center>
          </form>
        </ModalContent>
      </Modal>
      {/* -----------------------------------END PHYSICAL MODAL----------------------------------- */}

      {/* -----------------------------------ADD COMPANY MODAL----------------------------------- */}
      <Modal
        size="5xl"
        isOpen={companyModal.isOpen}
        onClose={() => {
          reset();
          companyModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <Box my="20px">
            <ModalHeader textAlign="center">Add Company</ModalHeader>
          </Box>
          <form onSubmit={handleSubmit(companyModalSubmit)}>
            <ModalBody>
              <Box m="10px">
                <Heading size="md">Company</Heading>
                <FormControl isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    SSM NUMBER / COMPANY REGISTRATION NO:
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    defaultValue={companySSM}
                    {...register("companyFormSSM")}
                  ></Input>
                </FormControl>
                <Stack direction="row" mt="10px">
                  <FormControl isRequired>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      COMPANY NAME:
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      placeholder="Company Name"
                      {...register("companyFormName")}
                      onBlur={convertToCaps}
                    ></Input>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      COMPANY PICTURE
                    </FormLabel>
                    <Input
                      accept="image/*"
                      type="file"
                      aria-hidden="true"
                      {...register("companyFormImage")}
                      // onChange={imageChange}
                    />
                  </FormControl>
                </Stack>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    COMPANY ADDRESS
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    placeholder="Company Address (Unit, Street)"
                    {...register("companyFormAddress")}
                  ></Input>
                </FormControl>
                {windowSize.current[0] > 500 && (
                  <HStack>
                    <Box w="15%">
                      <FormControl mt="10px" isRequired>
                        <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                          POSTCODE
                        </FormLabel>
                        <Input
                          type="number"
                          fontSize="14px"
                          placeholder="Postcode"
                          {...register("companyFormPostcode")}
                          onBlur={checkPostcodes}
                        ></Input>
                      </FormControl>
                    </Box>
                    <Box w="30%">
                      <FormControl mt="10px" isRequired>
                        <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                          CITY
                        </FormLabel>
                        <Input
                          fontSize="14px"
                          placeholder="City"
                          {...register("companyFormCity")}
                        ></Input>
                      </FormControl>
                    </Box>
                    <Box w="25%">
                      <FormControl mt="10px" isRequired>
                        <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                          STATE
                        </FormLabel>
                        <Input
                          fontSize="14px"
                          list="MYStates"
                          placeholder="State"
                          {...register("companyFormState")}
                          onBlur={checkState}
                        />
                        <datalist id="MYStates">
                          {MYStates &&
                            MYStates.map((option) => (
                              <option key={option} value={option} />
                            ))}
                        </datalist>
                      </FormControl>
                    </Box>
                    <Box w="30%">
                      <FormControl mt="10px" isRequired>
                        <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                          COUNTRY
                        </FormLabel>
                        <Select
                          defaultValue="Malaysia"
                          {...register("companyFormCountry")}
                        >
                          {countries.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </HStack>
                )}
                {windowSize.current[0] < 500 && (
                  <>
                    <HStack>
                      <Box w="50%">
                        <FormControl mt="10px" isRequired>
                          <FormLabel
                            fontSize="13px"
                            mt="10px"
                            fontWeight="bold"
                          >
                            POSTCODE
                          </FormLabel>
                          <Input
                            type="number"
                            fontSize="14px"
                            placeholder="Postcode"
                            {...register("companyFormPostcode")}
                          ></Input>
                        </FormControl>
                      </Box>
                      <Box w="50%">
                        <FormControl mt="10px" isRequired>
                          <FormLabel
                            fontSize="13px"
                            mt="10px"
                            fontWeight="bold"
                          >
                            CITY
                          </FormLabel>
                          <Input
                            fontSize="14px"
                            placeholder="City"
                            {...register("companyFormCity")}
                          ></Input>
                        </FormControl>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box w="50%">
                        <FormControl mt="10px" isRequired>
                          <FormLabel
                            fontSize="13px"
                            mt="10px"
                            fontWeight="bold"
                          >
                            STATE
                          </FormLabel>
                          <Input
                            fontSize="14px"
                            list="MYStates"
                            placeholder="State"
                            {...register("companyFormState")}
                            onBlur={checkState}
                          />
                          <datalist id="MYStates">
                            {MYStates &&
                              MYStates.map((option) => (
                                <option key={option} value={option} />
                              ))}
                          </datalist>
                        </FormControl>
                      </Box>
                      <Box w="50%">
                        <FormControl mt="10px" isRequired>
                          <FormLabel
                            fontSize="13px"
                            mt="10px"
                            fontWeight="bold"
                          >
                            COUNTRY
                          </FormLabel>
                          <Select
                            defaultValue="Malaysia"
                            {...register("companyFormCountry")}
                          >
                            {countries.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </HStack>
                  </>
                )}

                <HStack>
                  <Box width="50%">
                    <FormControl mt="10px">
                      <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                        COMPANY FINANCE EMAIL
                      </FormLabel>
                      <Input
                        type="email"
                        fontSize="14px"
                        placeholder="Email"
                        {...register("companyFormFinanceEmail")}
                      ></Input>
                    </FormControl>
                  </Box>

                  <Box width="50%">
                    <FormControl mt="10px" isRequired>
                      <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                        COMPANY SCHEME
                      </FormLabel>
                      <Menu closeOnSelect={false}>
                        <MenuButton
                          as={Button}
                          minWidth="100%"
                          borderColor="#33945f"
                          borderWidth="2px"
                        >
                          {schemeList.length > 0
                            ? schemeList.length + " schemes selected"
                            : "Choose 1 or more schemes"}
                        </MenuButton>
                        <MenuList>
                          <MenuOptionGroup
                            type="checkbox"
                            onChange={(e) => {
                              setSchemeList(e);
                            }}
                          >
                            <MenuItemOption value="Food & Beverage">
                              Food & Beverage
                            </MenuItemOption>
                            <MenuItemOption value="Cosmetics">
                              Cosmetics
                            </MenuItemOption>
                            <MenuItemOption value="Pharmaceuticals">
                              Pharmaceuticals
                            </MenuItemOption>
                            <MenuItemOption value="Consumer Goods">
                              Consumer Goods
                            </MenuItemOption>
                            <MenuItemOption value="Food Premises">
                              Food Premises
                            </MenuItemOption>
                            <MenuItemOption value="Slaughter House">
                              Slaughter House
                            </MenuItemOption>
                            <MenuItemOption value="Logistics">
                              Logistics
                            </MenuItemOption>
                            <MenuItemOption value="OEM">OEM</MenuItemOption>
                            <MenuItemOption value="Medical Devices">
                              Medical Devices
                            </MenuItemOption>
                            <MenuItemOption value="Education">
                              Education
                            </MenuItemOption>
                            <MenuItemOption value="Others">
                              Others
                            </MenuItemOption>
                          </MenuOptionGroup>
                        </MenuList>
                      </Menu>
                    </FormControl>
                  </Box>
                </HStack>
              </Box>

              <Box m="10px" pt="10px">
                <Heading size="md">Person In Charge</Heading>
                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    NAME
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    placeholder="Name"
                    {...register("companyFormPicName")}
                  ></Input>
                </FormControl>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    IDENTIFICATION NUMBER / PASSPORT NO.
                  </FormLabel>
                  <Input
                    type="text"
                    fontSize="14px"
                    placeholder="Identification Number / Passport No"
                    {...register("companyFormPicIC")}
                  ></Input>
                </FormControl>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    PHONE NUMBER
                  </FormLabel>
                  <HStack>
                    <Select
                      defaultValue="+60"
                      w="30%"
                      {...register("companyFormPicCodePhone")}
                    >
                      {countries
                        .sort((a, b) => {
                          if (a.label.toLowerCase() < b.label.toLowerCase())
                            return -1;
                          if (a.label.toLowerCase() > b.label.toLowerCase())
                            return 1;
                          return 0;
                        })
                        .map((item) => (
                          <option style={{ width: "100px" }} value={item.code}>
                            {item.value} {item.code}
                          </option>
                        ))}
                    </Select>
                    <Input
                      type="number"
                      fontSize="14px"
                      placeholder="Phone Number"
                      {...register("companyFormPicPhone")}
                    ></Input>
                  </HStack>
                </FormControl>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    EMAIL
                  </FormLabel>
                  <Input
                    type="email"
                    fontSize="14px"
                    placeholder="Email"
                    {...register("companyFormPicEmail")}
                  ></Input>
                </FormControl>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    POSITION
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    placeholder="Position"
                    {...register("companyFormPicPosition")}
                  ></Input>
                </FormControl>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Center w="100%">
                <Button
                  type="submit"
                  minW="30%"
                  bgColor="#33945f"
                  color="white"
                  _hover={{ bg: "green.700" }}
                  fontWeight="medium"
                >
                  Add
                </Button>
                <Button
                  minW="30%"
                  type="reset"
                  mx="10px"
                  fontWeight="medium"
                  onClick={() => {
                    reset();
                    companyModal.onClose();
                  }}
                >
                  Cancel
                </Button>
              </Center>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* ----------------------------------END ADD COMPANY MODAL----------------------------------- */}
      {/* ----------------------------------ADD PARTICIPANT MODAL ---------------------------------- */}
      <Modal
        size="5xl"
        isOpen={addParticipantModal.isOpen}
        onClose={() => {
          reset();
          addParticipantModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(modalAddParticipantSubmit)}>
            <Box my="20px">
              <ModalHeader textAlign="center">ADD PARTICIPANT</ModalHeader>
            </Box>
            <ModalBody>
              <>
                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    NAME
                  </FormLabel>
                  <Input
                    name="name"
                    {...register("name")}
                    placeholder="Enter name"
                  ></Input>
                </FormControl>
                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    IDENTIFICATION NUMBER / PASSPORT NO.
                  </FormLabel>
                  <Input
                    name="ic"
                    type="text"
                    {...register("ic")}
                    placeholder="Enter ic (no dash)"
                  ></Input>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    EMAIL
                  </FormLabel>
                  <Input
                    name="email"
                    type="email"
                    {...register("email")}
                    placeholder="Enter email"
                  ></Input>
                </FormControl>
                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    PHONE NUMBER
                  </FormLabel>
                  <HStack>
                    <Select
                      defaultValue={isNation ? "+60" : countryCode}
                      w="30%"
                      {...register("codePhone")}
                    >
                      {countries
                        .sort((a, b) => {
                          if (a.label.toLowerCase() < b.label.toLowerCase())
                            return -1;
                          if (a.label.toLowerCase() > b.label.toLowerCase())
                            return 1;
                          return 0;
                        })
                        .map((item) => (
                          <option style={{ width: "100px" }} value={item.code}>
                            {item.value} {item.code}
                          </option>
                        ))}
                    </Select>
                    <Input
                      name="phoneNo"
                      type="number"
                      {...register("phoneNo")}
                      placeholder="Enter phone number"
                    ></Input>
                  </HStack>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    POSITION
                  </FormLabel>
                  <Input
                    name="position"
                    {...register("position")}
                    placeholder="Enter position"
                  ></Input>
                </FormControl>
                {trainingType === "physical" && (
                  <FormControl>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      DIETARY / ALLERGIES
                    </FormLabel>
                    <Input
                      name="allergies"
                      {...register("allergies")}
                      placeholder="Enter allergies"
                    ></Input>
                  </FormControl>
                )}
              </>
            </ModalBody>

            <ModalFooter>
              <Stack direction={"row"} width="100%">
                <Box minW="50%">
                  <Button
                    w="100%"
                    type="submit"
                    fontSize="15px"
                    bg="#33945f"
                    color="white"
                    _hover={{
                      backgroundColor: "white",
                      color: "#33945f",
                      border: "1px solid #33945f",
                    }}
                  >
                    Add
                  </Button>
                </Box>
                <Box minW="50%">
                  <Button
                    w="100%"
                    fontSize="15px"
                    bg="white"
                    color="#33945f"
                    border="1px solid #33945f"
                    onClick={() => {
                      reset();
                      addParticipantModal.onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* ----------------------------------END PARTICIPANT MODAL ---------------------------------- */}

      {/* ----------------------------------START HRDC REMINDER MODAL ---------------------------------- */}
      <Modal
        size="2xl"
        isOpen={remindHrdc.isOpen}
        onClose={remindHrdc.onClose}
        bg="blue"
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(4px)" />
        <ModalContent my="auto" borderRadius="10px">
          <ModalHeader bg="gray.200" borderTopRadius="10px">
            <Center>
              <Image
                w={{ base: "100%", md: "50%" }}
                src={
                  "https://www.holisticslab.my/wp-content/uploads/2022/05/HLSB-2048x502.png"
                }
              />
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <Center>
            <ModalBody align="center" p="30px">
              <Text fontSize="20px">
                <span style={{ color: "red" }}>REMINDER</span>: Minimum{" "}
                <b>5 participants</b> are required to claim HRDC for{" "}
                <b>{course.course_name}</b>.
              </Text>
            </ModalBody>
          </Center>
        </ModalContent>
      </Modal>
      {/* ----------------------------------END HRDC REMINDER MODAL ---------------------------------- */}
    </>
  );
};

export default Company;

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Center,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import hl_logo from "../../../assets/logo.png";
import { EditIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { MdPreview } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { ReactTable, AlertPop, ChakraAlertDialog } from "../../../components";
import styled from "styled-components";
import CRUDFunction from "../../../functions/CRUDFunction";
import { useForm, Controller } from "react-hook-form";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { WYSIWYGEditor } from "../../../components/rich-text-editor";
import ThemeContext from "../../../functions/ThemeContext";

const Styles = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :nth-child(1) {
        width: 30px;
      }
      :nth-child(2) {
        width: 100px;
      }
      :nth-child(3) {
        width: 80px;
      }
      :nth-child(4) {
        width: 300px;
      }
      :last-child {
        width: 100px;
        border-right: 0;
      }
    }

    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #bdbdbd;
      text-align: left;
      :nth-child(1),
      :nth-child(3),
      :last-child {
        text-align: center;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Copywriting() {
  const addDrawer = useDisclosure();
  const editDrawer = useDisclosure();
  const viewModal = useDisclosure();
  const delDialog = useDisclosure();
  const errorProps = useDisclosure();
  const btnRef = React.useRef();
  const [data, setData] = useState([]);
  const [dataEmail, setDataEmail] = useState([]);
  const [dataMessage, setDataMessage] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [copywritingId, setCopywritingId] = useState([]);
  const [view, setView] = useState([]);
  const [edit, setEdit] = useState([]);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const [errorAlert, setErrorAlert] = useState("");

  const [tableLoader, setTableLoader] = useState(false);
  const themeCtx = useContext(ThemeContext);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        id: "index",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "TITLE",
        accessor: "copywriting_title",
      },
      {
        Header: "LABEL",
        accessor: "copywriting_label",
        Cell: ({ cell: { value } }) => (
          <>
            <Text
              style={{ textTransform: "capitalize" }}
              color={
                value === "Before"
                  ? "#f9c611"
                  : value === "After"
                  ? "#14804a"
                  : "#4b85fa"
              }
            >
              {value}
            </Text>
          </>
        ),
      },
      {
        Header: "CONTENT",
        accessor: "copywriting_message",
        Cell: ({ cell: { value } }) => (
          <>
            <Text noOfLines={1}>
              <div dangerouslySetInnerHTML={{ __html: value }} />
            </Text>
          </>
        ),
      },
      {
        Header: "ACTIONS",
        id: "reference",
        accessor: "copywriting_id",
        disableSortBy: true,
        Cell: ({ cell }) => (
          <>
            <Menu>
              <MenuButton
                as={Button}
                bgColor="none"
                _hover={{ bgColor: themeCtx.mode && "#2b2b2b" }}
                _active={{ bgColor: themeCtx.mode && "#2b2b2b" }}
                align="center"
                variant="ghost"
                rightIcon={<BiDotsVerticalRounded boxsize={5} />}
              ></MenuButton>
              <MenuList
                minW="100px"
                bgColor={themeCtx.mode ? "#2b2b2b" : "white"}
              >
                <MenuItem
                  fontSize="15px"
                  _hover={{ bgColor: themeCtx.mode && "#1b1b1b" }}
                  id="edit"
                  icon={<EditIcon boxSize={5} color="#14804a" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}copywriting/${cell.value}`
                    );
                    if (res) {
                      setEdit(res);
                      setCopywritingId(cell.value);
                      editDrawer.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bgColor: themeCtx.mode && "#1b1b1b" }}
                  icon={<MdPreview fontSize="20px" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}copywriting/${cell.value}`
                    );
                    if (res) {
                      setView(res);
                      viewModal.onOpen();
                    }
                  }}
                >
                  View
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bgColor: themeCtx.mode && "#1b1b1b" }}
                  icon={<DeleteIcon boxSize={5} color="#d12953" />}
                  onClick={() => {
                    setCopywritingId(cell.value);
                    delDialog.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    setTableLoader(true);
    fetchDataEmail();
    fetchDataMessage();
  }, []);

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
    }, 5000);
  };

  const fetchDataEmail = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}copywritingEmail`);
    if (res) {
      setTableLoader(false);
      setDataEmail(res);
    }
  };

  const fetchDataMessage = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}copywritingMessage`);
    if (res) {
      setTableLoader(false);
      setDataMessage(res);
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (data) {
      const formData = new FormData();

      if (tabIndex === 0) {
        formData.append("copywriting_tab", "Email");
      } else {
        formData.append("copywriting_tab", "Message");
      }
      formData.append("copywriting_title", data.copywritingTitle);
      formData.append("copywriting_label", data.copywritingLabel);
      formData.append("copywriting_message", data.copywritingMessage);
      formData.append("copywriting_email", data.copywritingEmail);

      if (addDrawer.isOpen) {
        const res = await CRUDFunction.create(
          `${URL_STAGING}copywriting/create`,
          formData
        );
        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          tabIndex === 0 ? setDataEmail(res) : setDataMessage(res);
          reset();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully created.",
          });
          timer();
          addDrawer.onClose();
        }
      } else if (editDrawer.isOpen) {
        const res = await CRUDFunction.update(
          `${URL_STAGING}copywriting/update/${copywritingId}`,
          formData
        );
        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          tabIndex === 0 ? setDataEmail(res) : setDataMessage(res);
          reset();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "The data has been updated successfully.",
          });
          timer();
          editDrawer.onClose();
        }
      }
    }
  };

  return (
    <div>
      {/* -----------------add copywriting drawer------------------- */}
      <Drawer
        isOpen={addDrawer.isOpen}
        placement="right"
        onClose={() => {
          reset();
          addDrawer.onClose();
        }}
        size="md"
        zIndex="1"
      >
        <DrawerOverlay />
        <DrawerContent
          bgColor={themeCtx.mode && "#2b2b2b"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader>ADD SCRIPT</DrawerHeader>
            <DrawerBody>
              <FormControl isRequired mb="20px">
                <FormLabel fontWeight="bold">TITLE</FormLabel>
                <Input
                  placeholder="Subject"
                  {...register("copywritingTitle")}
                />
              </FormControl>
              <FormControl isRequired mb="20px">
                <FormLabel fontWeight="bold">LABEL</FormLabel>
                <Select
                  id="copywriting_label"
                  defaultValue=""
                  bg={themeCtx.mode && "#2b2b2b"}
                  {...register("copywritingLabel")}
                >
                  <option
                    value=""
                    style={{ backgroundColor: "#2b2b2b" }}
                    disabled
                  >
                    Label
                  </option>
                  <option value="Before" style={{ backgroundColor: "#2b2b2b" }}>
                    Before
                  </option>
                  <option value="During" style={{ backgroundColor: "#2b2b2b" }}>
                    During
                  </option>
                  <option value="After" style={{ backgroundColor: "#2b2b2b" }}>
                    After
                  </option>
                </Select>
              </FormControl>
              <FormControl
                id="copywritingMessage"
                mb="20px"
                isInvalid={errors.copywritingMessage}
              >
                <FormLabel fontWeight={"bold"}>
                  MESSAGE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  name="copywritingMessage"
                  control={control}
                  rules={{
                    required: { value: true, message: "This is required." },
                  }}
                />
                <FormErrorMessage>
                  {errors.copywritingMessage?.message}
                </FormErrorMessage>
              </FormControl>
            </DrawerBody>
            <DrawerFooter>
              <Center w="100%">
                <Button
                  type="submit"
                  id="add_data"
                  w="100%"
                  mr={3}
                  color="white"
                  bg="#33945f"
                  fontWeight="medium"
                  _hover={{ bg: "green.700" }}
                >
                  Add
                </Button>
                <Button
                  w="100%"
                  variant="outline"
                  fontWeight="medium"
                  onClick={() => {
                    reset();
                    addDrawer.onClose();
                  }}
                >
                  Cancel
                </Button>
              </Center>
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>

      {/* -----------------add script drawer end------------------- */}

      {/* -----------------edit script drawer------------------- */}
      <Drawer
        isOpen={editDrawer.isOpen}
        placement="center"
        onClose={() => {
          reset();
          editDrawer.onClose();
        }}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent
          bgColor={themeCtx.mode && "#2b2b2b"}
          color={themeCtx.mode && "white"}
        >
          <form name="update-form" onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader>EDIT SCRIPT</DrawerHeader>
            <DrawerBody>
              <FormControl isRequired mb="20px">
                <FormLabel fontWeight="bold">TITLE</FormLabel>
                <Input
                  type="text"
                  id="copywriting_title"
                  defaultValue={edit.copywriting_title}
                  placeholder="Subject"
                  {...register("copywritingTitle")}
                />
              </FormControl>
              <FormControl isRequired mb="20px">
                <FormLabel fontWeight="bold">LABEL</FormLabel>
                <Select
                  defaultValue={edit.copywriting_label}
                  {...register("copywritingLabel")}
                  bg={themeCtx.mode && "#2b2b2b"}
                >
                  <option
                    value={"default"}
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                    disabled
                  >
                    Label
                  </option>
                  <option
                    value="Before"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Before
                  </option>
                  <option
                    value="During"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    During
                  </option>
                  <option
                    value="After"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    After
                  </option>
                </Select>
              </FormControl>
              <FormControl
                id="copywritingMessage"
                mb="20px"
                isInvalid={errors.copywritingMessage}
              >
                <FormLabel fontWeight={"bold"}>
                  MESSAGE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  name="copywritingMessage"
                  control={control}
                  defaultValue={edit.copywriting_message}
                  rules={{
                    required: { value: true, message: "This is required." },
                  }}
                />
                <FormErrorMessage>
                  {errors.copywritingMessage?.message}
                </FormErrorMessage>
              </FormControl>
            </DrawerBody>

            <DrawerFooter>
              <Center w="100%">
                <Button
                  type="submit"
                  id="update_data"
                  w="100%"
                  mr={3}
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  fontWeight="medium"
                >
                  Save
                </Button>
                <Button
                  w="100%"
                  variant="outline"
                  onClick={() => {
                    reset();
                    editDrawer.onClose();
                  }}
                  fontWeight="medium"
                >
                  Cancel
                </Button>
              </Center>
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>
      {/* -----------------edit script drawer end------------------- */}

      {/* --------------------view modal------------------------------ */}
      <Modal isOpen={viewModal.isOpen} onClose={viewModal.onClose} size="3xl">
        <ModalOverlay />
        <ModalContent
          bg={themeCtx.mode && "#2b2b2b"}
          color={themeCtx.mode && "white"}
        >
          <ModalHeader
            color="white"
            bg={
              view.copywriting_label === "Before"
                ? "#f9c611"
                : view.copywriting_label === "After"
                ? "#14804a"
                : "#4b85fa"
            }
            borderTopRadius={5}
          >
            {view.copywriting_label}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <Box mb="40px">
              <Text
                id="copywriting_title"
                fontSize="sm"
                fontWeight="bold"
                color="gray.500"
              >
                TITLE
              </Text>
              <Text>{view.copywriting_title}</Text>
            </Box>
            <Box>
              <Text fontSize="sm" fontWeight="bold" color="gray.500">
                MESSAGE
              </Text>
              <Text>
                <div
                  dangerouslySetInnerHTML={{ __html: view.copywriting_message }}
                />
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* --------------------view modal end-------------------------- */}

      {/* ---------------------------delete confirmation dialog -------------------------------*/}
      <ChakraAlertDialog
        title={"Delete Copywriting"}
        dialogProps={delDialog}
        url={[`${URL_STAGING}copywriting/${copywritingId}/${tabIndex}`]}
        alert={alert}
        setAlert={setAlert}
        setData={tabIndex === 0 ? setDataEmail : setDataMessage}
        body={"Are you sure? You can't undo this action afterwards."}
        themeCtx={themeCtx}
        button={"Delete"}
      />
      {/* ---------------------------delete confirmation dialog end-------------------------------*/}

      <Heading m="25px 0px 25px 100px" color={themeCtx.mode && "white"}>
        COPYWRITING
      </Heading>
      <Container maxW="90%" m="20px auto">
        {alert.message ? (
          <AlertPop
            width="30%"
            className={alert.class}
            status={alert.status}
            title={alert.message}
          />
        ) : (
          ""
        )}
        <Box
          h="100%"
          borderRadius={10}
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          display="grid"
        >
          <Tabs
            onChange={(index) => setTabIndex(index)}
            isFitted
            borderRadius={10}
            size="lg"
            variant="enclosed"
          >
            <TabList onChange={(index) => setTabIndex(index)}>
              <Tab
                _selected={{
                  bg: themeCtx.mode ? "#2b2b2b" : "white",
                  color: themeCtx.mode ? "white" : "black",
                }}
                borderTopRightRadius="0"
                color="gray.500"
                bg={themeCtx.mode ? "#3b3b3b" : "#f4f8f1"}
                w="20%"
                px={20}
                onClick={async () => {
                  const res = await CRUDFunction.get(
                    `${URL_STAGING}copywritingEmail`
                  );
                  if (res) {
                    setDataEmail(res);
                  }
                }}
              >
                Email
              </Tab>
              <Tab
                _selected={{
                  bg: themeCtx.mode ? "#2b2b2b" : "white",
                  color: themeCtx.mode ? "white" : "black",
                }}
                borderTopLeftRadius="0"
                color="gray.500"
                bg={themeCtx.mode ? "#3b3b3b" : "#f4f8f1"}
                w="20%"
                px={20}
                onClick={async () => {
                  const res = await CRUDFunction.get(
                    `${URL_STAGING}copywritingMessage`
                  );
                  if (res) {
                    setDataMessage(res);
                  }
                }}
              >
                Whatsapp
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <div style={{ position: "relative" }}>
                  <Button
                    leftIcon={<AddIcon />}
                    fontSize="14px"
                    id="add"
                    pos="absolute"
                    w="200px"
                    bg="#33945f"
                    _hover={{ bg: "green.700" }}
                    color="white"
                    right="3"
                    top="9"
                    ref={btnRef}
                    onClick={addDrawer.onOpen}
                  >
                    Add Script
                  </Button>
                </div>
                <Styles>
                  <div key={1}>
                    <ReactTable
                      columns={columns}
                      data={dataEmail}
                      tableLoader={tableLoader}
                      modulePage="settings"
                      themeCtx={themeCtx}
                    />
                  </div>
                </Styles>
              </TabPanel>
              <TabPanel>
                <div style={{ position: "relative" }}>
                  <Button
                    leftIcon={<AddIcon />}
                    fontSize="14px"
                    id="add"
                    pos="absolute"
                    w="200px"
                    bg="#33945f"
                    _hover={{ bg: "green.700" }}
                    color="white"
                    right="3"
                    top="9"
                    ref={btnRef}
                    onClick={addDrawer.onOpen}
                  >
                    Add Script
                  </Button>
                </div>
                <Styles>
                  <div key={1}>
                    <ReactTable
                      columns={columns}
                      data={dataMessage}
                      tableLoader={tableLoader}
                      modulePage="settings"
                      themeCtx={themeCtx}
                    />
                  </div>
                </Styles>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Container>
    </div>
  );
}

export { Copywriting };

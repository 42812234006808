import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Input,
  Image,
  Container,
  Button,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Textarea,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  Center,
  FormControl,
  FormLabel,
  Select,
  Badge,
} from "@chakra-ui/react";
import styled from "styled-components";
import {
  AddIcon,
  EditIcon,
  DeleteIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  ReactTable,
  AlertPop,
  ChakraAlertDialog,
  ErrorsDialog,
} from "../../../components";
import { useForm } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import "../../../assets/css/custom.css";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import ThemeContext from "../../../functions/ThemeContext";

const StylesCourse = styled.div`
  padding-y: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      // background-color: #ffba00;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      // border-right: 1px solid black;
      :nth-child(1) {
        width: 100px;
      }
      :nth-child(3) {
        width: 300px;
      }
      :last-child {
        width: 150px;
      }
    }

    td {
      // font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      // border-bottom: 1px solid #bdbdbd;
    }
  }
`;

function FeedbackAdmin() {
  const addFeedback = useDisclosure();
  const editFeedback = useDisclosure();
  const dialogProps = useDisclosure();
  const errorProps = useDisclosure();
  const [data, setData] = useState([]);
  const [feedbackID, setFeedbackID] = useState("");
  const [editData, setEditData] = useState([]);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const [errModule, setErrModule] = useState(false);
  const [errDescription, setErrDescription] = useState(false);
  const [errStatus, setErrStatus] = useState(false);
  const [errRole, setErrRole] = useState(false);
  const [errPriority, setErrPriority] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const themeCtx = useContext(ThemeContext);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        id: "rows",
        // accessor: "course_id",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "MODULE",
        accessor: "module",
        Cell: ({ value }) => (
          <>
            {value === "1" ? (
              <Text>Dashboard</Text>
            ) : value === "2" ? (
              <Text>Course</Text>
            ) : value === "3" ? (
              <Text>Programme</Text>
            ) : value === "4" ? (
              <Text>Company</Text>
            ) : value === "5" ? (
              <Text>Training</Text>
            ) : value === "6" ? (
              <Text>Trainee</Text>
            ) : value === "7" ? (
              <Text>Discount</Text>
            ) : value === "8" ? (
              <Text>Calendar</Text>
            ) : value === "9" ? (
              <Text>Settings</Text>
            ) : value === "10" ? (
              <Text>Authentication</Text>
            ) : (
              <Text>All</Text>
            )}
          </>
        ),
      },
      {
        Header: "ROLE",
        accessor: "role",
        Cell: ({ value }) => {
          return (
            <>
              {value === "1" ? (
                <Text>Public</Text>
              ) : value === "2" ? (
                <Text>Management</Text>
              ) : value === "3" ? (
                <Text>Company</Text>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: "DESCRIPTION",
        accessor: "description",
        Cell: ({ value }) => {
          return <Text>{value}</Text>;
        },
      },
      {
        Header: "PRIORITY",
        accessor: "priority",
        Cell: ({ value }) => {
          return (
            <Text
              color={
                value === "1"
                  ? "teal.400"
                  : value === "2"
                  ? "yellow.400"
                  : value === "3"
                  ? "orange.400"
                  : "red.400"
              }
              fontWeight="400"
            >
              {value === "1"
                ? "Not Set"
                : value === "2"
                ? "Not Important"
                : value === "3"
                ? "Important"
                : value === "4"
                ? "Critical"
                : ""}
            </Text>
          );
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <Badge
              variant="subtle"
              colorScheme={
                value === "1"
                  ? "yellow"
                  : value === "2"
                  ? "blue"
                  : value === "3"
                  ? "purple"
                  : "green"
              }
            >
              {value === "1"
                ? "To Do"
                : value === "2"
                ? "Doing"
                : value === "3"
                ? "Review"
                : value === "4"
                ? "Completed"
                : ""}
            </Badge>
            // <Select
            //   w="100%"
            //   style={{ color: "black", fontWeight: "500", opacity: "1" }}
            //   value={value}
            //   disabled
            // >
            //   <option value="1">To Do</option>
            //   <option value="2">Doing</option>
            //   <option value="3">Review</option>
            //   <option value="4">Completed</option>
            // </Select>
          );
        },
      },
      {
        Header: "ACTIONS",
        id: "ref",
        accessor: "feedback_id",
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<BiDotsVerticalRounded boxsize={5} />}
                _hover={{ bg: themeCtx.mode ? "#2b2b2b" : "white" }}
                _active={{ bg: themeCtx.mode ? "#2b2b2b" : "white" }}
                variant="ghost"
              ></MenuButton>
              <MenuList minW="100px" bg={themeCtx.mode ? "#2b2b2b" : "white"}>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bg: themeCtx.mode && "#1b1b1b" }}
                  icon={<EditIcon boxsize={5} color="#14804a" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}feedback/${value}`
                    );
                    if (res) {
                      setEditData(res);
                      setFeedbackID(value);
                      editFeedback.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  _hover={{ bg: themeCtx.mode && "#1b1b1b" }}
                  icon={<DeleteIcon boxsize={5} color="#d12953" />}
                  onClick={() => {
                    setFeedbackID(value);
                    dialogProps.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      },
    ],
    []
  );

  const renderRowSubComponent = React.useCallback(({ row }) => {
    return (
      <div>
        <Container
          align="left"
          maxW="100%"
          bgColor="white"
          py="10px"
          borderBottom="1px"
          borderColor="#bdbdbd"
        >
          <Container align="left" maxW="80%" m="0px 0px 0px 100px">
            <b>Course Module:</b>
            <Text fontSize="14px" mb="17px">
              {data[row.id].module}
            </Text>
            <b>Course Outline:</b>
            <Text fontSize="14px" mb="17px">
              {data[row.id].description}
            </Text>
            <b>Status:</b>
            <Text fontSize="14px" mb="17px">
              {data[row.id].status}
            </Text>
          </Container>
        </Container>
      </div>
    );
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}feedback`);
    if (res) {
      setData(res);
    }
  };

  const { register, handleSubmit, reset } = useForm();

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
      errorProps.onClose();
    }, 5000);
  };

  const resetErr = () => {
    setErrModule(false);
    setErrStatus(false);
    setErrRole(false);
    setErrPriority(false);
    setErrDescription(false);
  };

  const handleChange = (event) => {
    if (event.target.id === "module" && event.target.value !== "") {
      setErrModule(false);
    }
    if (event.target.id === "status" && event.target.value !== "") {
      setErrStatus(false);
    }
    if (event.target.id === "description" && event.target.value !== "") {
      setErrDescription(false);
    }

    if (event.target.id === "role" && event.target.value !== "") {
      setErrRole(false);
    }

    if (event.target.id === "priority" && event.target.value !== "") {
      setErrPriority(false);
    }
  };

  const onSubmit = async (data) => {
    if (data) {
      if (data.module === "") {
        setErrModule(true);
      } else {
        setErrModule(false);
      }
      if (data.description === "") {
        setErrDescription(true);
      } else {
        setErrDescription(false);
      }
      if (data.status === "") {
        setErrStatus(true);
      } else {
        setErrStatus(false);
      }
      if (data.role === "") {
        setErrRole(true);
      } else {
        setErrRole(false);
      }
      if (data.priority === "") {
        setErrPriority(true);
      } else {
        setErrPriority(false);
      }

      const formData = new FormData();
      formData.append("module", data.module);
      formData.append("status", data.status);
      formData.append("description", data.description);
      formData.append("role", data.role);
      formData.append("priority", data.priority);

      if (addFeedback.isOpen) {
        const res = await CRUDFunction.create(
          `${URL_STAGING}feedback`,
          formData
        );

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          setData(res);
          reset();
          resetErr();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully created.",
          });
          timer();
          addFeedback.onClose();
        }
      } else if (editFeedback.isOpen) {
        const res = await CRUDFunction.update(
          `${URL_STAGING}feedback/update/${feedbackID}`,
          formData
        );

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          setData(res);
          reset();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully updated.",
          });
          timer();
          editFeedback.onClose();
        }
      }
    }
  };

  return (
    <div>
      {alert.message ? (
        <AlertPop
          width="30%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Heading m="25px 0px 25px 100px" color={themeCtx.mode && "white"}>
        FEEDBACK
      </Heading>
      <Container
        maxW={"90%"}
        py="20px"
        bg={themeCtx.mode ? "#2b2b2b" : "white"}
        borderRadius="10px"
      >
        <div style={{ position: "relative" }}>
          <Button
            leftIcon={<AddIcon />}
            pos="absolute"
            right="2"
            top="25"
            _hover={{
              bg: "green.800 !important",
            }}
            style={{
              backgroundColor: "#33945F",
              color: "#ffffff",
              padding: "14px",
              fontSize: "14px",
            }}
            width="200px"
            onClick={() => {
              addFeedback.onOpen();
            }}
          >
            Add Feedback
          </Button>
        </div>
        <StylesCourse>
          <div key={1}>
            <ReactTable
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
              modulePage="feedback"
              themeCtx={themeCtx}
            />
          </div>
        </StylesCourse>
      </Container>
      {/* ------------------------------------ADD DRAWER----------------------------------- */}
      <Drawer
        isOpen={addFeedback.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          addFeedback.onClose();
        }}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent
          overflowY="auto"
          bgColor={themeCtx.mode && "#2b2b2b"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              ADD FEEDBACK
            </DrawerHeader>

            <DrawerBody>
              <FormControl isInvalid={errModule} pt="20px">
                <FormLabel fontWeight="bold">
                  MODULE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="module"
                  w="100%"
                  {...register("module")}
                  bg={themeCtx.mode && "#2b2b2b"}
                >
                  <option
                    value=""
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                    disabled
                    selected
                  >
                    Select module
                  </option>
                  <option
                    value="1"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Dashboard
                  </option>
                  <option
                    value="2"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Course
                  </option>
                  <option
                    value="3"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Programme
                  </option>
                  <option
                    value="4"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Company
                  </option>
                  <option
                    value="5"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Training
                  </option>
                  <option
                    value="6"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Trainee
                  </option>
                  <option
                    value="7"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Discount
                  </option>
                  <option
                    value="8"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Calendar
                  </option>
                  <option
                    value="9"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Settings
                  </option>
                  <option
                    value="10"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Authentication
                  </option>
                  <option
                    value="11"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    All
                  </option>
                </Select>
              </FormControl>
              {errModule && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={errRole} pt="20px">
                <FormLabel fontWeight="bold">
                  Role <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="role"
                  w="100%"
                  {...register("role")}
                  bg={themeCtx.mode && "#2b2b2b"}
                >
                  <option
                    value=""
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                    disabled
                    selected
                  >
                    Select role
                  </option>
                  <option
                    value="1"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Public
                  </option>
                  <option
                    value="2"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Management
                  </option>
                  <option
                    value="3"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Company
                  </option>
                </Select>
              </FormControl>
              {errRole && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={errDescription} pt="20px">
                <FormLabel fontWeight="bold">
                  DESCRIPTION <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Textarea
                  id="description"
                  resize="none"
                  h="120px"
                  placeholder="Description"
                  {...register("description")}
                  onBlur={handleChange}
                />
              </FormControl>
              {errDescription && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={errPriority} pt="20px">
                <FormLabel fontWeight="bold">
                  PRIORITY <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  w="100%"
                  id="priority"
                  {...register("priority")}
                  bg={themeCtx.mode && "#2b2b2b"}
                >
                  <option
                    value=""
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                    disabled
                    selected
                  >
                    Select priority
                  </option>
                  <option
                    value="1"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Not Set
                  </option>
                  <option
                    value="2"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Not Important
                  </option>
                  <option
                    value="3"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Important
                  </option>
                  <option
                    value="4"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Critical
                  </option>
                </Select>
              </FormControl>
              {errPriority && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={errStatus} pt="20px">
                <FormLabel fontWeight="bold">
                  STATUS <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  w="100%"
                  id="status"
                  {...register("status")}
                  bg={themeCtx.mode && "#2b2b2b"}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Select status
                  </option>
                  <option
                    value="1"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    To Do
                  </option>
                  <option
                    value="2"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Doing
                  </option>
                  <option
                    value="3"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Review
                  </option>
                  <option
                    value="4"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Completed
                  </option>
                </Select>
              </FormControl>
              {errStatus && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Add
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    resetErr();
                    addFeedback.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* ------------------------------------ADD DRAWER----------------------------------- */}

      {/* ------------------------------------EDIT DRAWER----------------------------------- */}
      <Drawer
        isOpen={editFeedback.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          editFeedback.onClose();
        }}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent
          overflowY="auto"
          bgColor={themeCtx.mode && "#2b2b2b"}
          color={themeCtx.mode && "white"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              EDIT FEEDBACK
            </DrawerHeader>

            <DrawerBody>
              <FormControl isInvalid={errModule} pt="20px">
                <FormLabel fontWeight="bold">
                  MODULE<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="module"
                  w="100%"
                  bg={themeCtx.mode && "#2b2b2b"}
                  defaultValue={editData.module}
                  {...register("module")}
                >
                  <option
                    value=""
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                    disabled
                  >
                    Select module
                  </option>
                  <option
                    value="1"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Dashboard
                  </option>
                  <option
                    value="2"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Course
                  </option>
                  <option
                    value="3"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Programme
                  </option>
                  <option
                    value="4"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Company
                  </option>
                  <option
                    value="5"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Training
                  </option>
                  <option
                    value="6"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Trainee
                  </option>
                  <option
                    value="7"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Discount
                  </option>
                  <option
                    value="8"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Calendar
                  </option>
                  <option
                    value="9"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Settings
                  </option>
                  <option
                    value="10"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Authentication
                  </option>
                  <option
                    value="11"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    All
                  </option>
                </Select>
              </FormControl>
              {errModule && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={errRole} pt="20px">
                <FormLabel fontWeight="bold">
                  Role <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  w="100%"
                  id="role"
                  bg={themeCtx.mode && "#2b2b2b"}
                  defaultValue={editData.role}
                  {...register("role")}
                >
                  <option
                    value=""
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                    disabled
                  >
                    Select role
                  </option>
                  <option
                    value="1"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Public
                  </option>
                  <option
                    value="2"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Management
                  </option>
                  <option
                    value="3"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Company
                  </option>
                </Select>
              </FormControl>
              {errRole && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={errDescription} pt="20px">
                <FormLabel fontWeight="bold">
                  DESCRIPTION <span style={{ color: "red" }}>*</span>;
                </FormLabel>
                <Textarea
                  resize="none"
                  h="120px"
                  placeholder="description"
                  defaultValue={editData.description}
                  {...register("description")}
                />
              </FormControl>
              {errDescription && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={errPriority} pt="20px">
                <FormLabel fontWeight="bold">
                  PRIORITY <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  w="100%"
                  bg={themeCtx.mode && "#2b2b2b"}
                  defaultValue={editData.priority}
                  {...register("priority")}
                >
                  <option
                    value=""
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                    disabled
                  >
                    Select priority
                  </option>
                  <option
                    value="1"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Not Set
                  </option>
                  <option
                    value="2"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Not Important
                  </option>
                  <option
                    value="3"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Important
                  </option>
                  <option
                    value="4"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Critical
                  </option>
                </Select>
              </FormControl>
              {errPriority && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={errStatus} pt="20px">
                <FormLabel fontWeight="bold">
                  STATUS <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  w="100%"
                  id="status"
                  bg={themeCtx.mode && "#2b2b2b"}
                  defaultValue={editData.status}
                  {...register("status")}
                >
                  <option
                    value=""
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                    disabled
                    selected
                  >
                    Select status
                  </option>
                  <option
                    value="1"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    To Do
                  </option>
                  <option
                    value="2"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Doing
                  </option>
                  <option
                    value="3"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Review
                  </option>
                  <option
                    value="4"
                    style={{ backgroundColor: themeCtx.mode && "#2b2b2b" }}
                  >
                    Completed
                  </option>
                </Select>
              </FormControl>
              {errStatus && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    resetErr();
                    editFeedback.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* ------------------------------------EDIT DRAWER----------------------------------- */}
      {/* ------------------------------------DELETE DIALOG----------------------------------- */}
      <ChakraAlertDialog
        title={"Delete Feedback"}
        dialogProps={dialogProps}
        url={[`${URL_STAGING}feedback/${feedbackID}`]}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        button={"Delete"}
      />
      {/* ------------------------------------IMAGE PREVIEW MODAL----------------------------------- */}
      <ErrorsDialog
        title={"Errors"}
        dialogProps={errorProps}
        body={errorAlert}
      />
    </div>
  );
}

export { FeedbackAdmin };

import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  Heading,
  Container,
  Text,
  ListItem,
  OrderedList,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  HStack,
  Box,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Icon,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { InfoOutlineIcon, EditIcon } from "@chakra-ui/icons";
import { FiMoreVertical, FiEdit } from "react-icons/fi";
import { GrCertificate } from "react-icons/gr";
import ThemeContext from "../../../functions/ThemeContext";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { ReactTable, AlertPop } from "../../../components";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const Styles = styled.div`
  padding-y: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :nth-child(1) {
        width: 100px;
      }
      :nth-child(3) {
        width: 300px;
      }
      :nth-child(4) {
        width: 200px;
      }
      :last-child {
        width: 200px;
      }
    }

    td {
      // font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      :nth-child(2) {
        text-align: left;
      }
    }
  }
`;

function ParticipantAdmin() {
  const themeCtx = useContext(ThemeContext);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [editID, setEditID] = useState([]);
  const [certificateData, setCertificate] = useState([]);
  const [selectedTrainee, setSelectedTrainee] = useState({
    id: "",
    name: "",
  });
  const [modalSerialNo, setModalSerialNo] = useState("");
  const editTrainee = useDisclosure();
  const errorProps = useDisclosure();
  const certificateModal = useDisclosure();
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const [editSerial, setEdit] = useState(false);
  const toast = useToast();
  const [loader, setLoader] = useState();

  const { register, handleSubmit, reset, resetField } = useForm();

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, []);

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
      errorProps.onClose();
    }, 5000);
  };

  const fetchData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}alltrainee`);
    if (res) {
      setData(res);
      setLoader(false);
    }
  };

  const onSubmit = async (formInput) => {
    if (editTrainee.isOpen) {
      const formData = new FormData();
      formData.append("trainee_name", formInput.traineeName);
      formData.append("trainee_ic", formInput.traineeIC.toString());
      const res = await CRUDFunction.update(
        `${URL_STAGING}trainee/updateParticipant/${editID}`,
        formData
      );
      if (res) {
        setData(res);
        setAlert({
          ...alert,
          class: "show",
          status: "success",
          message: "Data is successfully updated.",
        });
        timer();
        editTrainee.onClose();
      }
    } else if (certificateModal.isOpen) {
      const formData = new FormData();
      formData.append("trainee_id", selectedTrainee.id);
      formData.append("trainee_name", selectedTrainee.name);
      formData.append("training_id", certificateData.training_id);
      formData.append("serial_no", formInput.serial_no);

      if (editSerial === false) {
        const res = await CRUDFunction.create(
          `${URL_STAGING}certificate/create`,
          formData
        );
        if (res.message) {
          console.log(res);
          toast({
            title: "Error. Please try again",
            description: res.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully added.",
          });
          timer();
          reset();
          certificateModal.onClose();
        }
      } else {
        const res = await CRUDFunction.update(
          `${URL_STAGING}certificate/update`,
          formData
        );
        if (res.message) {
          console.log(res);
          toast({
            title: "Error. Please try again",
            description: res.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully updated.",
          });
          timer();
          reset();
          certificateModal.onClose();
        }
      }
    }
  };

  const columns = React.useMemo(() => [
    {
      Header: "#",
      Cell: (row) => (
        <>
          <Text>{parseInt(row.row.id) + 1}</Text>
        </>
      ),
    },
    {
      Header: "PARTICIPANT NAME",
      accessor: "trainee_name",
      Cell: ({ value }) => (
        <Text fontSize="15px" style={{ textTransform: "capitalize" }}>
          {value.toLowerCase()}
        </Text>
      ),
    },
    {
      Header: "IDENTIFICATION",
      accessor: "trainee_ic",
      Cell: ({ value }) => {
        let formattedIc = value;
        // if (value.length === 12) {
        //   formattedIc = `${value.substring(0, 6)}-${value.substring(
        //     6,
        //     8
        //   )}-${value.substring(8)}`;
        // }
        return <Text fontSize="15px">{formattedIc}</Text>;
      },
      disableSortBy: true,
    },
    {
      id: "expander",
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          {isAllRowsExpanded ? (
            <b>HIDE TRAINING LIST</b>
          ) : (
            <b>SHOW TRAINING LIST</b>
          )}
        </span>
      ),
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <InfoOutlineIcon boxsize={5} color="grey" />
          ) : (
            <InfoOutlineIcon boxsize={5} />
          )}
        </span>
      ),
      disableSortBy: true,
    },
    {
      Header: "ACTIONS",
      accessor: (data) => {
        return {
          traineeID: data.trainee_id,
        };
      },
      Cell: ({ value, row }) => {
        return (
          <Menu>
            <MenuButton
              as={Button}
              _hover={{ bg: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
              _expanded={{ bg: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
              _click={{ bg: themeCtx.mode ? "#2b2b2b" : "#ebebeb" }}
              variant="ghost"
              rightIcon={<FiMoreVertical />}
            />
            <MenuList bgColor={themeCtx.mode && "#2b2b2b"}>
              <MenuItem
                onClick={() => {
                  setEditData(data[row.id]);
                  setEditID(data[row.id].trainee_id);
                  editTrainee.onOpen();
                }}
                _hover={{ bg: themeCtx.mode && "#1b1b1b" }}
              >
                <Flex
                  p="2"
                  mx="2"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                >
                  {
                    <Icon
                      mr="4"
                      fontSize="16"
                      _groupHover={{
                        color: "green",
                      }}
                      as={FiEdit}
                    />
                  }
                  <Text>Edit</Text>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
      disableSortBy: true,
    },
  ]);

  const renderRowSubComponent = React.useCallback(({ row }) => {
    return (
      <div>
        <Container
          align="left"
          maxW="100%"
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          py="10px"
          borderBottom="1px"
          borderColor="#bdbdbd"
        >
          <Container align="left" maxW="80%" m="0px 0px 0px 100px">
            <b>List of Trainings:</b>
            <OrderedList>
              {data[row.id].trainings.map((item) => (
                <>
                  <Flex p="2" mx="2" borderRadius="lg">
                    <ListItem fontSize="15px" mb="10px">
                      {item.training_name}&nbsp;&nbsp;
                    </ListItem>
                    <Icon
                      mr="4"
                      fontSize="16"
                      _hover={{
                        color: "green",
                        cursor: "pointer",
                      }}
                      as={GrCertificate}
                      onClick={async () => {
                        const res = await CRUDFunction.get(
                          `${URL_STAGING}certificate/checkExist/${
                            data[row.id].trainee_id
                          }/${item.training_id}`
                        );
                        if (res) {
                          setModalSerialNo(res);
                        }
                        setSelectedTrainee({
                          id: data[row.id].trainee_id,
                          name: data[row.id].trainee_name,
                        });
                        setCertificate(item);
                        certificateModal.onOpen();
                      }}
                    />
                  </Flex>
                </>
              ))}
            </OrderedList>
          </Container>
        </Container>
      </div>
    );
  });

  return (
    <div>
      {alert.message ? (
        <AlertPop
          width="30%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Heading m="25px 0px 25px 100px" color={themeCtx.mode && "white"}>
        PARTICIPANT
      </Heading>
      <Container
        maxW={"90%"}
        bgColor={themeCtx.mode ? "#2b2b2b" : "white"}
        borderRadius="10px"
        py="20px"
      >
        <Styles>
          <ReactTable
            modulePage="participant"
            data={data}
            columns={columns}
            renderRowSubComponent={renderRowSubComponent}
            tableLoader={loader}
            themeCtx={themeCtx}
          />
        </Styles>
      </Container>

      <Drawer
        isOpen={editTrainee.isOpen}
        placement="right"
        onClose={() => {
          reset();
          editTrainee.onClose();
        }}
        size="lg"
        fontSize="14px"
      >
        <DrawerOverlay />
        <DrawerContent
          overflowY="auto"
          bg={themeCtx.mode ? "#2b2b2b" : "white"}
          color={themeCtx.mode && "white"}
        >
          <DrawerCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerHeader textAlign=")center" pt="7%" fontSize="25px">
              Edit Trainee
            </DrawerHeader>

            <DrawerBody>
              <FormControl pt="20px">
                <FormLabel>TRAINEE NAME</FormLabel>
                <Input
                  placeholder="Trainee name"
                  defaultValue={editData.trainee_name}
                  {...register("traineeName")}
                ></Input>
              </FormControl>

              <FormControl pt="20px">
                <FormLabel>IC NUMBER / PASSPORT NO</FormLabel>
                <Input
                  placeholder="IC / Passport no."
                  defaultValue={editData.trainee_ic}
                  {...register("traineeIC")}
                ></Input>
              </FormControl>
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    editTrainee.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>

      <Modal
        size="xl"
        isOpen={certificateModal.isOpen}
        onClose={() => {
          reset();
          setModalSerialNo("");
          setEdit(false);
          certificateModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Certificate Serial No</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <b>Trainee Name:</b>
              <Box
                h="40px"
                pl="15px"
                py="7px"
                mb="10px"
                borderRadius="10px"
                borderWidth="2px"
                style={{ textTransform: "capitalize" }}
              >
                {selectedTrainee && selectedTrainee.name.toLowerCase()}
              </Box>

              <b>Training Name:</b>
              <Box
                h="40px"
                pl="15px"
                py="7px"
                mb="10px"
                borderRadius="10px"
                borderWidth="2px"
              >
                {certificateData && certificateData.training_name}
              </Box>

              <FormControl>
                <FormLabel fontWeight="bold">Certificate Serial No:</FormLabel>
                {modalSerialNo !== "" && editSerial === false && (
                  <HStack>
                    <Box
                      w="80%"
                      h="40px"
                      p=" 7px 15px 7px 15px"
                      borderRadius="10px"
                      borderWidth="2px"
                    >
                      {modalSerialNo.serial_no}
                    </Box>
                    <Button
                      bg={themeCtx && themeCtx.mode && "#3b3b3b"}
                      leftIcon={<EditIcon />}
                      onClick={() => setEdit(true)}
                    >
                      Edit
                    </Button>
                  </HStack>
                )}
                {(modalSerialNo === "" || editSerial === true) && (
                  <Input
                    autoFocus
                    placeholder="Serial No"
                    {...register("serial_no")}
                  ></Input>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                type="submit"
                bg="#33945f"
                color="white"
                _hover={{ bg: "green.600" }}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
}

export { ParticipantAdmin };

import {
  Modal as ModalChakra,
  ModalOverlay,
  ModalContent,
  Spinner,
  Select,
  Text,
  ModalBody,
  Stack,
  Box,
  FormControl,
  Input,
  FormLabel,
  ModalFooter,
  Button,
  ModalHeader,
  ModalCloseButton,
  Heading,
  Image,
  Table,
  Th,
  Tr,
  Td,
  HStack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { EditIcon, CloseIcon } from "@chakra-ui/icons";
import { useState } from "react";

function Modal({
  trigger,
  loader,
  onSubmit,
  register,
  handleSubmit,
  inputArr,
  buttonName,
  buttonName2,
  modalHeader,
  viewArr,
  viewName,
  viewData,
  setButtonType,
  setInvNum,
  setReceiptDisplay,
  themeCtx,
}) {
  const [edit, setEdit] = useState(false);
  return (
    <ModalChakra
      isOpen={trigger.isOpen}
      onClose={() => {
        trigger.onClose();
        setEdit(false);
        setInvNum && setInvNum();
        setReceiptDisplay &&
          setReceiptDisplay({
            receiptNum: "",
            receiptAmount: "",
          });
      }}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent
        bg={themeCtx && themeCtx.mode ? "#2b2b2b" : "white"}
        color={themeCtx && themeCtx.mode && "white"}
      >
        {loader && (
          <div style={{ position: "absolute", top: "130%", right: "50%" }}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#33945f"
              size="xl"
              zIndex="1"
              background="white"
              display="flex"
              margin="auto"
            />
            <Text position="relative" zIndex="1" color="white" py="5px">
              Loading
            </Text>
          </div>
        )}
        <ModalHeader
          bg={themeCtx && themeCtx.mode ? "#1b1b1b" : "#f1f1f1"}
          color={themeCtx && themeCtx.mode && "white"}
          borderTopRadius={4}
        >
          {modalHeader}
        </ModalHeader>
        <ModalCloseButton color={themeCtx && themeCtx.mode && "white"} />
        {inputArr && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack spacing={4} direction="row">
                <Box width={"100%"} height={"100%"}>
                  {inputArr.map((item) => (
                    <FormControl mt={4}>
                      <FormLabel fontWeight="bold">{item.label}</FormLabel>
                      {item.select.length <= 0 && (
                        <>
                          <HStack>
                            <Input
                              disabled={edit === false ? item.disabled : false}
                              type={`${item.type}`}
                              placeholder={`Insert ${item.label}`}
                              defaultValue={
                                item.defaultValue !== 0 ||
                                item.defaultValue !== ""
                                  ? item.defaultValue
                                  : ""
                              }
                              {...register(`${item.param}`)}
                            />
                            {item.label === "Receipt Num" ||
                            item.label === "Receipt Amount" ||
                            item.label === "Invoice Num" ? (
                              <>
                                {edit === true && (
                                  <Button
                                    bg={themeCtx && themeCtx.mode && "#3b3b3b"}
                                    leftIcon={<CloseIcon boxSize={4} />}
                                    onClick={() => setEdit(false)}
                                  />
                                )}
                                {item.disabled === true ? (
                                  <Button
                                    bg={themeCtx && themeCtx.mode && "#3b3b3b"}
                                    leftIcon={<EditIcon />}
                                    onClick={() => setEdit(true)}
                                  >
                                    Edit
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </HStack>
                        </>
                      )}
                      {item.select.length > 0 && (
                        <Select
                          {...register(`${item.param}`)}
                          bg={themeCtx && themeCtx.mode ? "#2b2b2b" : "white"}
                        >
                          <option
                            value=""
                            style={{
                              backgroundColor:
                                themeCtx && themeCtx.mode ? "#2b2b2b" : "white",
                            }}
                            disabled
                          >
                            Select {item.label}
                          </option>
                          {item.select.length > 0 &&
                            item.select.map((elem) => (
                              <option
                                value={elem.training_id}
                                style={{
                                  backgroundColor:
                                    themeCtx && themeCtx.mode
                                      ? "#2b2b2b"
                                      : "white",
                                }}
                              >
                                {elem.training_name}
                              </option>
                            ))}
                        </Select>
                      )}
                    </FormControl>
                  ))}
                </Box>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {buttonName2 && (
                  <Button
                    variant="ghost"
                    color={themeCtx && themeCtx.mode ? "white" : "black"}
                    mr={3}
                    fontSize={"14px"}
                    w={"10%"}
                    type="submit"
                    disabled={!edit}
                    onClick={() => {
                      setButtonType(buttonName2);
                      setEdit(false);
                    }}
                  >
                    {buttonName2}
                  </Button>
                )}
                <Button
                  bg="#33945f"
                  color="white"
                  mr={3}
                  fontSize={"14px"}
                  w={"10%"}
                  type="submit"
                  disabled={loader}
                  onClick={() => {
                    setButtonType(buttonName);
                    setEdit(false);
                  }}
                >
                  {buttonName}
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    setEdit(false);
                    setInvNum && setInvNum();
                    setReceiptDisplay &&
                      setReceiptDisplay({
                        receiptNum: "",
                        receiptAmount: "",
                      });
                    trigger.onClose();
                  }}
                  fontSize={"14px"}
                  w={"10%"}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </form>
        )}
        <ModalBody color={themeCtx && themeCtx.mode ? "white" : "black"}>
          {viewName && (
            <>
              <Stack direction="row" p="5">
                <Box
                  p="5"
                  my="auto"
                  borderWidth={1}
                  maxW="350px"
                  maxH="350px"
                  style={{ display: "flex" }}
                >
                  <Image
                    src={
                      viewData.company && viewData.company.company_image
                        ? `https://myhalalgig.com/htms-api/public/uploads/${viewData.company.company_image}`
                        : "https://dummyimage.com/600x400/000/fff"
                    }
                  />
                </Box>

                <Box pl="15px">
                  {viewName.map((item) => (
                    <>
                      <Heading
                        size="sm"
                        style={{
                          textTransform: "capitalize",
                          textDecoration: "underline",
                        }}
                        color="#959ba3"
                      >
                        {item.charAt(10) === "c"
                          ? item.replace("company_pic", "PIC ")
                          : item.charAt(8) !== "f"
                          ? item.replace("_", " ")
                          : item.replace("_finance_email", " Finance Email")}
                      </Heading>
                      <Text mb="10px">
                        {item === "company_address" ? (
                          <Text>
                            {viewData.company &&
                              JSON.parse(viewData.company.company_address)[0]
                                .address}{" "}
                            {viewData.company &&
                              JSON.parse(viewData.company.company_address)[0]
                                .postcode}{" "}
                            {viewData.company &&
                              JSON.parse(viewData.company.company_address)[0]
                                .city}
                            ,{" "}
                            {viewData.company &&
                              JSON.parse(viewData.company.company_address)[0]
                                .state}
                            ,{" "}
                            {viewData.company &&
                              JSON.parse(viewData.company.company_address)[0]
                                .country}
                          </Text>
                        ) : item === "company_revenue" ? (
                          <Text>
                            RM {viewData.revenue ? viewData.revenue : 0}
                          </Text>
                        ) : item === "company_finance_email" ? (
                          <Text>
                            {viewData.finance_email
                              ? viewData.finance_email
                              : "-"}
                          </Text>
                        ) : item === "company_scheme" ? (
                          viewData.company &&
                          viewData.company.company_scheme ? (
                            JSON.parse(viewData.company.company_scheme).map(
                              (item, index) => (
                                <Text>
                                  {index + 1}. {item}
                                </Text>
                              )
                            )
                          ) : (
                            "-"
                          )
                        ) : (
                          viewData.company && viewData.company[item]
                        )}
                      </Text>
                    </>
                  ))}
                </Box>
              </Stack>

              {viewData.company && viewData.company.trainees.length !== 0 && (
                <Box m="5">
                  <Heading size="md" mb="2">
                    Staff List
                  </Heading>
                  <Box overflowY="auto" h="310px">
                    <Table>
                      <Tr pos="sticky" top="0" bg="#1DB954">
                        <Th color="black">#</Th>
                        <Th color="black">Name</Th>
                        <Th color="black">Position</Th>
                        <Th color="black">IC</Th>
                        <Th color="black">Phone</Th>
                        <Th color="black">Email</Th>
                      </Tr>
                      {viewData.company.trainees &&
                        viewData.company.trainees.map((item, i) => (
                          <>
                            <Tr>
                              <Td>{i + 1}</Td>
                              <Td>{item.trainee_name}</Td>
                              <Td>{item.trainee_position}</Td>
                              <Td>{item.trainee_ic}</Td>
                              <Td>{item.trainee_phone}</Td>
                              <Td>{item.trainee_email}</Td>
                            </Tr>
                            <Tr>
                              <Td></Td>
                              <Td colspan="5">
                                <UnorderedList>
                                  {viewData.company.trainingList[
                                    item.trainee_id
                                  ] !== undefined &&
                                    viewData.company.trainingList[
                                      item.trainee_id
                                    ].map((item) => (
                                      <ListItem fontSize="14px">
                                        {item}
                                      </ListItem>
                                    ))}
                                </UnorderedList>
                              </Td>
                            </Tr>
                          </>
                        ))}
                    </Table>
                  </Box>
                </Box>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </ModalChakra>
  );
}

export default Modal;

import { createContext, useState } from "react";

const ThemeContext = createContext({
  mode: false,
  switch: () => {},
});

export const ThemeContextProvider = (props) => {
  const [mode, setMode] = useState(false);

  const switchMode = () => {
    setMode((current) => !current);
  };

  const contextValue = {
    mode: mode,
    switch: switchMode,
  };
  return (
    <ThemeContext.Provider value={contextValue}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;

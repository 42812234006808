import React from "react";
import { CanvasComponent } from "../../../components";
import { Heading, Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

function TemplateScreen() {
  console.log(window.title);
  let title;
  let activeKey = null;
  let type = null;
  if (window.type === "upload") {
    title = "Upload new template";
    type = "upload";
  } else if (window.type === "edit") {
    title = "Edit template";
    activeKey = window.activeKey;
    type = "edit";
  } else if (window.type === "generate") {
    activeKey = window.activeKey;
    title = activeKey.title;
    type = "generate";
  }

  return (
    <div
      style={{
        // backgroundColor: "#333231",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CanvasComponent
        canvasConfig={activeKey}
        type={type}
        title={window.title ? window.title : title}
      />
    </div>
  );
}

export { TemplateScreen };
